import {
  IconButton,
  TextField,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import classnames from "classnames";
import * as React from "react";
import type { ClassKey, FieldInnerProps } from "./types";
import { Delete } from "@material-ui/icons";
import { Comment } from "../../../../types";
import CreateModal from '../../../../../../Components/CreateDialog';
import { findIndex, get } from "lodash";
import TextEditor from "../../../../../../Components/Material/TextEditor";
type Props = FieldInnerProps;

const useStyles = makeStyles<Theme, {}, ClassKey>(() => ({
  root: {
    width: "100%",
  },
  commentWrapper: { display: "flex", alignItems: "center", marginBottom: 8 },
  deleteButton: { marginRight: 4, width: 25, height: 25 },
  field: {},
}));


const SubsectionCommentsFieldInner = ({
  field,
  form,
  arrayHelpers,
  __component,
  disabled,
  className,
  onFormChange,
  inputRef,
  ...props
}: Props) => {
  const Component = __component || "div";

  const classes = useStyles();

  const [opennedModal, setOpennedModal] = React.useState<boolean>(false);
  const [textModal, setTextdModal] = React.useState<string>("");
  const [idx2Delete, setIdx2Delete] = React.useState<number>(-1);

  const handleDeleteComment = React.useCallback(
    (index: number) => {
      setIdx2Delete(index);
      setTextdModal(`¡Atención! ¿Quieres eliminar el comentario?`)
      setOpennedModal(true);
    },
    [arrayHelpers]
  );

  let comments = field.value ?? [];
  const hasComments = Array.isArray(comments) && comments.length > 0;

  const GetNextId:number = (comments.length == 0? 1 : comments[comments.length - 1].id + 1)

  const [newComment, setNewComment] = React.useState({id: -1, comment: ""});
  const [isNewComment, setIsNewComment] = React.useState<boolean>(false);

  // const handleChangeComment = React.useCallback(
  //   (id:number, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //     setNewComment({id:id, comment: event.target.value});
  //   },
  //   []
  // );

  // const handleAddComment = React.useCallback(
  //   (comment: Comment) => {
  //     if (comment.comment.trim()) {
  //       arrayHelpers.push({id: GetNextId, comment: comment.comment});
  //     }
  //     setNewComment({id:-1, comment: ""});
  //   },
  //   [arrayHelpers]
  // );

  const handleAddComment = React.useCallback(
    (comment: Comment) => {
      if(comment.comment.length > 0) {
        if(onFormChange)
          onFormChange(true);
        arrayHelpers.push({id: GetNextId, comment: comment.comment});
        setNewComment({id: -1, comment: ""});
      }
      setIsNewComment(false);
    },
    [arrayHelpers, onFormChange]
  );

  const handleChangeComment = React.useCallback(
    (editorRef:any, comment:Comment, event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

      if(!editorRef)
        return;

      if(onFormChange)
        onFormChange(true);
      setNewComment({id: comment.id, /*comment: editorRef.getContent({ format: 'text' }),*/ comment:editorRef.getContent()});

      // if(idx == -1)
      //   // arrayHelpers.push({id: GetNextId, comment: event.target.value});
      //   setNewComment({id: GetNextId, comment: event.target.value});
      // else {
      //   // console.log(arrayHelpers.form.values)
      //   // console.log(field)
      //   // console.log(form)
      //   // console.log(arrayHelpers)
      //   // comment.comment = event.target.value;
      //   // comment.comment.trim();
      //   setNewComment({id: comment.id, comment: event.target.value.trim()});
      //   // arrayHelpers.replace(idx, {id: comment.id, comment: event.target.value});
      // }
    },
    [arrayHelpers]
  );

  // const handleChangeComment = React.useCallback(
  //   (id:number, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //     setNewComment({id:id, comment: event.target.value});
  //   },
  //   []
  // );

  const handleCreateModal = (action:number) => {
    switch(action) {
      case 1: // Delete comment
        if(onFormChange)
          onFormChange(true);
        arrayHelpers.remove(idx2Delete);
        setOpennedModal(false);
        break;
    }
  }

  // const handlePressEnter: React.KeyboardEventHandler<HTMLDivElement> =
  //   React.useCallback(
  //     (event) => {
  //       if (event.key === "Enter") {
  //         event.preventDefault();
  //         handleAddComment(newComment);
  //       }
  //     },
  //     [handleAddComment, newComment]
  //   );

  return (
    <Component className={classnames(className, classes.root)}>
      {hasComments
        ? comments.map((comment, index) => {
            const fieldName = `${field.name}.${index}`;
            return (
              <div key={comment.id} className={classes.commentWrapper}>
                {!disabled && (
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => handleDeleteComment(index)}
                  >
                    <Delete />
                  </IconButton>
                )}
                {/* <Typography key={fieldName} variant="body1">
                  {comment.comment}
                </Typography> */}
                {comment.id == newComment.id?
                  <TextEditor
                    initialValue={newComment.comment}
                    value={newComment.comment}
                    // handleChange={(evt:any, editorRef: any)=>handleChangeComment(editorRef, comment, evt)}
                    onBlur={()=>(comment.id == newComment.id?arrayHelpers.replace(index, newComment):null)}
                    onFocus={()=>setNewComment(comment)}
                    onChange={(editorRef:any)=>handleChangeComment(editorRef, comment)}
                  />
                // <Editor
                //   apiKey='oftyll90ov1v8av78a6s0enwjt0noms7ed316kzbwc0azk68'
                //   onInit={(_evt:any, editor:any) => editorRef.current = editor}
                //   value={newComment.comment_html}
                //   onEditorChange={(evt:any)=>handleChangeComment(comment, evt)}
                //   // onInput={(evt:any)=>handleChangeComment(comment, evt)}
                //   // onChange={(evt:any)=>handleChangeComment(comment, evt)}
                //   // onUndo={(evt:any)=>handleChangeComment(comment, evt)}
                //   // onRedo={(evt:any)=>handleChangeComment(comment, evt)}

                //   // onKeyUp={(evt:any)=>handleChangeComment(comment, evt)}
                //   onBlur={()=>(comment.id == newComment.id?arrayHelpers.replace(index, newComment):null)}
                //   onFocus={()=>setNewComment(comment)}
                //   init={{
                //     height: 500,
                //     width:'100%',
                //     menubar: false,
                //     branding: false,
                //     plugins: [
                //       'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                //       'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                //       'insertdatetime', 'media', 'table', 'code', 'wordcount'
                //     ],
                //     // toolbar: 'undo redo | blocks | ' +
                //     //   'bold italic forecolor | alignleft aligncenter ' +
                //     //   'alignright alignjustify | bullist numlist outdent indent | ' +
                //     //   'removeformat | help',
                //     //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                //     // }}
                //     toolbar: 'undo redo | ' +
                //     'bold italic underline forecolor backcolor | bullist numlist outdent indent',
                //     content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                //   }}
                // />
              :
                <div className={`comment-card`} style={{paddingLeft:20}} onClick={()=>!disabled?setNewComment(comment):null} key={index} dangerouslySetInnerHTML={{ __html: comment.comment }} />
                // <TextField
                //   key={comment.id}
                //   // inputRef={inputRef}
                //   name={field.name}
                //   type="text"
                //   size="medium"
                //   value={comment.id == newComment.id?newComment.comment:comment.comment}
                //   placeholder="Añade un comentario"
                //   onChange={(evt:any)=>handleChangeComment(comment, evt)}
                //   InputLabelProps={{ shrink: true }}
                //   disabled={disabled}
                //   fullWidth
                //   className={classnames("dialog-item-25", classes.field)}
                //   multiline
                //   variant="outlined"
                //   inputProps={{ maxLength: 4500 }}
                //   onBlur={()=>(comment.id == newComment.id?arrayHelpers.replace(index, newComment):null)}
                //   // onBlur={()=>setNewComment({id: -1, comment: ""})}
                //   onFocus={()=>setNewComment(comment)}
                //   // onKeyDown={handlePressEnter}
                // />
              }

                {/* <>
                  <Editor
                    apiKey='oftyll90ov1v8av78a6s0enwjt0noms7ed316kzbwc0azk68'
                    onInit={(_evt:any, editor:any) => editorRef.current = editor}
                    initialValue={comment.id == newComment.id?newComment.comment:comment.comment}
                    onChange={(evt:any)=>handleChangeComment(comment, evt)}
                    onCommentChange={(evt:any)=>handleChangeComment(comment, evt)}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                    />
                </> */}
              </div>
            );
          })
        : null}
        
      {opennedModal?
          <CreateModal
              onCloseCreationModal={()=>setOpennedModal(false)}
              isOpen={opennedModal}
              inputText={textModal}
              typeModal={2}
              action={handleCreateModal}
              typeAction={1}
              loadingModal={false}
              titleModal={`MENSAJE`}
          />
      :
          null
      }

      {!isNewComment?
        <TextField
          key={newComment.id}
          inputRef={inputRef}
          name={field.name}
          type="text"
          size="medium"
          value={newComment.id == -1?newComment.comment:""}
          placeholder="Añade un comentario"
          onChange={(evt:any)=>handleChangeComment(newComment, evt)}
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
          fullWidth
          className={classnames("dialog-item-25", classes.field)}
          multiline
          variant="outlined"
          inputProps={{ maxLength: 4500 }}
          onFocus={()=>setIsNewComment(true)}
          // onKeyDown={handlePressEnter}
        />
      :
        <TextEditor
          initialValue={newComment.comment}
          value={newComment.comment}
          // handleChange={(evt:any, editorRef: any)=>handleChangeComment(editorRef, comment, evt)}
          onBlur={()=>handleAddComment(newComment)}
          onFocus={()=>setNewComment({id: -1, comment: ""})}
          onChange={(editorRef:any)=>handleChangeComment(editorRef, newComment)}
        />
      }



      {/* {newComment.id == -1 && newComment.comment.length > 0?
        <div style={{ marginTop:5 }}>
          <TextField
            key={"tmp"}
            inputRef={inputRef}
            name={field.name}
            type="text"
            size="medium"
            value={""}
            placeholder="Añade un comentario"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
            fullWidth
            className={classnames("dialog-item-25", classes.field)}
            multiline
            variant="outlined"
            inputProps={{ maxLength: 4500 }}
            // onKeyDown={handlePressEnter}
          />
        </div>
      :
        null
      } */}
    </Component>
  );
};

export default SubsectionCommentsFieldInner;
