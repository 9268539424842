import * as React from "react";
import { Props as InformeCreateFormProps } from "../containers/InformeCreateForm";
import InformeCreateFormDialog, {
  Props as DialogProps,
} from "../components/InformeCreateFormDialog";

export type Return = [
  React.ReactNode,
  {
    handleOpen: () => void;
    handleClose: () => void;
  }
];

export type Props = Omit<
  DialogProps<InformeCreateFormProps>,
  "open" | "Form" | "FormProps" | "onClose" | "title"
> & {
  form: React.ComponentType<InformeCreateFormProps>;
  formProps: InformeCreateFormProps;
  title?: DialogProps<InformeCreateFormProps>["title"];
};

export default function useInformeCreateFormDialog({
  title,
  form,
  formProps,
  ...dialogProps
}: Props): Return {
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleFormBack = React.useCallback(
    (values:any, formActions:any) => {
      if (formProps && formProps.onBack) {
        formProps.onBack(values, formActions);
      }
      handleClose();
    },
    [handleClose, formProps]
  );

  return [
    <InformeCreateFormDialog
      {...dialogProps}
      open={open}
      onClose={handleClose}
      title={title ?? "Nuevo informe"}
      Form={form}
      FormProps={React.useMemo(
        () => ({
          ...formProps,
          onBack: handleFormBack,
          submitMeta: {
            onSuccess: (informeId: number) => {
              if (formProps.submitMeta && formProps.submitMeta.onSuccess) {
                formProps.submitMeta.onSuccess(informeId);
              }
              handleClose();
            },
          },
        }),
        [formProps, handleFormBack, handleClose]
      )}
    ></InformeCreateFormDialog>,
    { handleOpen, handleClose },
  ];
}
