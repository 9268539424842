import * as React from "react";
import { Form } from "formik";
import { FormInnerProps, Values } from "./types";
import { Button, DialogActions, makeStyles } from "@material-ui/core";
import useFormikActions from "../../hooks/useFormikActions";
import classnames from "classnames";
import LoadingButton from "../../../../../material-ui/components/LoadingButton";
import InformeCreateFormFields from "./InformeCreateFormFields";

export type Props = FormInnerProps;

const useStyles = makeStyles(() => ({
  root: {},
  header: {},
  actions: {
    gap: 20,
  },
}));

const InformeCreateFormInner = (props: Props) => {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    disabled,
    disabledFields,
    isSubmitting,
    Header,
    Content,
    Actions,
  } = props;

  const classes = useStyles();

  const HeaderComponent = Header || "div";
  const ContentComponent = Content || "div";
  const ActionsComponent = Actions || DialogActions;

  const handleBackClick = useHandleBackClick(props);

  const handleDateChange = React.useCallback(
    (field: "dtStartVisit", formattedDate: string | null) => {
      setFieldValue(field, formattedDate);
    },
    [setFieldValue]
  );

  return (
    <Form className={classes.root}>
      <HeaderComponent className={classes.header} />
      <ContentComponent>
        <InformeCreateFormFields
          isSubmitting={isSubmitting}
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          disabled={disabled}
          disabledFields={disabledFields}
          onDateChange={handleDateChange}
        />
      </ContentComponent>
      <ActionsComponent className={classes.actions} disableSpacing>
        <Button
          onClick={handleBackClick}
          disabled={disabled || isSubmitting}
          className="cancel-dialog"
        >
          {`CANCELAR`}
        </Button>
        <LoadingButton
          className={classnames("confirm-dialog")}
          isLoading={disabled || isSubmitting}
        >
          {`CONTINUAR`}
        </LoadingButton>
      </ActionsComponent>
    </Form>
  );
};

export default InformeCreateFormInner;

function useHandleBackClick(props: Props) {
  const { values, onBack } = props;

  const formActions = useFormikActions<Values>(props);

  return React.useCallback(() => {
    if (onBack) {
      onBack(values, formActions);
    }
  }, [onBack, formActions, values]);
}
