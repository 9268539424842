import FiltersResponsive from "../../../../../Components/Filters/Filters_responsive";
import SearchField from "../../../../../Components/SearchField";
import { InformeRouteType } from "../../../hooks/useInformesRouteParams";
import { InformeT } from "../../../types";
import InformesTableFilters from "./InformesTableFilters";

export type Props = {
  handleOpenDialog: any;
  options: any;
  searchValue: string;
  handleChangeSearchValue: Function;
  handleClearSearchValue: Function;
  canCreate?: boolean;

  handleChangeFilter: Function;
  handleChangeFilterDate: Function;
  handleCleanFilters: Function;
  informes$props:any;
  apply_filters:any;
  informeType:InformeRouteType;

};

export default function SearchAndCreateHeader(props: Props) {
  const {
    handleOpenDialog,
    canCreate,
    searchValue,
    handleChangeSearchValue,
    handleClearSearchValue,
    options,
    handleChangeFilter,
    handleChangeFilterDate,
    handleCleanFilters,
    informes$props,
    apply_filters,
    informeType
  } = props;

  return (
    <div className="App-header-options App-header-options-responsive">   
      <SearchField
        placeholder="Buscar..."
        value={searchValue}
        allowSearch={true}
        onValueChange={handleChangeSearchValue}
        handleClearSearchValue={handleClearSearchValue}
      />
      {canCreate && (
        <div style={{ display: "flex", gap: "16px", flexWrap: 'wrap' }}>
          <div
            className={`App-header-options-item App-header-options-item-create ${
              options ? null : `App-header-options-item-disable`
            }`}
            onClick={() => handleOpenDialog()}
          >
            {`Crear informe`}
          </div>
        </div>
      )}
      <InformesTableFilters
        handleChangeFilter={handleChangeFilter}
        handleChangeFilterDate={handleChangeFilterDate}
        handleCleanFilters={handleCleanFilters}
        loadingOptions={false}
        options={informes$props}
        apply_filters={apply_filters}
        infomeType={informeType}
      />
    </div>
  );
}
