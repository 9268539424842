import * as React from "react";
import { Props, Values } from "./types";
import InformeCreateForm from "../../components/InformeCreateForm";
import { FormikHelpers } from "formik";
import { startOfDay } from "date-fns";
import { getValidationSchema } from "./validationSchema";
import OrderService from '../../../../../../ApiClients/OrderService';
import { InformeRouteType } from "../../../../hooks/useInformesRouteParams";

const InformeCreateFormContainer = ({
  informeType,
  userIdName,
  centrotrabajoId,
  submitMeta,
  ...props
}: Props) => {
  const initialValues = React.useMemo(
    () => ({
      name: "",
      description: "",
      dtStartVisit: null,
      participant: "",
      team: "",
      type: informeType
    }),
    [informeType]
  );

  const validationSchema = React.useMemo(() => getValidationSchema(), []);

  const onSubmit = useCreateInforme({ informeType, userIdName, submitMeta, centrotrabajoId });
  return (
    <InformeCreateForm
      {...props}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    ></InformeCreateForm>
  );
};

export default InformeCreateFormContainer;

function useCreateInforme({
  informeType,
  userIdName,
  centrotrabajoId,
  submitMeta,
}: Pick<Props, "informeType" | "userIdName" | "submitMeta" | "centrotrabajoId">) {
  return React.useMemo(
   () => (values: Values, formActions: FormikHelpers<Values>) => {
      console.log("Started submitting");
      const newValues = {
        ...values,
        tipo: (informeType == InformeRouteType.VISITAS ? 1 : 2),
        dtCreated: new Date().toISOString(),
        author: userIdName,
        centrotrabajoId: centrotrabajoId
      };
      formActions.setSubmitting(true);
      
      // console.log(newValues)
      
      OrderService.saveData(newValues, 16)
      .then((ret)=>{
        // console.log(ret)

        if (submitMeta && submitMeta.onSuccess) {
          console.log("Successfully submitted:");
          // TODO:OS use id from query
          submitMeta.onSuccess(ret);
        }
        // setTimeout(() => {
        //   console.log("Successfully submitted:");
        //   console.log(newValues);
        //   formActions.setSubmitting(false);
        //   if (submitMeta && submitMeta.onSuccess) {
        //     // TODO:OS use id from query
        //     submitMeta.onSuccess(1);
        //   }
        // }, 2000);
      })
      .catch((err)=>{
        console.error(err);
        formActions.setSubmitting(false);
      });


    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [informeType, userIdName, submitMeta?.onSuccess]
  );
}
