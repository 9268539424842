import { StyleSheet } from '@react-pdf/renderer';

export const PdfStyles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Open Sans',
  },
  rheader:{
    width: '100%',
  },
  rowlogo:{
    display: 'flex',    
    flexDirection: 'row',
    justifyContent:'space-between',    
    // flex:1
  },
  mainlogo:{
    // flex:1,
    justifyContent:'flex-start'
  },
  mainTitle:{
    flex:1,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    // width:'100%',
    alignItems:'center',
    paddingLeft:5,
    paddingRight:5,
  },  
  scdlogo: {
    // justifyContent:'flex-end',
    // flex:1,
  },
  logo1: {
    width: 45,
    height: 45,
  },
  logo2: {
    width: 'auto',
    height: 45,
  },
  info:{
  },
  apartado:{
  },
  separator:{
    marginTop: 10,
    marginBottom:10, 
    borderTop: 1,
    borderTopColor: '#00D3FF',
  },
  footer: {
    position: "absolute",
    fontSize: 8,
    bottom: 10,
    textAlign: "left",
    fontWeight: 'bold',
    color: '#999797',
    marginLeft: 40,
    width: 360,
  },

  footerImage: {
    width: 100, 
    height: 'auto', 
    position: 'absolute',
    right: 50, 
    bottom: 6, 
  },

  footerImage2: {
    width: 20, 
    height: 20, 
    position: 'absolute',
    right: 155, 
    bottom: 4, 
  },

  data: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: 8,
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  campo: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '15%',
    fontWeight: 'bold',
  },
  dato: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '85%',
  },

  titlesec: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 40,
  },
  titletext: {
    fontSize: 12,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  numeration: {
    color: '#00D3FF',
  },
  comentarios: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    // height: 'auto', 
  },
  global: {
    backgroundColor: '#D1F8FF',
  },
  ctextLegal:{
    fontSize: 8,
    width: '100%',
    padding: 4,
  },
  cspacer: {
    width: '5%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    padding: 4,
    paddingTop: 6,
    
  },
  square: {
    width: 5,
    height: 5,
    backgroundColor: '#000000',
  },
  ctext: {
    fontSize: 8,
    width: '80%',
    padding: 4,
  },

  signaturesWrapper:{
    display:'flex',
    flexDirection:'row',
    fontSize:8,
    marginRight: '15%',
    padding: 20, 
    justifyContent:'space-between',
  },
  signature:{
    width:'35%'
  },
  signatureAutor:{
    textAlign:'justify'
  },
  signaturePanel:{
    width:150, 
    height:'auto', 
    backgroundColor:'#D1F8FF', 
    marginTop: 20, 
    padding: 10
  },

  tableStyle :{
    width: '100%'
  },
  tableRowStyle : {
    flexDirection : 'row'
  },  
  tableColHeaderStyle : {
    width: "20%",
    borderStyle: "solid",
    border: 0,
    borderBottomColor: "#00d3ff",
    borderBottomWidth: 1,
    alignSelf:'baseline'
  },
  tableColStyle : {
    width: "20%",
    borderStyle: "solid",
    border: 0,
    borderBottomColor: "#e0e0e0",
    borderBottomWidth: 1,    
  },  
  tableCellHeaderStyle : {
    textAlign: "center",
    margin: 5,
    fontSize: 9,
    fontWeight: "bold"
  },
  tableCellStyle : {
    textAlign: "center",
    margin: 5,
    fontSize: 8
  },
  tableCellLeftAlignStyle : {
    textAlign: "left",
    margin: 5,
    fontSize: 8
  },
  boldTableCellStyle : {
    textAlign: "center",
    margin: 5,
    fontSize: 8,
    fontWeight: 'bold'
  },  
});