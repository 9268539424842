import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import classnames from "classnames";
import { isNil } from "lodash";

export type Props = {
  mark: number | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      lineHeight: "1",
    },
    circle: {
      width: "1em",
      height: "1em",
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: theme.spacing(1),
    },
    red: {
      fill: "#e74c3c", // Rojo
    },
    blue: {
      fill: "#3498db", // Azul
    },
    green: {
      fill: "#2ecc71", // Verde
    },
  })
);

export default function InformesTableStatusRowCell({ mark }: Props) {
  const classes = useStyles();

  return (
    <TableCell align="center" className={classes.root}>
      {!isNil(mark) ? (
        <>
          <svg
            className={classnames(classes.circle, {
              [classes.red]: mark >= 0 && mark < 5,
              [classes.blue]: mark >= 5 && mark < 8,
              [classes.green]: mark >= 8 && mark <= 10,
            })}
          >
            <circle cx="50%" cy="50%" r="50%" />
          </svg>
          {mark}
        </>
      ) : null}
    </TableCell>
  );
}
