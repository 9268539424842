import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Document, Page } from '../Material/pdfRenderComponents';
import Button from '@material-ui/core/Button';
import EscaleraAddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import HelpIcon from '@material-ui/icons//InfoOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DatePicker from '../DatePicker';
import { IMe, IIIRegister, IVRegister, VIIForm, IXQuestion, ICentroTrabajo, VIQuestion, IQuestion } from '../../types';
import { SelectorField, CheckBoxField, TextFieldInput } from '../FormsWidgets';
import {GetRole, GetEmpresa} from '../../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import {cloneDeep, filter, find, findIndex} from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageViewIcon from '@material-ui/icons/Pageview';
import { GetConcatedDate, GetDatetimeFormat } from '../../vc_general';
import OrderService from '../../ApiClients/OrderService';
import LoaderBeauty from '../Material/activityProgress';
import CreateModal from '../CreateDialog';
import ListSecciones from '../Material/MultiSelect';
import Loader from '../Loader';
import { ListChildComponentProps } from 'react-window';
import {epis} from '../../ApiClients/DummyData';
import esc01 from '../../Assets/widgets/esc01.png';
import esc02 from '../../Assets/widgets/esc02.png';

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY:'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
    width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        //overflowX: 'auto',
        borderWidth: 2, 
        borderColor: '#00809b',
        borderStyle: 'solid',
    },
    table:{
        //tableLayout:'fixed'
        width: '100%',
    },
    row:{
        //wordWrap:'break-word'
        width:'100%',
        //backgroundColor:'#ddd'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    //fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth:'200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});

const useStyles = ({
    page: {
        //display: 'row',
        //backgroundColor: '#E4E4E4',
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    body: {
        paddingTop: 15,
        paddingBottom: 40,
        paddingLeft: 15,
    },
    logo: {
        width: 60,
        height: 60,
    },
    logoFito: {
        width: 'auto',
        height: 'auto',
    },
    bussinessData: {
        width: 'auto',
        height: 'auto',
    },
    header:{
        marginTop:7,
        fontSize:10
    },
    secondHeader:{
        marginTop:7,
        fontSize:11,
        marginBottom:10
    },
    headerTable:{
        fontSize:10,
        marginVertical:'auto',
        padding:3,
        borderTop:'1pt solid rgba(0,0,0,0.31)',
        borderBottom:'1pt solid rgba(0,0,0,0.31)',
        borderLeft:'1pt solid rgba(0,0,0,0.31)',
    },
    lineTable:{
        fontSize:9,
        marginVertical:'auto',
        padding:3,
    },
    content: {
        marginRight:15,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    },
    content02: {
        marginRight:0,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    }
});

function GetIconHelp01 (icon:number) {
    switch(icon){
        case 1:
            return esc01;
        case 2:
            return esc02;
        default:
            return undefined;
    }
}

/*
const secciones_inspected = (list:Array<any>)=>{

    let secciones = list.map((seccion:any, index:number)=>{

        return (
            <View key={index} style={[useStyles.content, {paddingBottom:5}]}>
                <View style={{width:'13%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {index + 1}
                    </Text>
                </View>
                <View style={{width:'87%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {seccion.name}
                    </Text>
                </View>
            </View>
        );
    })
    return secciones;
}

const questions = (list:Array<IQuestion>)=>{

    let questions = list.map((question:IQuestion, index:number)=>{

        return (
            <View key={index} style={[useStyles.content, {paddingBottom:5}]}>
                <View style={{width:'55%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.question}
                    </Text>
                </View>
                <View style={{width:'10%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.answer}
                    </Text>
                </View>
                <View style={{width:'35%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.comment}
                    </Text>
                </View>
            </View>
        );
    })
    return questions;
}
*/

const pdf_questions = (list:Array<IQuestion>)=>{

    let questions = list.map((question:IQuestion, index:number)=>{

        return (
            <View key={index} style={[useStyles.content02, {paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                <View style={{width:'40%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'justify', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.question}
                    </Text>
                </View>
                <View style={{width:'15%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.answer}
                    </Text>
                </View>
                <View style={{width:'38%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.comment}
                    </Text>
                </View>
            </View>
        );
    })
    return questions;
}

const pdf_inspections = (inspection:IXQuestion) => {
    const data = inspection.questions.map((item:VIQuestion, i01:number)=>{
        return(
            <View key={i01}>                   
                <View style={{paddingBottom:10}}>
                    <View style={{width:'93%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                        <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${item.title}`}</Text>
                    </View>

                    <View style={[useStyles.content02, {paddingBottom:20, paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                        <View style={{width:'40%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center'}]}>{`Punto inspección`}</Text>
                        </View>
                        <View style={{width:'15%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', backgroundColor:'#ddd'}]}>{`Respuesta`}</Text>
                        </View>
                        <View style={{width:'38%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Comentario`}</Text>
                        </View>
                    </View>

                    {pdf_questions(item.questions)}
                </View>
            </View>
        );
    });

    return data;
}

const pdf_escaleras = (inspections:Array<IXQuestion>) => {
    const data = inspections.map((inspection:IXQuestion, i01:number)=>{

        return(
            <View key={i01} style={{paddingBottom:5, marginBottom: 20}}>
                <View style={{width:'95%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${inspection.item}`}</Text>
                </View>
                <View style={{width:'93%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Sección`}</Text>
                </View>
                <View style={{width:'88%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${inspection.secciones.map(function(o){return o.name}).join("\r\n")}`}</Text>
                </View>
                <View style={{width:'93%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Tipo: ${inspection.tipo}`}</Text>
                </View>
                {inspection.seccionDsc.length > 0?
                    <View>
                        <View style={{width:'93%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Nota`}</Text>
                        </View>
                        <View style={{width:'88%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${inspection.seccionDsc}`}</Text>
                        </View>
                    </View>
                :
                    null
                }

                <View style={{paddingBottom:10}}>
                    {pdf_inspections(inspection)}
                </View>

            </View>
        );
    });

    return data;

}

const PdfForm  = (props: ListChildComponentProps) =>{
    const register:VIIForm = props.data[0];
    const me:IMe = props.data[1];
    const item:IVRegister = props.data[2];

    const empresa:ICentroTrabajo|undefined = GetEmpresa(item.centrotrabajoId, me);
    
    return(
        <Document>
            <Page size="A4" style={useStyles.body} >
                <View fixed>
                    <View style={[useStyles.content, {paddingBottom:20, justifyContent:'space-between'}]}>
                        <Text style={{fontSize:8, color:'#8c8c8c', fontWeight:'bold'}} fixed>
                            {`Fecha revisión: ${register.dt_reviewed}`}
                        </Text>
                        <Text style={{fontSize:8}} render={({ pageNumber, totalPages }) => (
                            `Pág. ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                    <View style={[useStyles.content, {paddingBottom:20}]}>
                        <View style={{width:'25%', marginRight:30, paddingRight:15, borderRight:'1pt solid rgba(0,0,0,0.31)'}}>
                            {empresa?
                                <Image src={empresa.logo} style={useStyles.bussinessData}/>
                            :
                                null
                            }
                            <Text style={{fontWeight:25, fontSize:10, textAlign:'center', marginTop:10}}>{`${empresa?.description}`}</Text>
                        </View>
                        <View style={{width:'70%', height:120}}>
                            <Text style={{fontWeight:50, fontSize:20}}>{`${register.title}`}</Text>
                            <Text style={useStyles.header}>{`Fecha realización: ${GetDatetimeFormat(register.dt_created)}`}</Text>
                            <Text style={useStyles.header}>{`Realizado por: ${register.usuario}`}</Text>
                        </View>
                    </View>
                </View>
                <View style={{width:'95%', height:30}}>
                    <Text style={{fontSize:10}}>{`${help.w1}`}</Text>
                </View>
                <View>
                    {pdf_escaleras(register.inspections)}
                </View>
            </Page>    
        </Document>
    ); 
}

const questions_01 = {title:"1. Uso", observations:[], questions: [
    {id:"q_1", question: "Los largueros se mantienen libres de obstáculos como sogas, cables, mangueras, alambres, aceite, grasa y basura o desechos.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},
    {id:"q_2", question: "La escalera portátil, se está colocando en posición indicada: La distancia del pie de la escalera  a la superficie de apoyo no debe ser menor a un ¼ con respecto a la longitud del punto de apoyo.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},
    {id:"q_3", question: "La longitud que sobre sale por encima del punto de apoyo es la indicada: Entre 0.8 y 1.0 metros.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_4", question: "La escalera está limpia de aceite, grasa, y otras sustancias peligrosas que pueden causar resbalones.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_5", question: "El apoyo de la escalera portátil es firme y segura en el suelo. Está instalada sobre una base sólida y nivelados sus soportes.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_6", question: "Los travesaños y los largueros se encuentran en buenas condiciones, sin fisuras, rotos, sueltos, etc.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_7", question: "Las escaleras poseen en su base un sistema antideslizante (cuñas,caucho…).", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_8", question: "El ascenso, descenso y los trabajos desde escaleras se efectúan de frente a estas.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_9", question: "La escalera no se utiliza por dos o más personas simultáneamente.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_10", question: "Los trabajos a más de 3,5 metros de altura, desde el punto de operación al suelo, que requieran movimientos o esfuerzos peligrosos para la estabilidad del trabajador, se efectúan con la utilización de un EPI anticaídas o se adoptan medidas de protección alternativas.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_11", question: "No se trabaja por encima del tercer peldaño contando desde el punto de apoyo superior.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_12", question: "Se utiliza calzado antideslizante que sujeta bien los pies, con las suelas limpias de grasa, aceite u otros materiales deslizantes.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_13", question: "La escalera no es utilizada para otros fines distintos de aquellos para los que ha sido construida.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_14", question: "No se realizan trabajos que impliquen un desplazamiento del cuerpo fuera de la vertical de la escalera.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_15", question: "El escalón superior de la escalera de tijera no es usado para pararse.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_16", question: "La parte posterior de la escalera de tijera no es usada para subirse a menos que la escalera esté diseñada con ese propósito.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_17", question: "La escalera de tijera se utiliza correctamente, sin trabajar 'a caballo' sobre la escalera.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_18", question: "Las escaleras metálicas disponen de rótulos visibles que dicen “Cuidado: No usar cerca de equipo eléctrico” u otra advertencia similar.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_19", question: "El área alrededor de la escalera se encuentra señalizada de manera que los transeúntes o vehículos no la puedan golpear.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]}
]};

const questions_02 = {title:"2. Condiciones", observations:[], questions: [
    {id:"q_1", question: "Las zapatas se encuentran en buen estado, fijas, en cada pata.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},
    {id:"q_2", question: "Los rieles o bordes se evidencian en buen estado, sin bordes afilados, rajados, ni doblados.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},
    {id:"q_3", question: "Los escalones o peldaños, se encuentran en buen estado, no se observan sueltos, rotos, gastados o faltantes.  Son seguros.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_4", question: "El tope superior de la escalera de tijera se encuentra en buen estado, no se observa rajado, suelto o faltante.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_5", question: "La escalera de tijera está dotada de topes de seguridad de apertura tanto en su articulación superior como hacia la mitad de los largueros (cadenilla, cable de acero, etc.).", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_6", question: "En general la escalera se encuentra en buen estado no se observan partes oxidadas, corroídas, rajadas, sueltas o faltantes.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_7", question: "Se observa la etiqueta de la escalera, esta es legible.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_8", question: "La cuerda o polea (opcional), se encuentra en buen estado, no se observa gastada, roída. o faltante.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},
]};

const tipo_escalera = [
    {id:"TIJERA", name:"TIJERA"},
    {id:"EXTENSIBLE", name:"EXTENSIBLE"},
];

const help = {w1: `Retirar del servicio las escaleras que se encuentren dañadas o defectuosas y etiquetarlas como "no usar".`, icons: [1,2]};

const i_newRegister:IXQuestion = {item: ``, secciones: [], seccionDsc: ``, tipo: ``, questions: [questions_01, questions_02]};

interface IFormProps {
    me:IMe;
    classes: any;
    secciones:any;
    handleCloseItemForm: Function;
    item:IVRegister;
}

interface IFormState {
    creationModalOpen:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    titleModal: string;
    htmlBody:boolean;
    loading:boolean;
    loadingModal:boolean;
    register:VIIForm;
}

class Form extends Component<IFormProps, IFormState> {
    _going2upload:any = {attachments:[]};
    constructor(props: IFormProps) {
        super(props);
        this.state = {
            creationModalOpen:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            htmlBody:false,
            loading: false,
            loadingModal: false,
            titleModal: `MENSAJE`,
            register: {
                dt_reviewed: `20/11/17`,
                dt_created: new Date(),
                dt_edited: new Date(),
                title: `Revisión Escalera portátil - Sección`,
                usuario: props.me.userIdName,
                inspections: [i_newRegister],
            }
        };
    }

    componentDidMount(){

    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false });
    }

    /* CALL BACKEND */

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 6);
        this._going2upload = {attachments:[]};
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        let modaltype = 1;
        let modalaction = -1;

        if(response == 1) {
            p = `Formulario adjuntado correctamente`;
            modaltype = 4;
            modalaction= 1;
        }
        
        this.setState({
            creationModalOpen:true,
            modalText:p,
            modalType: modaltype,
            modalAction: modalaction,
            loadingModal: false
        });
    }

    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Implica que se ha adjuntado corractamente el formulario
                this.props.handleCloseItemForm(true);
                this.onCloseCreationModal();
                break;
            case 2: // Implica iniciar el proceso de adjuntar el formulario
                this.setState({loadingModal: true},()=>{
                    this.handleCreateForm();
                });
                break;
            default:
                this.onCloseCreationModal();
        }
    }

    handleChangeDate=(id:any, event:any)=>{
        let nRegister = Object.assign({}, this.state.register);
        nRegister[id] = event;
        this.setState({ register: nRegister });
    }

    handleChangeUser=(id:any, event:any, i01:number)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections[i01][id] = String(event.target.value).toUpperCase();
        this.setState({ register: nRegister });
    }

    handleChangeUserQuestions=(id:any, event:any, i01:number, i02:number, i03:number)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections[i01].questions[i02].questions[i03][id] = event.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeSelectDevices=(e:any, list:any, i01:number)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.inspections[i01].secciones = list;
        this.setState({ register: nRegister });
    }

    handleCreateForm=()=>{

        const {item, me} = this.props;
        const {register} = this.state;

        let dale = <PdfForm index={1} data={[register, me, item]} style={{}} />;

        const blob = pdf(dale).toBlob();

        blob.then(ret=>{

            let reader = new FileReader();

            reader.onloadend = async ()=>{
                this._going2upload.attachments.push({name: `Informe_ESC_01_seccion_${GetConcatedDate(register.dt_created)}.pdf`, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: item.tipo, listadoId: item.listadoTableId, id: item.id});
                this.sendAttachFiles();
            }
            reader.readAsDataURL(ret);

        }).catch(err=>{
            console.log(err)
        })
    }

    handleAskCreateForm=()=>{
        const {item} = this.props;

        this.setState({
            modalAction: 2,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo a la acción ${item.id} de la orden de trabajo ${item.listadoTableId}?`,
            modalType: 2,
            creationModalOpen: true,
        });
    }

    handleShowHelpers=()=>{
        const nhelper = 
            <div>
                <p style={{textAlign:'justify'}}>
                    {help.w1}
                </p>
                {help.icons.map((icon:number, i: number)=>{
                    return(
                        <div key={i} style={{marginBottom: 20, textAlign:'center'}}>
                            <img key={i} src={GetIconHelp01(icon)} width={'auto'} height={'300px'} />
                        </div>
                    );
                })}
            </div>;

        this.setState({
            modalText: nhelper,
            modalType: 1,
            creationModalOpen: true,
        });
    }

    verifyForm=(register:VIIForm)=>{

        // Verifica que almenos tiene alguna escalera
        if(register.inspections.length == 0)
            return false;

        // Verifica que está el nombre de todas las escaleras
        const u_index = findIndex(register.inspections, function(o){return o.item.length == 0});

        if(u_index > -1)
            return false;

        // Verifica que hay almenos un apartado
        if(register.inspections.length == 0)
            return false;
        // Verifica todos las escaleras tienen almenos una sección
        let s_c = true;
        register.inspections.map(i=>{
            if(i.secciones.length == 0)
                s_c = false
        })

        if(!s_c)
            return false;

        // Verifica que todas las preguntas de las escaleras menos los comentarios han sido contestadas
        let c = true;
        register.inspections.map(i=>{
            i.questions.map((q:VIQuestion)=>{
                q.questions.map((x:IQuestion)=>{
                    if(x.answer.length == 0)
                        c = false;
                })

            })
        })

        if(!c)
            return false;

        // Verifica que está se ha seleccionado el tipo de escalera en todas las inspecciones
        const i_index = findIndex(register.inspections, function(o){return o.tipo.length == 0});

        if(i_index > -1)
            return false;

        return true;
    }

    handleDeleteEscalera = (i01:number) => {
        let nregister = cloneDeep(this.state.register);
        nregister.inspections.splice(i01, 1);
        this.setState({register: nregister});
    }

    handleAddEscalera = () => {
        let nregister = cloneDeep(this.state.register);
        nregister.inspections.push(i_newRegister);
        this.setState({register: nregister});
    }

    handleDeleteBlock = (idx01:number, idx02:number) => {
        let nregister = cloneDeep(this.state.register);
        nregister.inspections[idx01].questions.splice(idx02, 1);
        this.setState({register: nregister});
    }

    renderEscalera = (inspection:IXQuestion, i01:number, secciones:any) => {

        return(
            <Accordion key={i01} defaultExpanded={false} style={{border:`2px solid rgb(0, 43, 97, 0.5)`, marginBottom:20, borderRadius:'10px'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-content"
                >
                <Typography style={{fontWeight:'bold'}}><input placeholder='Identificación escalera...' style={{fontSize:'15px', fontWeight:'bold'}} width={"300px"} type={"text"} onChange={(e:any)=>this.handleChangeUser('item', e, i01)} onClick={(e:any)=>{e.stopPropagation();}} value={`${inspection.item}`}/></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{display:'inline', width:'100%'}}>
                        <div style={{display:'flex', marginLeft:'auto', width:'100%'}}>
                            <IconButton onClick={()=>this.handleDeleteEscalera(i01)} aria-label="delete user">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        <div style={{width:'100%', marginBottom:20}}>
                            <ListSecciones id={"secciones"} data={inspection.secciones} multiple={true} name={'Sección'} placeholder={'Sección'} options={secciones} handleChangeSelect={(e:any, list:any)=>this.handleChangeSelectDevices(e,list, i01)}/>
                        </div>
                        <div style={{width:'100%', marginBottom:20}}>
                            <SelectorField
                                name={`Tipo`}
                                fieldsData={tipo_escalera}
                                id={'tipo'}
                                deviceData={inspection}
                                handleFieldChange={(id:string, evt:any)=>this.handleChangeUser(id,evt,i01)}
                                required={true}
                                style={3}
                            />
                            <TextFieldInput 
                                id={'seccionDsc'}
                                name={`Nota`} 
                                multiline={false} 
                                fieldsData={inspection} 
                                handleFieldChange={(id:string, e:any, type:string)=>this.handleChangeUser('seccionDsc', e, i01)}
                                required={false}
                                disabled={false}
                                type={`text`}
                                helper={``}
                                maxText={100}
                                style={2}
                                noError={true}
                                shrink={true}
                                size={"small"}
                            />
                        </div>
                        {inspection.questions.map((item:VIQuestion, i02:number)=>{
                            return(
                                <Accordion key={i02} defaultExpanded={false} style={{border:`2px solid rgb(0, 43, 97, 0.5)`, marginBottom:20, borderRadius:'10px'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-content"
                                    >
                                    <Typography style={{fontWeight:'bold'}}><strong style={{fontSize:'15px'}}>{`${item.title}`}</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{display:'inline', width:'100%'}}>
                                            <div style={{display:'flex', marginLeft:'auto', width:'100%'}}>
                                                <IconButton onClick={()=>this.handleDeleteBlock(i01, i02)} aria-label="delete user">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                            <Paper className={this.props.classes.paper}>
                                                <Table className={this.props.classes.table} aria-label="simple table">
                                                {item.questions.map((item:IQuestion, i03:number)=>{
                                                    return(
                                                        <TableBody key={i03} style={{width:'100%'}}>
                                                            <TableRow className={this.props.classes.row} style={{backgroundColor:'#00809b'}} key={i03}>
                                                                <TableCell style={{width:'100%', color:'#fff'}} align="center"><strong>{i03 + 1}</strong></TableCell>
                                                            </TableRow>
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell  align="left">{`${item.question}`}</TableCell>
                                                            </TableRow> 
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell align="center">
                                                                    <div style={{maxWidth:150}}>
                                                                        <SelectorField
                                                                            name={`Q_${i03 + 1}`}
                                                                            fieldsData={item.a_template}
                                                                            id={'answer'}
                                                                            deviceData={item}
                                                                            handleFieldChange={(id:string, evt:any)=>this.handleChangeUserQuestions(id,evt,i01,i02,i03)}
                                                                            required={true}
                                                                            style={3}
                                                                        />
                                                                    </div>
                    
                                                                </TableCell>
                                                            </TableRow> 
                                                            <TableRow className={this.props.classes.row}> 
                                                                <TableCell align="center">
                                                                    <TextFieldInput
                                                                        name={`C_${i03 + 1}`}
                                                                        id={'comment'}
                                                                        disabled={false}
                                                                        type={"text"}
                                                                        required={false}
                                                                        handleFieldChange={(id:string, evt:any, type:string)=>this.handleChangeUserQuestions(id,evt,i01,i02,i03)}
                                                                        style={3}
                                                                        helper={""}
                                                                        fieldsData={item}
                                                                        maxText={2000}
                                                                        noError={true}
                                                                        size={"medium"}
                                                                        shrink={true}
                                                                    />
                                                                </TableCell>
                                                            </TableRow> 
                                                        </TableBody>
                                                    );
                                                })}  
                                                </Table>
                                            </Paper> 
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })} 
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }

    render() {
        const {register, loading} = this.state;
        const {me, secciones} = this.props;

        let usersinspected = register.inspections.map((inspection:IXQuestion, index:number)=>{
            return(this.renderEscalera(inspection, index, secciones));
        });

        return (
            <Dialog style={{padding:'25px'}} fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data form-dialog-data'>
                <DialogTitle id="form-dialog-title">
                    <div className="App-header">
                        {`${register.title}`}
                    </div>
                    <div>
                        <IconButton size="small" onClick={()=>this.handleShowHelpers()} aria-label="help user">
                            <HelpIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <IconButton onClick={()=>this.handleAddEscalera()} aria-label="delete escalera">
                            <EscaleraAddIcon />
                        </IconButton>
                    </div>
                    {usersinspected}
                    <div>
                        {this.state.creationModalOpen?
                            <CreateModal
                                onCloseCreationModal={this.onCloseCreationModal}
                                isOpen={this.state.creationModalOpen}
                                inputText={this.state.modalText}
                                typeModal={this.state.modalType}
                                action={this.handleCreateModal}
                                typeAction={this.state.modalAction}
                                loadingModal={this.state.loadingModal}
                                titleModal={this.state.titleModal}
                            />
                        :
                            null
                        }
                    </div>
                </DialogContent>
                <DialogActions style={{ borderTop: '#ff972b solid 3px'}}>
                    {!loading?
                        <div style={{marginRight:'auto', display:'flex', marginLeft: 'auto', width: '90%', justifyContent:'space-between'}}>
                            <Button onClick={()=>this.props.handleCloseItemForm(false)} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(register)} onClick={()=>this.handleAskCreateForm()} className={`confirm-dialog ${!this.verifyForm(register)?`confirm-dialog-disable`:``}`}>
                                {`Generar informe`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Form);