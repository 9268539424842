import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe, ICentroTrabajo } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import {GetLogoEmpresa} from '../helpers';
import SearchField from './SearchField';
import { cloneDeep, filter, includes } from 'lodash';
import bg from '../Assets/PRL_bg_emp.png'
import CreateModal from './CreateDialog'

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    }
});

interface IHomeButtonProps {
    id: number;
    name: string;
    icon: any;
    goToFeature: Function;
    classes: any;
    cartProductCount?: number;
}

interface IHomeButtonState {
}

class HomeButtonInternal extends Component<IHomeButtonProps, IHomeButtonState> {
    render() {
        let imgComponent = <img src={this.props.icon} alt='icon' className='feature-icon-home' />;
        return (
            <div key={this.props.id} className='home-button' onClick={() => this.props.goToFeature(this.props.id)}>
                <div className='frame-img'>
                    {imgComponent}
                </div>
                <div className='button-text'>
                    {this.props.name}
                </div>
            </div>
        );
    }
}
const HomeButton = withStyles(styles)(HomeButtonInternal);

interface IHomeProps {
    history: any;
    match: any;
    myInfo?: IMe;
    version: string;
    updateNinformed: Function;
    ninformed: boolean;
}

interface IHomeState {
    loading: boolean;
    searchValue: string;
    creationModalOpen: boolean;
    modalType: number;
    typeAction: number;
    inputText: any;
    titleModal:string;
    closeButtonModal:boolean;
}


class Home extends Component<IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props);
        this.state = {
            loading: true, 
            searchValue: '',
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            titleModal:`MENSAJE`,
            closeButtonModal: false,
        };
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    goToFeature=(id: string)=>{
        this.props.history.push(`/${id}/listado/1`);
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal implica ir a las noticias
                this.props.history.push('/news');
                break;
            default:
                break;
        }
    }

    RenderEmpresas=(me:IMe)=>{
        if(me.empresas.length == 0)
            return <strong style={{paddingLeft:20}}>{`No tienes empresas configuradas.`}</strong>;
        
        if(me.empresas.length == 1) {
            const nnews = filter(me.news, function(o){return !o.viewed});
    
            if(nnews.length == 0)
                this.props.history.push(`/${me.empresas[0].id}/listado/1`);
        }
        
        const f_business = this.handleFilterBusiness(me);

        if(f_business.length == 0)
            return <strong style={{paddingLeft:20}}>{`No hay empresas que correspondan con los filtros aplicados.`}</strong>
        
        let emp = f_business.map((empresa:ICentroTrabajo, index:number)=>{
            return(
                <HomeButton
                    key={empresa.id}
                    goToFeature={this.goToFeature}
                    id={empresa.id}
                    name={empresa.description}
                    icon={empresa.logo}
                />
            );
        });

        return <div className="dynamic-grid">{emp}</div>;
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }
    
    handleFilterBusiness=(me:IMe)=>{

        let newBusiness = cloneDeep(me.empresas);

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newBusiness.length > 0 && searchValue && searchValue.length > 0){
            newBusiness = filter(newBusiness, function(o){return includes(o.description.toLowerCase(), searchValue) });
        }
        return newBusiness;
    }

    checkNews = (me:IMe) => {
        const {ninformed, updateNinformed} = this.props;
        if(ninformed)
            return null;
        const nnews = filter(me.news, function(o){return !o.viewed});

        if(nnews.length == 0)
            return null;

        this.setState({
            creationModalOpen:true,
            modalType:2,
            typeAction: 1,
            titleModal: `NOVEDADES`,
            closeButtonModal: false,
            inputText: 'Hay nuevas funcionalidades en Nealia Prevent. ¿Quiere verlas ahora?'
        }, ()=>updateNinformed());
    }

    render() {
        const {searchValue} = this.state;
        
        return (
            <div className='Home-basic'>
                <Layout 
                    version={this.props.version}
                    history={this.props.history}
                    match={this.props.match}
                    currentPage={0}
                    allowDrawer={false}
                    me={this.props.myInfo}
                    showEmpresa={false}
                    showLateralMenu={false}
                />
                
                <div className='Home-header-webBar'>
                    <div className="App-header-options" style={{ marginRight:15 }}>
                        <SearchField placeholder="Buscar..." value={searchValue} allowSearch={true} onValueChange={this.handleSearchChange} handleClearSearchValue={this.handleClearSearchValue}/>
                    </div>
                </div>
                <div className='bg-companies'>

                    {this.props.myInfo?
                        <div className='buttons-container'>
                            <div className='App-header' style={{ paddingLeft:20 }}>
                                {`Centros de trabajo`}
                            </div> 
                            {this.RenderEmpresas(this.props.myInfo)}
                            {this.checkNews(this.props.myInfo)}
                        </div>
                    :
                        this.state.loading?
                            <div style={{marginLeft:20}}>
                                <Loader loading={true}/>
                            </div>
                        :
                            `Fallo en la carga del perfil, volver a intentar`
                    }
                </div>

                {this.state.creationModalOpen?
                        <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.inputText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.typeAction}
                            loadingModal={false}
                            titleModal={this.state.titleModal}
                            closeButton={this.state.closeButtonModal}
                        />
                    :
                        null
                }
            </div>
        );
    }
}

export default Home;