import * as React from "react";
import { FormikErrors } from "formik";
import { get, sumBy } from "lodash";
import { Values } from "../../../../Informes/modules/informes-update-form/containers";

export type Config = {
  sections: { id: number; subsections: { mark?: void | number, includeAvg:boolean }[] }[];
  formErrors?: FormikErrors<Values>;
  path?: string;
};

type Return = { [id: number]: Array<any> };

const useSectionMarks = (config: Config): Return => {
  return React.useMemo(() => getSectionMarks(config), [config]);
};

export default useSectionMarks;

export function getSectionMarks({
  sections,
  formErrors,
  path,
}: Config): Return {
  return sections.reduce((acc, { id, subsections }, index) => {
    const subsectionsPath = `${path}.${index}.subsections`;
    const availableToAvg = subsections.filter((sub, subIndex) => {
      const fieldName = `${subsectionsPath}.${subIndex}`;
      const hasError = get(formErrors, `${fieldName}.mark`);
      
      return typeof sub.mark === "number" && !hasError && sub.includeAvg;
    });

    const total = sumBy(availableToAvg, (s) => s.mark || 0);
    const cantSub = availableToAvg.length;
    const avgMark =
      availableToAvg.length > 0
        ? sumBy(availableToAvg, (s) => s.mark || 0) / availableToAvg.length
        : undefined;
    //return { ...acc, [id]: [avgMark && parseFloat(avgMark.toFixed(2)), total,  cantSub] };
    return { ...acc, [id]: [avgMark && parseFloat(avgMark.toFixed(2)), total,  cantSub] };
  }, {} as Return);
}
