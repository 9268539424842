import { FormFieldsProps } from "./types";
import classnames from "classnames";
import { Divider, Grid, TextField, makeStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import SectionsField from "../../../../../Section/modules/Section-form/components/SectionsField/SectionsField";
import { InformeRouteType } from "../../../../hooks/useInformesRouteParams";
import React from "react";
import TextEditor from "../../../../../../Components/Material/TextEditor";

export type Props = FormFieldsProps;

export const useStyles = makeStyles(() => ({
  root: {},
  field: {
    backgroundColor: "white",
    borderRadius: 11,
    marginTop: 15,
  },
  divider: {
    margin: "50px 0px",
  },
}));

const InformeUpdateFormFields = (props: Props) => {
  const {
    values,
    errors,
    touched,
    component,
    handleChange,
    handleBlur,
    onDateChange,
    onFormChange,
    setFieldValue,
    disabled,
    disabledFields,
    isSubmitting,
    className,
    onOpenMeasureDialog,
  } = props;
  const classes = useStyles();

  const Component = component || "div";
  const [globalCommentFocus01, setGlobalCommentFocus01] = React.useState<boolean>(false);
  const [globalCommentFocus02, setGlobalCommentFocus02] = React.useState<boolean>(false);

  const onEditChange = (id:string, value:any) => {
    if(onFormChange)
      onFormChange(true);
    setFieldValue(id, value);
  }

  const formatDate = (date: MaterialUiPickersDate) =>
    date?.toISOString() ?? null;

    return (
    <Component className={classnames(className)}>
      {/* General info */}
      <Grid container spacing={2}>
        {/* 1st row */}
        <Grid item container spacing={2}>
          <Grid item sm={10} xs={12}>
            <TextField
              name="name"
              label="Nombre"
              type="text"
              size="medium"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{ shrink: true }}
              disabled={disabled || disabledFields?.name || isSubmitting}
              error={Boolean(errors.name && touched.name)}
              helperText={errors.name && touched.name ? errors.name : null}
              fullWidth
              className={classnames("dialog-item-25", classes.field)}
              variant="outlined"
            />
          </Grid>
          {/* <Grid item sm={2} xs={12}>
            <TextField
              name="type"
              label="Tipo"
              type="text"
              size="medium"
              value={
                values.type === InformeRouteType.AUDITORIAS
                  ? "Auditoría"
                  : "Visita"
              }
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{ shrink: true }}
              disabled={disabled || disabledFields?.type || isSubmitting}
              error={Boolean(errors.type && touched.type)}
              helperText={errors.type && touched.type ? errors.type : null}
              fullWidth
              className={classnames("dialog-item-25", classes.field)}
              variant="outlined"
            />
          </Grid> */}
        </Grid>

        {/* 2nd row */}
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Descripción"
            type="text"
            size="medium"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            InputLabelProps={{ shrink: true }}
            disabled={disabled || disabledFields?.description || isSubmitting}
            error={Boolean(errors.description && touched.description)}
            helperText={
              errors.description && touched.description
                ? errors.description
                : null
            }
            fullWidth
            className={classnames("dialog-item-25", classes.field)}
            variant="outlined"
            multiline
            minRows={3}
            inputProps={{ maxLength: 1000 }}
          />
        </Grid>
      
        {values.type == InformeRouteType.AUDITORIAS?
          <React.Fragment>
            <Grid item xs={12}>
              <TextField
                name="team"
                label="Equipo Auditor"
                type="text"
                size="medium"
                value={values.team}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{ shrink: true }}
                disabled={disabled || disabledFields?.team || isSubmitting}
                error={Boolean(errors.team && touched.team)}
                helperText={
                  errors.team && touched.team
                    ? errors.team
                    : null
                }
                fullWidth
                className={classnames("dialog-item-25", classes.field)}
                variant="outlined"
                multiline
                minRows={3}
                inputProps={{ maxLength: 1000 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="participant"
                label="Participantes"
                type="text"
                size="medium"
                value={values.participant}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{ shrink: true }}
                disabled={disabled || disabledFields?.participant || isSubmitting}
                error={Boolean(errors.participant && touched.participant)}
                helperText={
                  errors.participant && touched.participant
                    ? errors.participant
                    : null
                }
                fullWidth
                className={classnames("dialog-item-25", classes.field)}
                variant="outlined"
                multiline
                minRows={3}
                inputProps={{ maxLength: 1000 }}
              />
            </Grid>
          </React.Fragment>
        :
          null
        }

        {/* 3rd row */}
        <Grid item container spacing={2}>
          <Grid item md={2} sm={6} xs={12}>
            <TextField
              name="author"
              label="Autor"
              type="text"
              size="medium"
              value={values.author}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{ shrink: true }}
              disabled={disabled || disabledFields?.author || isSubmitting}
              error={Boolean(errors.author && touched.author)}
              helperText={
                errors.author && touched.author ? errors.author : null
              }
              fullWidth
              className={classnames("dialog-item-25", classes.field)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <DatePicker
              name="dtStartVisit"
              label="Fecha Inicio"
              value={values.dtStartVisit}
              format="dd/MM/yyyy"
              inputVariant="outlined"
              onChange={(date) => onDateChange("dtStartVisit", formatDate(date))}
              disabled={disabled || disabledFields?.dtStartVisit || isSubmitting}
              className={classnames("dialog-item-25", classes.field)}
              fullWidth
              animateYearScrolling
            />
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <DatePicker
              name="dtEndVisit"
              label="Fecha Fin"
              value={values.dtEndVisit}
              format="dd/MM/yyyy"
              inputVariant="outlined"
              onChange={(date) => onDateChange("dtEndVisit", formatDate(date))}
              disabled={disabled || disabledFields?.dtEndVisit || isSubmitting}
              className={classnames("dialog-item-25", classes.field)}
              fullWidth
              animateYearScrolling
              clearable
              error={Boolean(errors.dtEndVisit && touched.dtEndVisit)}
              helperText={
                errors.dtEndVisit && touched.dtEndVisit ? errors.dtEndVisit : null
              }
            />
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <TextField
              name="statusDsc"
              label="Estado"
              type="text"
              size="medium"
              value={values.statusDsc}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{ shrink: true }}
              disabled={disabled || disabledFields?.statusDsc || isSubmitting}
              error={Boolean(errors.statusDsc && touched.statusDsc)}
              helperText={
                errors.statusDsc && touched.statusDsc ? errors.statusDsc : null
              }
              fullWidth
              className={classnames("dialog-item-25", classes.field)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <SectionsField
        name="sections"
        disabled={disabled}
        onOpenMeasureDialog={onOpenMeasureDialog}
        onFormChange={onFormChange}
      />

      {values.type == InformeRouteType.AUDITORIAS?
        <React.Fragment>
          <Divider className={classes.divider} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div onClick={()=>!(disabled || disabledFields?.globalComment01 || isSubmitting)?setGlobalCommentFocus01(true):null} key={0}>
                <div style={{marginBottom:-7, zIndex:90, marginLeft:10}}>
                  <label style={{backgroundColor:'#fff', fontSize:14, paddingLeft:5, paddingRight:20}}>
                    {`Buenas prácticas`}
                  </label>
                </div>

                {globalCommentFocus01?
                  <TextEditor
                    initialValue={values.globalComment01}
                    value={values.globalComment01}
                    // handleChange={(evt:any, editorRef: any)=>handleChangeComment(editorRef, comment, evt)}
                    onBlur={()=>setGlobalCommentFocus01(false)}
                    onFocus={()=>setGlobalCommentFocus01(true)}
                    onChange={(editorRef:any)=>onEditChange("globalComment01", editorRef.getContent())}
                  />
                :
                  <div className={`comment-card`} style={{paddingLeft:20, minHeight:'300px', marginBottom:3}} dangerouslySetInnerHTML={{ __html: values.globalComment01 }}/>
                }
                {Boolean(errors.globalComment01 && touched.globalComment01)?
                  <label style={{color:'#f44336', fontSize:'0.75rem', marginLeft:14, marginRight:14}}>
                    {errors.globalComment01 && touched.globalComment01
                      ? errors.globalComment01
                      : null
                    }
                  </label>
                :
                  null
                }

              </div>
              
              {/* // <TextField
              //   name="globalComment01"
              //   label="Buenas prácticas"
              //   type="text"
              //   size="medium"
              //   value={values.globalComment01}
              //   onChange={handleChange}
              //   onBlur={handleBlur}
              //   InputLabelProps={{ shrink: true }}
              //   disabled={disabled || disabledFields?.globalComment01 || isSubmitting}
              //   error={Boolean(errors.globalComment01 && touched.globalComment01)}
              //   helperText={
              //     errors.globalComment01 && touched.globalComment01
              //       ? errors.globalComment01
              //       : null
              //   }
              //   fullWidth
              //   className={classnames("dialog-item-25", classes.field)}
              //   variant="outlined"
              //   multiline
              //   minRows={10}
              //   inputProps={{ maxLength: 1000000 }}
              // /> */}
            
            </Grid>

            <Grid item xs={12}>
              <div onClick={()=>!(disabled || disabledFields?.globalComment02 || isSubmitting)?setGlobalCommentFocus02(true):null} key={0}>
                <div style={{marginBottom:-7, zIndex:90, marginLeft:10}}>
                  <label style={{backgroundColor:'#fff', fontSize:14, paddingLeft:5, paddingRight:20}}>
                    {`Valoración global`}
                  </label>
                </div>

                {globalCommentFocus02?
                  <TextEditor
                    initialValue={values.globalComment02}
                    value={values.globalComment02}
                    // handleChange={(evt:any, editorRef: any)=>handleChangeComment(editorRef, comment, evt)}
                    onBlur={()=>setGlobalCommentFocus02(false)}
                    onFocus={()=>setGlobalCommentFocus02(true)}
                    //onChange={(editorRef:any)=>setFieldValue("globalComment02", editorRef.getContent())}
                    onChange={(editorRef:any)=>onEditChange("globalComment02", editorRef.getContent())}
                  />
                :
                  <div className={`comment-card`} style={{paddingLeft:20, minHeight:'300px', marginBottom:3}} dangerouslySetInnerHTML={{ __html: values.globalComment02 }}/>
                }
                {Boolean(errors.globalComment02 && touched.globalComment02)?
                  <label style={{color:'#f44336', fontSize:'0.75rem', marginLeft:14, marginRight:14}}>
                    {errors.globalComment02 && touched.globalComment02
                      ? errors.globalComment02
                      : null
                    }
                  </label>
                :
                  null
                }

              </div>
            </Grid>
          </Grid>
        </React.Fragment>
      :
        null
      }
    </Component>
  );
};

export default InformeUpdateFormFields;
