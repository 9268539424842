import { IconButton, Toolbar } from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import { useState } from "react";
import CreateModal from '../CreateDialog';

type FiltersParams = {
    loadingOptions: boolean;
    RenderFilters: Function;
    options?:any;
    apply_filters:any;
    handleCleanFilters: Function;
};

type FiltersParamsDialog = {
    RenderFilters:Function,
    options?:any
    apply_filters:any
    closeDialogFunction:Function
};

const FiltersResponsive = ({loadingOptions, RenderFilters, options, apply_filters, handleCleanFilters}: FiltersParams) => {
    const [openFilterDialog, setOpenFilterDialog] = useState(false)

    const handleOpenCloseFilterModal = (evt:any) => {
        evt.stopPropagation();
        // const toolbarFilterList = document.querySelector(".filter-list-toolbar-responsive");
        // if (!toolbarFilterList || toolbarFilterList.checkVisibility()){              
        //     return;
        // }

        setOpenFilterDialog(!openFilterDialog);
    }

   return (
    loadingOptions
        ? null
        : <div className='filter-list filter-list-responsive'>
            <Toolbar variant="dense" className='filter-list-toolbar filter-list-toolbar-responsive'>
                {RenderFilters(options, apply_filters)}
            </Toolbar>
            <div className='clear-filter clear-filter-responsive'>
                <div className="clear-filter clear-filter-responsive filter-show">
                    <IconButton  aria-label="filter list" onClick={(evt:any)=>handleOpenCloseFilterModal(evt)}>
                        <FilterListIcon />
                    </IconButton>
                </div>
                <div>
                    {Object.keys(apply_filters).length > 0 && <IconButton onClick={(evt:any)=>handleCleanFilters(evt)} aria-label="filter list">
                        <ClearAllIcon />
                    </IconButton>}
                </div>
            </div> 
            { openFilterDialog
                ? <FiltersDialog                
                    RenderFilters={RenderFilters}
                    options={options}
                    apply_filters={apply_filters}
                    closeDialogFunction={handleOpenCloseFilterModal}
                />
                : null
            }                                         
        </div>
            
        )
}

const FiltersDialog = ({RenderFilters, options, apply_filters, closeDialogFunction}:FiltersParamsDialog) => {
    return (
        <CreateModal
            onCloseCreationModal={(evt:any)=>closeDialogFunction(evt)}
            isOpen={true}
            inputText={RenderFilters(options, apply_filters)}
            typeModal={1}
            action={(evt:any)=>closeDialogFunction(evt)}
            typeAction={0}
            loadingModal={false}
            titleModal={'FILTROS'}
            closeButton={true}
            className={'dialog-data'}
            dialogContentClassName={'filters-dialog'}
        />     
    )
}

export default FiltersResponsive