import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

interface IOverflowCell {
  text: string;
}

const OverflowCell = (props: IOverflowCell) => {

  const textElementRef = useRef<HTMLInputElement | null>(null);
  const compareSize = () => {    
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
    const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

    if (isMobile) {
      setDevice('Mobile');
    } else if (isTablet) {
      setDevice('Tablet');
    } else {
      setDevice('Desktop');
    }      
    const compare =
      textElementRef.current!.scrollWidth > textElementRef.current!.clientWidth;
      setHover(compare);            
  };  

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);        
  }, []);

  useEffect(() => () => {
    window.removeEventListener('resize', compareSize);
  }, []);

  const [hoverStatus, setHover] = useState(false);
  const [open, setOpen] = React.useState(false);  
  const [device, setDevice] = useState('');

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {    
    if(!open && device !== 'Desktop' && hoverStatus)
      setOpen(true);
  };

  const handleToolTipMouseEnter = () => {      
    if(device === 'Desktop')
      setOpen(hoverStatus);
  }

  const handleToolTipMouseLeave = () => {
    if(device === 'Desktop')
      setOpen(hoverStatus);    
      handleTooltipClose();
  }


  return (
    <Tooltip      
      title={props.text}
      placement='top'
      interactive={false}      
      onClose={handleTooltipClose}      
      open={open}
      disableFocusListener
      disableTouchListener
      disableHoverListener
    >
      <div className='tooltipText'
        onClick={handleTooltipOpen}
        onMouseEnter={handleToolTipMouseEnter}
        onMouseLeave={handleToolTipMouseLeave}
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width:'100%',        
            
        }}
      >
        {props.text}
      </div>
    </Tooltip>
  );
};

export default OverflowCell;