import {
  array as arraySchema,
  number as numberSchema,
  object as objectSchema,
  string as stringSchema,
} from "yup";

import { InformeRouteType } from "../../../hooks/useInformesRouteParams";
import { SubmitAction } from "./types";

const getSubsectionCommentValidationSchema = ({
  type,
  submitAction,
}: {
  type: InformeRouteType;
  submitAction: SubmitAction;
}) => {

  const baseSchema = objectSchema()
    .shape({
      id: numberSchema(), // R/O
      comment: stringSchema(), // R/O
    });

  return baseSchema;
};

const getSubsectionValidationSchema = ({
  type,
  submitAction,
}: {
  type: InformeRouteType;
  submitAction: SubmitAction;
}) => {

  const commentSchema = getSubsectionCommentValidationSchema({
    type,
    submitAction,
  });
  const markSchema = numberSchema()
    .min(0, "No puede ser una nota menor a 0")
    .max(10, "No puede ser una nota superior a 10")
    .transform(
      (value, originalValue) =>
        originalValue && parseFloat(originalValue.toFixed(2))
    );

  const baseSchema = objectSchema()
    .shape({
      id: numberSchema(), // R/O
      numeration: stringSchema(), // R/O
      name: stringSchema(), // R/O
      description: stringSchema(), // R/O
      order: numberSchema(), // R/O
      //comments: arraySchema()
    })
    .required(
      "Es necesario que cada sección tenga mínimo un apartado para publicar"
    );
  switch (submitAction) {
    case SubmitAction.SAVE:
    case SubmitAction.PREVIEW:
      return baseSchema.clone().shape({
        mark:
          type === InformeRouteType.VISITAS ? markSchema.strip() : markSchema,
      });
    case SubmitAction.PUBLISH:
      return baseSchema.clone().shape({
        mark:
          type === InformeRouteType.VISITAS
            ? markSchema.strip()
            : markSchema.required(
                "Tiene que puntuar el apartado para poder publicar"
              ),
        comments: arraySchema().of(commentSchema)
          .min(1, "Necesario que haya al menos un comentario en cada apartado para publicar")
          .required(),
      });
  }
};

const getSectionValidationSchema = ({
  type,
  submitAction,
}: {
  type: InformeRouteType;
  submitAction: SubmitAction;
}) => {
  const subsectionSchema = getSubsectionValidationSchema({
    type,
    submitAction,
  });
  const baseSchema = objectSchema().shape({
    numeration: stringSchema(), // R/O
    name: stringSchema(), // R/O
    description: stringSchema(), // R/O
    order: numberSchema(), // R/O
    //group: arraySchema(numberSchema()).required(), // R/O
    groupId: numberSchema().required(), // R/O
    subsections: arraySchema().of(subsectionSchema).required(),
  });

  switch (submitAction) {
    case SubmitAction.SAVE:
    case SubmitAction.PREVIEW:
      return baseSchema.clone();
    case SubmitAction.PUBLISH:
      return baseSchema.clone().shape({
        subsections: arraySchema()
          .of(subsectionSchema)
          .min(
            1,
            "Es necesario que cada sección tenga mínimo un apartado para publicar"
          )
          .required()
      });
  }
};

export const getValidationSchema = ({
  type,
  submitAction,
}: {
  type: InformeRouteType;
  submitAction: SubmitAction;
}) => {
  const setionSchema = getSectionValidationSchema({
    type,
    submitAction,
  });
  const baseSchema = objectSchema().shape({
    name: stringSchema().required("Obligatorio para guardar o publicar"),
    type: stringSchema().oneOf([type]), // R/O
    description: stringSchema(),
    author: stringSchema(), // R/O
    dtStartVisit: stringSchema().required("Obligatorio para guardar o publicar"),
    dtEndVisit: stringSchema().nullable(), // R/O
    dtReport: stringSchema().nullable(), // R/O
    status: numberSchema(), // R/O
    statusDsc: stringSchema(), // R/O
    sections: arraySchema().of(setionSchema).required(),
  });

  switch (submitAction) {
    case SubmitAction.SAVE:
    case SubmitAction.PREVIEW:
      return baseSchema.clone().shape({
        dtStartVisit: stringSchema().nullable(),
      });
    case SubmitAction.PUBLISH:
      return baseSchema.clone().shape({
        dtEndVisit: stringSchema().required("Obligatorio para publicar"),

        team: type === InformeRouteType.AUDITORIAS
        ? stringSchema().required("Obligatorio para publicar")
        : stringSchema().strip(),
        participant: type === InformeRouteType.AUDITORIAS
        ? stringSchema().required("Obligatorio para publicar")
        : stringSchema().strip(),
        globalComment01: type === InformeRouteType.AUDITORIAS
        ? stringSchema().required(
          "Obligatorio para publicar"
        )
        : stringSchema().strip(),
        globalComment02: type === InformeRouteType.AUDITORIAS
        ? stringSchema().required(
          "Obligatorio para publicar"
        )
        : stringSchema().strip(),
        sections: arraySchema()
          .of(setionSchema)
          .min(1, "Necesario que haya al menos una sección para publicar")
          .required(),
      });
  }
};
