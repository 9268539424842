import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import Empresa from './Empresa';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import PaginatedTable from './Material/PaginatedControlPanel';
import {filter, includes, cloneDeep} from 'lodash';
import OrderService from '../ApiClients/OrderService';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import Toolbar from '@material-ui/core/Toolbar';
import {GetRole, GetEmpresa} from '../helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';
import DatePicker from './DatePicker';
import { IMe, IRegister, IIRegister, ICentroTrabajo, IFilter } from '../types';
import LoaderBeauty from './Material/activityProgress';
import CreateModal from './CreateDialog';
import List from './Material/MultiSelect';
import LateralMenu from './LateralMenu';
import SearchField from './SearchField';
import FiltersResponsive from './Filters/Filters_responsive';

export function tmRegister(centrotrabajoId: any){
    return ({
        id: -1,
        centrotrabajoId:centrotrabajoId,
        tipo:1, 
        referencia:[], 
        puestoTrabajo:-1,
        origen:-1,
        seccion:-1, 
        responsable:-1, 
        periodicidad:-1, 
        prioridad:-1, 
        referenciaDsc:'',
        detalleRiesgo:'',
        medidas:'',
        fechaReferencia:new Date(),
        comentario:'',
        tipoMedida:-1
    });
};

const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const fields = [
    {
        id: 'origen',
        name: 'Orígen',
        required: false,
        style:1,
    },
    {
        id: 'tipoMedida',
        name: 'Tipo medida',
        required: false,
        style:1,
    },
    {
        id: 'seccion',
        name: 'Sección',
        required: false,
        style:1,
    },
    {
        id: 'puestoTrabajo',
        name: 'Puesto trabajo',
        required: false,
        style:1,
    },
    {
        id: 'referencia',
        name: 'Riesgo',
        required: false,
        style:1,
    },
    {
        id: 'prioridad',
        name: 'Prioridad',
        required: false,
        style:1,
    },
    {
        id: 'responsable',
        name: 'Responsable',
        required: false,
        style:1,
    },
];

const fieldsFormListado = [
    [
        {
            id: 'origen',
            name: 'Orígen',
            required: true,
            style:1,
            typeField:1,
        },
        {
            id: 'tipoMedida',
            name: 'Tipo medida',
            required: false,
            style:1,
            typeField:1,
        },
        {
            id: 'seccion',
            name: 'Sección',
            required: true,
            style:1,
            typeField:1,
        },
        {
            id: 'puestoTrabajo',
            name: 'Puesto de trabajo',
            required: true,
            style:1,
            typeField:1,
        },
    ],    
   
    
    [
        {
            id: 'responsable',
            name: 'Responsable',
            required: false,
            style:1,
            helper:'',
            typeField:1,
        },
        {
            id: 'prioridad',
            name: 'Prioridad',
            required: true,
            style:1,
            typeField:1,
        },
        {
            id: 'periodicidad',
            name: 'Periodicidad',
            required: true,
            style:1,
            helper:'',
            maxText:2,
            typeField:1,
        },
        {
            id: 'fechaReferencia',
            name: 'Fecha Prevista Inicio',
            required: true,
            style:1,
            typeField:3,
        }
    ], 
    [    
        {
            id: 'referencia',
            name: 'Riesgo',
            required: true,
            style:3,
            typeField:5
        },
    ],
    [
        /*{
            id: 'riesgo',
            name: 'Riesgo',
            required: true,
            style:3,
            helper:'',
            maxText:1000,
            typeField:2,
            multiline:true
        },*/
        {
            id: 'detalleRiesgo',
            name: 'Detalle riesgo',
            required: false,
            style:3,
            helper:'',
            maxText:4000,
            typeField:2,
            multiline:true
        }
    ],
    [
        {
            id: 'medidas',
            name: 'Medidas',
            required: true,
            style:3,
            helper:'',
            maxText:30000,
            typeField:2,
            multiline:true
        }
    ],
    [
        {
            id: 'comentario',
            name: 'Comentarios',
            required: false,
            style:3,
            helper:'',
            maxText:1000,
            typeField:2,
            multiline:true
        }
    ]
];

interface IFormListadoItemProps {
    classes: any;
    options:any;
    handleCreateItem:Function;
    handleCloseItemForm: Function;
    loading:boolean;
    //centrotrabajoId:string;
    register: IRegister|IIRegister;
    readonly: boolean;
}

interface IFormListadoItemState {
    itemListado:any;
    warningOpen:boolean;
    warningTitle:string;
}


class FormListadoItem extends Component<IFormListadoItemProps, IFormListadoItemState> {
    constructor(props: IFormListadoItemProps) {
        super(props);
        this.state = {
            itemListado: props.register,
            warningOpen:false,
            warningTitle:"Reunión CAE",
        };
    }

    handleChangeUser=(id:any, event:any)=>{
        if(id === 'origen' && event.nativeEvent.srcElement.textContent.includes("REUNIÓN CAE")){            
            this.setState({warningOpen:true, warningTitle:event.nativeEvent.srcElement.textContent});
        }
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.value;
        this.setState({ itemListado: newItemListado });
    }    
    
    shouldDisableToday = (date:any) => {
        return false;
    }

    handleChangeDate=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event;
        this.setState({ itemListado: newItemListado });
    }

    handleListChange=(id:any, e:any, list:any)=> {
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = list;
        this.setState({ itemListado: newItemListado });
    }

    verifyForm=(type:number, id?:string)=>{

        if(type == 1){ // Verifica todo el formulario
            const item = this.state.itemListado;
            if(item.referencia == -1){
                return false;
            } else if(item.puestoTrabajo == -1){
                return false;
            } else if(item.origen == -1){
                return false;
            } else if(item.seccion == -1){
                return false;
            } else if(item.responsable == -1){
                return false;
            } else if(item.periodicidad == -1){
                return false;
            } else if(item.prioridad == -1){
                return false;
            } 
            else if(item.tipoMedida == -1){
                return false;
            }
        /*else if(item.riesgo.length == 0){
                return false;
            } else if(item.detalleRiesgo.length == 0){
                return false;
            } */else if(item.medidas.length == 0){
                return false;
            }

            return true;
        } else if(type == 2){ // Verifica el ítem

        }

        return false;
        /*let idProduct : number = Number(this.state.productInfo.id)
        let ivaProduct : number = Number(this.state.productInfo.iva)

        if(verificationType == 0) {
            switch(id){
                case "id":
                    if(idProduct <= 0){
                        return false;
                    }
                    break;
                case "name":
                    if(this.state.productInfo.name.length == 0){
                        return false;
                    }
                    break;
                case "iva":
                    if(ivaProduct < 0){
                        return false;
                    }
                    break;
            }
            return true;
        } else {

            if(idProduct <= 0){
                return false;
            }

            if(this.state.productInfo.name.length == 0){
                return false;
            }

            if(ivaProduct < 0){
                return false;
            }
            return true;
        }*/

    }
    handleResetItemForm = () => {
        this.setState({itemListado:undefined});
    }

    handleCloseWarningMessage=()=>{
        this.setState({warningOpen:false});
    }

    render() {
        const {itemListado} = this.state;
        //console.log(itemListado)
        let fieldsTMP = fieldsFormListado.map((n0:any, index:number)=>{
            let fieldsN0 = n0.map((n:any, index:number)=>{
                switch(n.typeField){
                    case 1:
                        return(
                            <SelectorField
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={this.props.options[n.id]?filter(this.props.options[n.id], function(o){return o.active}):[]}
                                handleFieldChange={this.handleChangeUser}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                disabled={this.props.readonly}
                                required={n.required}
                                style={n.style}
                                deviceData={itemListado}
                                className="dialog-item-25"
                            />
                        );
                    case 2:
                        return(
                            <TextFieldInput
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={itemListado}
                                handleFieldChange={this.handleChangeUser}
                                disabled={this.props.readonly}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                required={n.required}
                                style={n.style}
                                helper={n.helper}
                                maxText={n.maxText}
                                rows={4}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                                noError={true}
                                multiline={n.multiline}
                            />
                        );
                    case 3:
                        return(
                            <div className='dialog-item-25'>{DatePicker(n.id, n.name, "dd/MM/yyyy", this.handleChangeDate, itemListado.fechaReferencia, this.props.readonly, this.shouldDisableToday, new Date('1970-01-01'))}</div>                            
                        );
                    case 4:
                        return(
                            <div style={{marginBottom:20}}>
                                <List disabled={this.props.readonly} id={n.id} data={itemListado[n.id]} multiple={true} name={n.name} placeholder={n.name} options={this.props.options[n.id]?filter(this.props.options[n.id], function(o){return o.active}):[]} handleChangeSelect={(e:any, list:any)=>this.handleListChange(n.id,e,list)}/>
                            </div>
                        );
                    case 5:
                        return(
                            <SelectorField
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={this.props.options[n.id]?filter(this.props.options[n.id], function(o){return o.active}):[]}
                                handleFieldChange={this.handleChangeUser}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                required={n.required}
                                disabled={this.props.readonly}
                                style={n.style}
                                deviceData={itemListado}
                                className="dialog-item-100"
                            />
                        );
                    default:
                        return('');
                }
            })
            return (
                <form key={index} noValidate autoComplete="off">
                    {fieldsN0}
                </form>
            )
        })

        return (
            <div>
                <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data'>
                    <DialogTitle id="form-dialog-title">{'FORMULARIO PLANIFICACIÓN PREVENTIVO'}</DialogTitle>
                    <DialogContent>
                        {fieldsTMP}
                    </DialogContent>
                    <DialogActions>
                        {!this.props.loading?
                            <div style={{ display:'flex', gap:20 }}>
                                {this.props.readonly?
                                    <Button onClick={()=>this.props.handleCloseItemForm()} className="cancel-dialog">
                                        {'Cerrar'}
                                    </Button>
                                :
                                    <React.Fragment>
                                        <Button onClick={()=>this.props.handleCloseItemForm()} className="cancel-dialog">
                                            {'Cancelar'}
                                        </Button>
                                        <Button disabled={!this.verifyForm(1)} onClick={()=>this.props.handleCreateItem(this.state.itemListado)} className={`confirm-dialog ${(!this.verifyForm(1)?'confirm-dialog-disable':'')}`}>
                                            {`${itemListado.id == -1?`Crear`:`Actualizar`}`}
                                        </Button>
                                    </React.Fragment>
                                }
                            </div>
                        :
                            <Loader loading={true}/>
                        }
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.warningOpen} onClose={this.handleCloseWarningMessage} className='dialog-data dialog-warning' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {this.state.warningTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¡Atención! Será obligatorio añadir en el apartado comentarios el nombre de la subcontrata.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>                
                        <Button onClick={this.handleCloseWarningMessage} autoFocus>
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            
        );
    }
}
export const FormListado = withStyles(listadoStyles)(FormListadoItem);

interface IListadoProps {
    history:any;
    match:any;
    me?: IMe;
    version: string;
    filters: IFilter;
    handleChangeFilter: Function;
    handleCheckFilter: Function;
}

interface IListadoState {
    rowSelected: number;
    creationModalOpen:boolean;
    creationItemOpen: boolean;
    creationModalOpenEmail:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    htmlBody:boolean;
    sendingEmail:boolean;
    emailResult:number;
    currentYearSelected: number|string;
    loading:boolean;
    loadingOptions:boolean;
    loadingDocuments:boolean;
    documents:any;
    filters:any;
    options?:any;
    searchValue:string;
    creatingItem:boolean;
    bRegister: IRegister|IIRegister;
    loadingModal: boolean;
    titleModal: string;
    filter_id: string;
}

class Income extends Component<IListadoProps, IListadoState> {
    constructor(props: IListadoProps) {
        super(props);        
        this.state = {
            rowSelected: -1,
            //dataIncome: dataIncome,
            documents:[],
            creationModalOpen:false,
            creationItemOpen:false,
            creationModalOpenEmail:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            sendingEmail:false,
            emailResult:-1,
            htmlBody:false,
            currentYearSelected: new Date().getFullYear(),
            loading: true,
            loadingOptions: true,
            loadingDocuments: true,
            filters:{},
            searchValue: '',
            creatingItem: false,
            bRegister: tmRegister(this.props.match.params.centrotrabajoId),
            loadingModal: false,
            titleModal: `MENSAJE`,
            filter_id: "f_cp"
        };
    }

    componentDidMount(){
        this.reviewPage();
        this.loadData();
    }

    componentDidUpdate(prevProps:IListadoProps){
        this.reviewPage();
        if(prevProps.me !== this.props.me || prevProps.match.params.centrotrabajoId !== this.props.match.params.centrotrabajoId)
            this.loadData();
    }

    loadData = () => {
        const {me} = this.props;

        if(!me)
            return;
        if ([5,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) > -1) {
            this.loadControlPanel();
            this.loadOptions();
        } else {
            this.setState({
                loading:false,
                loadingOptions: false,
                loadingDocuments: false
            })
        }
    }

    loadOptions = async () => {
        let response = await OrderService.LoadOptions(this.props.match.params.centrotrabajoId);
        this.setState({loadingOptions: false, options: response});
    }

    loadControlPanel = async () => {
        const centrotrabajoId = Number(this.props.match.params.centrotrabajoId);
        let response = await OrderService.LoadPanelControl(centrotrabajoId);
        this.setState({loadingDocuments: false, documents: response});
    }

    handleFilterDocuments=(documents:any, f:any)=>{
        let newDocuments = cloneDeep(documents);

        //** FILTRA POR LISTADO ACTUAL */
        const tipo = Number(this.props.match.params.tipo);

        newDocuments = filter(newDocuments, function(o){return o.tipo == tipo});

        //** FILTRA POR LOS FILTROS APLICADOS */
        const keys = Object.keys(f);
        const values = Object.values(f);

        if(keys.length > 0){
            for(let a=0; a<keys.length;a++){
                newDocuments = filter(newDocuments, function(o){return o[keys[a]] == values[a]})
            }
        }

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o){return includes(o.code.toLowerCase(), searchValue) || includes(o.referenciaCode.toLowerCase(), searchValue) || includes(o.origenDsc.toLowerCase(), searchValue) || includes(o.seccionDsc.toLowerCase(), searchValue)  || includes(o.puestoTrabajoDsc.toLowerCase(), searchValue) || includes(o.tipoMedidaDsc.toLowerCase(), searchValue) || includes(o.responsableDsc.toLowerCase(), searchValue) || includes(o.medidas.toLowerCase(), searchValue) || includes(o.detalleRiesgo.toLowerCase(), searchValue) });
        }
        return newDocuments;
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }

    handleCleanFilters = (evt:any)=>{
        evt.stopPropagation();
        this.props.handleChangeFilter(this.state.filter_id, {}, false);
    }

    reviewPage=()=>{
        if([1,2].indexOf(Number(this.props.match.params.tipo)) == -1)
            this.props.history.push(`/`);
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false, });
    }

    onCloseFormItemModal = () => {
        this.setState({ creationItemOpen: false });
    }

    /* CALL BACKEND */


    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1:
                break;
        }
        this.onCloseCreationModal();
    }

    onCloseCreationModalEmail = () => {
        this.setState({ creationModalOpenEmail: false });
    }

    handleClickListado=(income:number)=>{
        this.setState({creationItemOpen:true, bRegister: tmRegister(this.props.match.params.centrotrabajoId)});
        /*switch(income){
            case 1:
                this.props.history.push('/generar/preventivo')
                break;
            case 2:
                this.props.history.push('/ingressos/correctivo')
                break;
        }*/
    }

    handleSelectYearChange = (event:any) => {
    }

    handleItemClick=(item:any)=>{
        this.props.history.push(`${window.location.hash.replace('#','')}/${item.id}`)
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters.f_cp);
        newFilters[id] = evt.target.value;
        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);
    }

    RenderFilters = (optionsfilters:any, arrayfilters:any) => {
        if(optionsfilters == undefined)
            return `Fallo en la carga de los filtros, vuelva a refrescar la página.`;

        let filters = fields.map((filter:any, index:number)=>{

            return (
                <SelectorField
                    key={index}
                    name={filter.name}
                    id={filter.id}
                    deviceData={arrayfilters}
                    fieldsData={optionsfilters[filter.id]?optionsfilters[filter.id]:[]}
                    handleFieldChange={this.handleChangeFilter}
                    required={filter.required}
                    style={filter.style}
                    className='filter-item-200px'
                />
            );
        })

        

        return filters;
    }

    handleCreateItem=(item:any)=>{
        this.setState({creatingItem:true})
        this.saveData(item);
    }

    saveData=async(item:any)=>{
        let response:any = await OrderService.saveData(item, 1);
        if(response == 2){
            this.setState({
                modalText: '¡ATENCIÓN! Ya existe una medida preventiva con la misma configuración.',
                modalType: 1,
                creationModalOpen: true,
                creatingItem:false,
            });
        } else {
            this.loadControlPanel();
            this.setState({creatingItem:false, creationItemOpen:false});
        }
    }

    handleClickOptionRow=(register:IRegister, option:number)=>{
        switch(option){
            case 1: // Implica editar el registro
                this.setState({creationItemOpen:true, bRegister: register})
                break;
            default:
                break;
        }
    }

    render() {

        const {loadingOptions, loadingDocuments, options, filter_id, documents, searchValue, creatingItem, bRegister} = this.state;
        const {me, filters} = this.props;
        const empresa:ICentroTrabajo|undefined = GetEmpresa(this.props.match.params.centrotrabajoId, me);

        const readonly = me?
                            ([20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1?true:false)
                        :
                            true;

        let title = (
            <div className='App-title-webPage'>
                {`Panel de control`}
                {'  '}
                {/*<Loader loading={this.state.loading} />*/}
            </div>
        );

        const apply_filters: any = filters[filter_id];        

        return (
            <div className='App-basic'>
                <Layout 
                    history={this.props.history} 
                    currentPage={1}
                    allowDrawer={true}
                    match={this.props.match}
                    version={this.props.version}
                    me={me}
                    showEmpresa
                    showLateralMenu
                />

                <div className='App-body-webBar'>
                    {me?
                        [5,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1?
                            <div>
                                <div className="App-header">
                                    {title}
                                </div>
                                <label style={{ marginLeft:10 }}>
                                    {`No tiene permisos para visualizar este espacio.`}
                                </label>
                            </div>
                        :
                            <div>
                                <div className="App-header">
                                    {title}
                                    <div className="App-header-options App-header-options-responsive">   
                                        <SearchField placeholder="Buscar..." value={searchValue} allowSearch={true} onValueChange={this.handleSearchChange} handleClearSearchValue={this.handleClearSearchValue}/>                                                                       
                                        <div style={{ display:'flex', flexWrap: 'wrap', gap:'10px' }}>                                            
                                            <div className={`App-header-options-item App-header-options-item-create ${options?null:`App-header-options-item-disable`}`} onClick={()=>(options?this.handleClickListado(1):null)}>
                                                {`Crear preventivo`}
                                            </div>                                            
                                        </div>                                        
                                        <FiltersResponsive
                                            loadingOptions={loadingOptions}
                                            RenderFilters={this.RenderFilters}
                                            apply_filters={apply_filters}
                                            handleCleanFilters={this.handleCleanFilters}
                                            options={options} 
                                        />  
                                    </div>
                                </div>                                                            
                                
                                <div>
                                    <PaginatedTable
                                        data={this.handleFilterDocuments(documents, apply_filters)}
                                        handleSearch={this.handleSearchChange}
                                        searchValue={searchValue}
                                        onClearSearchValue={this.handleClearSearchValue}
                                        handleItemClick={this.handleItemClick}
                                        handleClickOptionRow={this.handleClickOptionRow}
                                    />
                                </div>
                                {this.state.creationItemOpen?
                                    <FormListado
                                        options={options}
                                        handleCloseItemForm={this.onCloseFormItemModal}
                                        handleCreateItem={this.handleCreateItem}
                                        loading={creatingItem}
                                        register={bRegister}
                                        readonly={readonly}
                                    />
                                :
                                    null
                                }
                                {this.state.creationModalOpen?
                                    <CreateModal
                                        onCloseCreationModal={this.onCloseCreationModal}
                                        isOpen={this.state.creationModalOpen}
                                        inputText={this.state.modalText}
                                        typeModal={this.state.modalType}
                                        action={this.handleCreateModal}
                                        typeAction={this.state.modalAction}
                                        loadingModal={this.state.loadingModal}
                                        titleModal={this.state.titleModal}
                                    />
                                :
                                    null
                                }
                            </div>
                    :
                        this.state.loading?
                            <div style={{marginLeft:20}}>
                                <Loader loading={true}/>
                            </div>
                        :
                            `Fallo en la carga del perfil, volver a intentar`
                    }
                    
                    <LoaderBeauty visible={loadingOptions||loadingDocuments?true:false}/>
                </div> 
            </div>
        );
    }
}

export default Income;