import * as React from "react";
import FormCorrectivo from "../../../../../Components/FormCorrectivo";
import useOptions from "./useOptions";
import useOnSubmitFormCorrectivo from "./useOnSubmitFormCorrectivo";
import useOnSubmitFormPreventivo from "./useOnSubmitFormPreventivo";
import useOnSubmitFormObservacion from "./useOnSubmitFormObservacion";
import {
  FormListado,
  tmRegister,
} from "../../../../../Components/ControlPanel";
import FormObservacion from "../../../../../Components/FormObservacion";
import OrderService from "../../../../../ApiClients/OrderService";
import CreateModal from '../../../../../Components/CreateDialog';
import { InformeT } from "../../../types";
import { Section, Subsection } from "../../../../Section/types";
import { IRegister } from "../../../../../types";
import { Values } from "../../informes-update-form/containers";

export enum MeasureType {
  CORRECTIVO = 1,
  PREVENTIVO = 2,
  OBSERVACION = 3,
}

export type Config = {
  centroTrabajoId: number;
  informe: InformeT;
  loadData: Function;
};

export type Return = [
  React.ReactNode,
  React.ReactNode,
  { handleOpen: (subsection: Subsection, type: MeasureType, informe: Values) => void; handleClose: () => void }
];

const useInformeCreateMeasureForms = ({ centroTrabajoId, informe, loadData }: Config) => {
  const [openDialog, setOpenDialog] = React.useState<null | MeasureType>(null);
  const [saveItem, setSaveItem] = React.useState<boolean>(false);
  const [opennedModal, setOpennedModal] = React.useState<boolean>(false);
  const [textModal, setTextdModal] = React.useState<string>("");
  const [typeModal, setTypeModal] = React.useState<number>(0);
  const [c_item, setItem] = React.useState<any>(undefined);
  const [subsection, setSubsection] = React.useState<null | Subsection>(null);
  const [t_informe, setInforme] = React.useState<InformeT>(informe);

  const handleOpenDialog = React.useCallback(
    // OpenFormProps can be injected when opening the dialog
    // Thus, based on type or anything related to the action (or informe), can be used for the dialog
    // e.g. if informe type === visitas, add prop "visitas" to limit the dialog options
    (subsection: Subsection, type: MeasureType, informe:Values) => [setOpenDialog(type), setSubsection(subsection), setInforme(informe)],
    []
  );
  const handleCloseDialog = React.useCallback(() => [setOpenDialog(null), setSubsection(null), setOpennedModal(false), setSaveItem(false)], []);

  const { options, loading: optionsLoading } = useOptions({ centroTrabajoId });

  // Confirmation item
  const ConfirmationSaveItem = (item:IRegister, action:number) => {
    item.reportId = t_informe.id;
    item.reportType = t_informe.tipo;
    item.sectionId = subsection?.sectionId;
    item.subsectionId = subsection?.id;

    setItem(item);
    setTypeModal(2);
    // console.log(item)
    
    let txt = "¡ATENCIÓN! ¿Quiere guardar?";

    switch(openDialog) {
      case MeasureType.CORRECTIVO:
        txt = "¡ATENCIÓN! ¿Quiere crear el correctivo?";
        break;
      case MeasureType.PREVENTIVO:
        txt = "¡ATENCIÓN! ¿Quiere crear el preventivo?";
        break;
      case MeasureType.OBSERVACION:
        txt = "¡ATENCIÓN! ¿Quiere crear la observación?";
        break;
    }
    
    setTextdModal(txt);
    setOpennedModal(true);

    // console.log(t_informe);
  }

  // Save item
  const SaveItem = async (item:any, action:number) => {
    setSaveItem(true);
    setTypeModal(1);

    // This is the reused submit
    let responseInforme:any = await OrderService.saveData(t_informe, 18);
    
    if(responseInforme == 1) {
      // This is the reused submit
      let response:any = await OrderService.saveData(item, action);

      if(response == 1) {
        handleCloseDialog();
        loadData();
      } else if(response == 2 && MeasureType.PREVENTIVO) {
        setTextdModal("¡ATENCIÓN! Ya existe un preventivo con la misma configuración.");
        setSaveItem(false);
      } else {
        setTextdModal("¡ATENCIÓN! No se ha podido realizar la acción.");
        setSaveItem(false);
      }
    } else {
      setTextdModal("¡ATENCIÓN! No se ha podido realizar la acción.");
      setSaveItem(false);
    }
  }

  // Form correctivo props
  const onSubmitFormCorrectivo = useOnSubmitFormCorrectivo({
    onSuccess: ConfirmationSaveItem,
  });
  // end form correctivo

  // Form preventivo props
  const onSubmitFormPreventivo = useOnSubmitFormPreventivo({
    onSuccess: ConfirmationSaveItem,
  });
  const bRegister = tmRegister(centroTrabajoId);
  // end form preventivo

  // Form observaciones props
  const OnSubmitFormObservacion = useOnSubmitFormObservacion({
    onSuccess: ConfirmationSaveItem,
  });

  const handleCreateModal = (action:number) => {
    switch(action) {
      // Create preventivo
      case 1:
        SaveItem(c_item, action);
        break;
      // Create correctivo
      case 2:
        SaveItem(c_item, action);
        break;
      // Create obs
      case 99999:
        SaveItem(c_item, action);
        break;
      default:
        handleCloseDialog();
    }
  }


  return [
    (() => {
      switch (openDialog) {
        case MeasureType.CORRECTIVO:
          return (
            <React.Fragment>
              <FormCorrectivo
                centrotrabajoId={centroTrabajoId}
                options={options}
                handleCloseItemForm={handleCloseDialog}
                handleCreateItem={onSubmitFormCorrectivo}
                loading={optionsLoading || saveItem}
              />
              <CreateModal
                onCloseCreationModal={()=>setOpennedModal(false)}
                isOpen={opennedModal}
                inputText={textModal}
                typeModal={typeModal}
                action={handleCreateModal}
                typeAction={2}
                loadingModal={saveItem}
                titleModal={`MENSAJE`}
              />
            </React.Fragment>
          );
        case MeasureType.PREVENTIVO:
          return (
            <React.Fragment>
              <FormListado
                options={options}
                handleCloseItemForm={handleCloseDialog}
                handleCreateItem={(item: any) => onSubmitFormPreventivo(item)}
                loading={optionsLoading || saveItem}
                register={bRegister}
                readonly={false}
              />
              <CreateModal
                onCloseCreationModal={()=>setOpennedModal(false)}
                isOpen={opennedModal}
                inputText={textModal}
                typeModal={typeModal}
                action={handleCreateModal}
                typeAction={1}
                loadingModal={saveItem}
                titleModal={`MENSAJE`}
              />
            </React.Fragment>
          );
        case MeasureType.OBSERVACION:
          return (
            <React.Fragment>
              <FormObservacion
                handleCloseItemForm={handleCloseDialog}
                handleCreateItem={OnSubmitFormObservacion}
                loading={optionsLoading || saveItem}
              />
              <CreateModal
                onCloseCreationModal={()=>setOpennedModal(false)}
                isOpen={opennedModal}
                inputText={textModal}
                typeModal={typeModal}
                action={handleCreateModal}
                typeAction={99999}
                loadingModal={saveItem}
                titleModal={`MENSAJE`}
              />
            </React.Fragment>
          );
        default:
          return null;
      }
    })(),
    { handleOpen: handleOpenDialog, handleClose: handleCloseDialog },
  ] as const;
};

export default useInformeCreateMeasureForms;

export function isMeasureType(type: number): type is MeasureType {
  return Object.values(MeasureType).includes(type);
}
