import * as React from "react";
import { Field, FieldArray } from "formik";

import SectionsFieldInner from "./SectionsFieldInner";
import type { Props } from "./types";

const SectionsField = ({ name, component, ...props }: Props) => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => (
      <Field
        {...props}
        __component={component}
        component={SectionsFieldInner}
        name={name}
        arrayHelpers={arrayHelpers}
      />
    )}
  />
);

export default SectionsField;
