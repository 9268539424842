// import React from 'react';
// import Checkbox from '@material-ui/core/Checkbox';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import { Editor } from '@tinymce/tinymce-react';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// interface ITExtEditorProps {
//   id?:string;
//   value:string;
//   handleChange:Function;
//   handleOnFocus: Function;
//   handleOnBlur: Function;
// }

// export default function TextEditor(props:ITExtEditorProps) {
//   const editorRef:any = React.useRef(null);

//   return (
//     <Editor
//       apiKey='oftyll90ov1v8av78a6s0enwjt0noms7ed316kzbwc0azk68'
//       onInit={(_evt:any, editor:any) => editorRef.current = editor}
//       value={props.value}
//       onEditorChange={(evt:any, editorRef: any)=>props.handleChange(evt, editorRef)}
//       // onInput={(evt:any)=>handleChangeComment(comment, evt)}
//       // onChange={(evt:any)=>handleChangeComment(comment, evt)}
//       // onUndo={(evt:any)=>handleChangeComment(comment, evt)}
//       // onRedo={(evt:any)=>handleChangeComment(comment, evt)}

//       // onKeyUp={(evt:any)=>handleChangeComment(comment, evt)}
//       onBlur={()=>props.handleOnBlur}
//       onFocus={()=>props.handleOnFocus}
//       init={{
//         height: 500,
//         width:'100%',
//         menubar: false,
//         branding: false,
//         plugins: [
//           'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
//           'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
//           'insertdatetime', 'media', 'table', 'code', 'wordcount'
//         ],
//         // toolbar: 'undo redo | blocks | ' +
//         //   'bold italic forecolor | alignleft aligncenter ' +
//         //   'alignright alignjustify | bullist numlist outdent indent | ' +
//         //   'removeformat | help',
//         //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
//         // }}
//         toolbar: 'undo redo | ' +
//         'bold italic underline forecolor backcolor | bullist numlist outdent indent',
//         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
//       }}
//     />
//   );
// }
import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface ITExtEditorProps {
  initialValue?: string;
  value: string;
  onChange?: (editor: any) => void;
  onBlur?: (editor: any) => void;
  onFocus?: (editor: any) => void;
}

const TextEditor: React.FC<ITExtEditorProps> = ({ initialValue, onChange, value, onFocus, onBlur }) => {
  const editorRef = useRef<any>(null);

  const initial = (evt:any, editor:any) => {
    editorRef.current = editor;
    editor.focus();
  };

  const handleEditorChange = (content: string, editor: any) => {
    if (onChange) {
      onChange(editor);
    }
  };

  const handleEditorOnFocus = (editor: any) => {
    if (onFocus) {
      onFocus(editor);
    }
  };

  const handleEditorOnBlur = (editor: any) => {
    if (onBlur) {
      onBlur(editor);
    }
  };

  return (
    <Editor
      apiKey='oftyll90ov1v8av78a6s0enwjt0noms7ed316kzbwc0azk68'
      onInit={(evt, editor) => initial(evt, editor)}
      // initialValue={initialValue}
      value={value}
      init={{
        height: 500,
        width:'100%',
        menubar: false,
        branding: false,
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'code', 'wordcount'
        ],
        // toolbar: 'undo redo | blocks | ' +
        //   'bold italic forecolor | alignleft aligncenter ' +
        //   'alignright alignjustify | bullist numlist outdent indent | ' +
        //   'removeformat | help',
        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        // }}
        // toolbar: 'undo redo | ' +
        // 'bold italic underline forecolor backcolor | bullist numlist outdent indent',
        toolbar: 'undo redo | ' +
        'bold italic underline forecolor backcolor | bullist numlist outdent indent',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
      onEditorChange={handleEditorChange}
      onFocus={handleEditorOnFocus}
      onBlur={handleEditorOnBlur}
    />
  );
};

export default TextEditor;