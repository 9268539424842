import React, { Fragment, useState }from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { createStyles } from '@material-ui/core';
//"dd/MM/yyyy"
//"Fecha de Entrega"
const userStyles = ({
  textField: {
    //marginLeft: 2,
    //marginRight: 15,
    //marginBottom: 20,
    //minWidth: 200
  },
  textFieldFullWidth: {
    width:'100%',
    marginBottom: 20

  },
  textFieldMiddleWidth: {
    marginLeft: 1,
    marginRight: 15,
    width:'30%',
    marginBottom: 20,
    minWidth: 200

  },
  formControl: {
      marginRight: 20,
      marginBottom: 20,
      //width:'100%',
      minWidth: 200
  },
});

const muiTheme = createStyles({
  datePicker: {
    selectColor: "#0E6EB8",
  },
});

function returnStyle(typeStyle:number){
  switch(typeStyle){
      case 1:
          return userStyles.textField
      case 2:
          return userStyles.textFieldMiddleWidth
      case 3:
          return userStyles.textFieldFullWidth
      default:
          return userStyles.textField
  }
}

function cDatePicker(id:string, label:string, format:string, handleChange:Function, data:string, disabled:boolean, shouldDisableDate:any, minDate:Date, style?:any, className?:string) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
    <DatePicker
        label={label}
        value={data}
        onChange={(evt:any)=>handleChange(id, evt)}
        animateYearScrolling
        format={format}
        minDate={minDate}
        shouldDisableDate={shouldDisableDate}
        disabled={disabled}
        style={style?returnStyle(style):undefined}
        className={className ? className : "datepicker-100"}
    />
    </MuiPickersUtilsProvider>
  );
}

export default cDatePicker;