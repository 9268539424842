import React, { ReactElement } from "react";
import { ICentroTrabajo } from "../../../types";
import { InformeT } from "../types";

//Context to pass informe data from InformeOverview to InformeUpdateFormInner, to add PDF generation to PUBLICAR button

type InformeContextType = {
  generatePdfBlob?: (
    informe: InformeT,
    empresa: ICentroTrabajo,
    fileName: string
  ) => Promise<void>;
  informe?: InformeT;
  empresa?: ICentroTrabajo;
  MyDocument?: (props: { c_informe: InformeT }) => ReactElement;
};

const InformeContext = React.createContext<InformeContextType>({});

export default InformeContext;