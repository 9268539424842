import * as React from "react";
import { Field, FieldArray } from "formik";

import SubsectionCommentsFieldInner from "./SubsectionCommentsFieldInner";
import type { Props } from "./types";

const SubsectionCommentsField = ({ name, component, ...props }: Props) => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => (
      <Field
        {...props}
        __component={component}
        component={SubsectionCommentsFieldInner}
        name={name}
        arrayHelpers={arrayHelpers}
      />
    )}
  />
);

export default SubsectionCommentsField;
