import React from 'react';
import {GetEmpresa} from '../helpers';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { IMe, ICentroTrabajo } from '../types';
import './Empresa.css';


interface IEmpresaProps {
    match:any;
    me?:IMe,
    history:any;
    handleToogle:any;
}

export default function MenuAppBar(props:IEmpresaProps) {
    // const [lateralMenu, setLateralMenu] = React.useState(false);

    // const drawerOpenned = Boolean(lateralMenu);

    // const handleToogle = (event: React.MouseEvent<HTMLElement>) => {
    //     setLateralMenu(!drawerOpenned);
    // };

    const {me, handleToogle} = props;
    const empresa:ICentroTrabajo|undefined = GetEmpresa(props.match.params.centrotrabajoId, me);

    return (
        <div className='App-header-webBar'>
            <div className='feature-icon-wrapper'>
                <img src={empresa?.logo} alt='icon' className='feature-icon' />
            </div>
            <span className='feature-name'>{empresa?.description}</span>
            <div className='App-menu-button' >
                <IconButton edge="start" onClick={handleToogle} className='classes.menuButton' color='inherit' aria-label='menu'>
                    <MenuIcon />
                </IconButton>
            </div>
            {/*<Drawer opened={drawerOpenned} onToggle={handleToogle} history={props.history} match={props.match} anchor={'left'} me={props.me}/>*/}
                 
            
           
        </div>
    );
}