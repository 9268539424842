import * as React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { MeasureType } from "../hooks/useInformeCreateMeasureForms";
import { Section, Subsection } from "../../../../Section/types";

type ClassKey = "actionsMenu" | "actionsMenuItem";

export type Props = {
  anchorEl: Element | null;
  subsection: Subsection & { mark?: number };
  onClose: () => void;
  onSelectMeasure: (subsection: Subsection, type: MeasureType) => void;
  classes?: ClassNameMap<ClassKey>;
};

const MeasureTypeMenu = ({
  anchorEl,
  onClose,
  onSelectMeasure,
  subsection,
  classes,
}: Props) => {
  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      keepMounted
      className={classes?.actionsMenu}
    >
      <MenuItem
        className={classes?.actionsMenuItem}
        onClick={() => onSelectMeasure(subsection, MeasureType.PREVENTIVO)}
      >
        {"Preventivo"}
      </MenuItem>
      <MenuItem
        className={classes?.actionsMenuItem}
        onClick={() => onSelectMeasure(subsection, MeasureType.CORRECTIVO)}
      >
        {"Correctivo"}
      </MenuItem>
      {/* <MenuItem
        className={classes?.actionsMenuItem}
        onClick={() => onSelectMeasure(MeasureType.OBSERVACION)}
      >
        {"Observación"}
      </MenuItem> */}
    </Menu>
  );
};

export default MeasureTypeMenu;
