import * as React from "react";
import {
  Document,
  Page,
  Text,
  View,
  pdf,
  Image,
} from "@react-pdf/renderer";
import InformeUpdateForm from "../modules/informes-update-form/containers/InformeUpdateForm";
import InformeActionsTable, {
  Props as InformeActionsTableProps,
} from "../modules/informe-actions-table/containers/InformeActionsTable";
import { InformeT } from "../types";
import { InformeRouteType } from "../hooks/useInformesRouteParams";
import useInformeActions from "../modules/informe-actions-table/hooks/useInformeActions";
import useInformeCreateMeasureForms from "../modules/informe-create-measure/hooks/useInformeCreateMeasureForms";
import { ReactElement } from "react";
import InformeContext from "../hooks/InformeContext";
import { Section } from "../../Section/types";
import { PdfStyles } from "./pdfStyles";
import { MyDocument } from "./PdfTemplate";
import { ICentroTrabajo, IMe } from "../../../types";
import { GetEmpresa } from "../../../helpers";


export type Props = {
  informeRouteType: InformeRouteType;
  informe: InformeT;
  readOnly?: boolean;
  handleNavigateToReports: Function;
  onNavigateToMeasure?: InformeActionsTableProps["onClickAction"];
  centroTrabajoId: number;
  // onFormChange: Function;
  loadData: Function;
  me?: IMe;
};




//PDF Content


//Funcion to generate BASE64 shared thru InformeContext to PUBLICAR button on InformeUpdateFormInner
const generatePdfBlob = async (informe:InformeT, empresa: ICentroTrabajo) => {
  const pdfDocument = <MyDocument informe={informe} empresa={empresa} />; // Utiliza 'informe' directamente desde los props
  const blob = await pdf(pdfDocument).toBlob();
  const reader = new FileReader();
  
  return new Promise<any>((resolve, reject)=>{
    reader.readAsDataURL(blob);

    reader.onloadend = function () {
      const base64data = reader.result;
      //console.log(base64data);
      resolve(base64data);
    };

    reader.onerror = function(err) {
      reject(err);
    };

    reader.onabort = function() {
      reject("Abort");
    };

  })
};

const InformeOverview = ({
  informeRouteType,
  informe,
  readOnly,
  handleNavigateToReports,
  onNavigateToMeasure,
  // onFormChange,
  centroTrabajoId,
  loadData,
  me,
}: Props) => {
  const informeActions = useInformeActions({ informe });
  
  const [measureDialogForm, { handleOpen }] = useInformeCreateMeasureForms({
    centroTrabajoId,
    informe,
    loadData
  });

  const empresa:ICentroTrabajo|undefined = GetEmpresa(centroTrabajoId, me);

// Función temporal para abrir el PDF en una nueva ventana, ahora como función interna
const openPdfInNewWindow = async (empresa:ICentroTrabajo) => {
  const pdfDocument = <MyDocument informe={informe} empresa={empresa} />; // Utiliza 'informe' directamente desde los props
  const blob = await pdf(pdfDocument).toBlob();
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank', 'noopener,noreferrer');
};

  return (
    <InformeContext.Provider
      value={{
        generatePdfBlob,
        informe,
        empresa,
        MyDocument: () => <MyDocument informe={informe} empresa={empresa}/>,
      }}
    >
      <React.Fragment>
        {/* Botón temporal para abrir el PDF */}
        {/* {empresa?
          <button onClick={()=>openPdfInNewWindow(empresa)} style={{ margin: '20px', padding: '10px' }}>
            Abrir PDF en Nueva Ventana
          </button>
        :
          null
        } */}
        <InformeUpdateForm
          title={`${
            informeRouteType === InformeRouteType.AUDITORIAS
              ? "Auditoría"
              : "Visita"
          }  / ${informe.code}${
            // readOnly || informe.status !== 0 ? " - Solo lectura" : ""
            readOnly || [50, 99, 98, 97].indexOf(informe.status) > -1 ? " - Solo lectura" : ""
          }`}
          informe={informe}
          //disabled={readOnly || informe.status !== 0}
          disabled={readOnly || [50, 99, 98, 97].indexOf(informe.status) > -1}
          informeRouteType={informeRouteType}
          onOpenMeasureDialog={handleOpen}
          handleNavigateToReports={handleNavigateToReports}
          // onFormChange={onFormChange(true)}
        />
        
        <InformeActionsTable
          actions={informeActions}
          onClickAction={onNavigateToMeasure}
        />
        {measureDialogForm}
      </React.Fragment>
    </InformeContext.Provider>
  );
};

export default InformeOverview;
