import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CamIcon from '@material-ui/icons/PhotoCamera';
import CleanPhotoIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';
import ChangeCam from '@material-ui/icons/FlipCameraAndroid';
import ResetIcon from '@material-ui/icons/AutorenewOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop:20,
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      border:'3px solid rgb(0, 43, 97, 0.60)',
      borderRadius:'5px'
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

interface ISignatureProps {
  handleSignatureTaken: Function;
  onClose: any;
  isOpen: boolean;
  firmante?: string;
}

interface IPosition {
    x: number;
    y: number;
}

export default function SignaturePanel(props: ISignatureProps) {
    const classes = useStyles();

    const [initialized, setInitialized] = React.useState(false);
    const [signed, setSigned] = React.useState(false);

    const panel_ref:any = React.useRef(null);
    const canvas_ref:any = React.useRef(null);

    let position:IPosition = {x:0,y:0};
    
    function preventDefault(e:any){
        e.preventDefault();
    }

    function disableScroll(){
        document.body.addEventListener('touchmove', preventDefault, { passive: false });
    }
    // new position from mouse event
    function setMousePosition(e:any) {
        let ncoords:IPosition = {x:0,y:0};

        if(e.type == 'touchstart' || e.type == 'touchmove') {

            let rect = e.target.getBoundingClientRect();
            var touch = e.touches[0] || e.changedTouches[0];

            let x = touch.clientX-rect.x;
            let y = touch.clientY-rect.y;

            ncoords = {x:x, y:y};
            position = ncoords;
        } else if (e.type == 'mousemove' || e.type == 'mouseenter' || e.type == 'mousedown') {
            ncoords = {x:e.offsetX, y:e.offsetY};
            position = ncoords;
        }
        
        return ncoords;
    }
    
    function draw(e:MouseEvent) {
        // mouse left button must be pressed
        if (e.type == 'touchmove' || e.buttons === 1) {
        
            //let canvas:any = document.getElementById('canvas_signature');
            let ctx:any = canvas_ref.current.getContext('2d');

            ctx.beginPath(); // begin
        
            ctx.lineWidth = 3;
            ctx.lineCap = 'round';
            ctx.strokeStyle = '#000';
            //alert(position.x)
            //alert(position.y)

            ctx.moveTo(position.x, position.y); // from
            let ncoords:IPosition = setMousePosition(e);
            ctx.lineTo(ncoords.x, ncoords.y); // to

            ctx.stroke(); // draw it!
            setSigned(true);
        }
    }

    function clear(){
        let ctx:any = canvas_ref.current.getContext('2d');
        ctx.clearRect(0, 0, canvas_ref.current.width, canvas_ref.current.height);
        setSigned(false);
    }

    function saveSignature(){
        props.handleSignatureTaken(canvas_ref.current.toDataURL());
    }

    const closePanel = () => {
        // Quitar listeners

        // Cerrar panel
        props.onClose();
    }

    function resizeCanvas(){

        if(canvas_ref.current == null || panel_ref.current == null)
            return;
        canvas_ref.current.width = panel_ref.current.offsetWidth - 100;
        canvas_ref.current.height = panel_ref.current.offsetHeight - 20;
    }

    function addListeners(){
        setTimeout(() => {
            if(canvas_ref.current == null || panel_ref.current == null){
                addListeners();
            } else {
                //disableScroll();
                canvas_ref.current.width = panel_ref.current.offsetWidth - 50;
                canvas_ref.current.height = panel_ref.current.offsetHeight - 20;

                canvas_ref.current.addEventListener('mousemove', draw);
                canvas_ref.current.addEventListener('mousedown', setMousePosition);
                canvas_ref.current.addEventListener('mouseenter', setMousePosition);
                canvas_ref.current.addEventListener('touchmove', draw);
                canvas_ref.current.addEventListener('touchstart', setMousePosition);
    
                setInitialized(true)
            }

        }, 150);
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        addListeners();

        //window.addEventListener("resize", resizeCanvas);
        //return () => window.removeEventListener("resize", resizeCanvas);
    }, []);

    return (
    <Dialog
        open={props.isOpen}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        //maxWidth={"xl"} 
    >
        <DialogTitle style={{marginRight:30, whiteSpace:'pre-line'}} id="alert-dialog-slide-title">{`${props.firmante?`FIRMA:\r\n${props.firmante}`:`FIRMA`}`}</DialogTitle>
        <IconButton aria-label="close" style={{position:'absolute', top:10, right:10}} onClick={closePanel}>
            <CloseIcon />
        </IconButton>
        <DialogContent ref={panel_ref} style={{backgroundColor:'#eee', textAlign:'center'}}>
            <div />
            <canvas
                ref={canvas_ref}
                style={{backgroundColor:'#ddd'}}
                hidden={false}
                width={'auto'}
                height={'auto'}
            />
        </DialogContent>
        <DialogActions style={{textAlign:'center', backgroundColor:'#ddd'}}>
            <IconButton disabled={!initialized} style={{marginLeft:'auto',marginRight:'auto'}} aria-label="close" onClick={()=>clear()} >
                <ResetIcon />
            </IconButton>
            <IconButton disabled={!signed} style={{marginLeft:'auto', marginRight:'auto'}} aria-label="close" onClick={()=>saveSignature()} >
                <SaveIcon />
            </IconButton>
        </DialogActions>
    </Dialog>
    );
}