import * as React from "react";
import { Form } from "formik";
import { Values, FormInnerProps } from "./types";
import { Fab, Typography, makeStyles } from "@material-ui/core";
import { SubmitAction } from "../../containers";
import InformeUpdateFormFields from "./InformeUpdateFormFields";
import classnames from "classnames";
import { Save } from "@material-ui/icons";
import LoadingButton from "../../../../../material-ui/components/LoadingButton";
import InformeContext from "../../../../hooks/InformeContext";
import { useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Subsection } from "../../../../../Section/types";
import { MeasureType } from "../../../informe-create-measure/hooks/useInformeCreateMeasureForms";
import AccountCircle from '@material-ui/icons/Visibility';

export type Props = FormInnerProps;

const useStyles = makeStyles(() => ({
  root: {},
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  publishButton: {
    borderRadius: 10,
  },
  previewButton: {
    borderRadius: 10,
    width: '50px',
    marginBottom: 20
  },
  saveFab: {
    margin: 0,
    top: "auto",
    right: 40,
    bottom: 40,
    left: "auto",
    position: "fixed",
    zIndex:1000,
  },
  progress: {},
}));

let intervalAutoSave:any = undefined;

const InformeUpdateFormInner = (props: Props) => {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    disabled,
    disabledFields,
    isSubmitting,
    submitForm,
    handleSubmit,
    title,
    Header,
    Content,
    Actions,
    onClickSubmit,
    onFormChange,
    onOpenMeasureDialog,
  } = props;

  const classes = useStyles();

  if(!intervalAutoSave) {
    intervalAutoSave = setInterval(() => {
      onClickSubmit(SubmitAction.SAVE);
      setTimeout(() => {
        submitForm();
      }, 500);
    }, 120000);
  }

  React.useEffect(() => {
    return () => {
      clearInterval(intervalAutoSave);
      intervalAutoSave = undefined;
    }
  }, [])

  // const interval = setInterval(() => {
  //   submitForm();
  // }, 5000);

  const HeaderComponent = Header || "div";
  const ContentComponent = Content || "div";
  const ActionsComponent = Actions || "div";
  const history = useHistory();
  const handleDateChange = React.useCallback(
    (
      field: Values["dtCreated" | "dtStartVisit" | "dtReport"],
      formattedDate: string | null
    ) => {
      if(onFormChange)
        onFormChange(true)

      setFieldValue(field, formattedDate);
    },
    [setFieldValue, onFormChange]
  );

  // const onFormChangeState = React.useCallback( () => {
  //   console.log('handle Form Change State');
  //   onFormChange(true);
  // }, [onFormChange]    
  // );
  return (
    <>
            {/* <button onClick={()=>alert('eooo')}>
  dale
</button> */}
      <Form className={classes.root} onChange={()=>onFormChange?onFormChange(true):null}>
        <HeaderComponent className={classnames("App-header", classes.header)}>
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            {title}
          </Typography>

          {!disabled && (
            <React.Fragment>
              <LoadingButton
                className={classnames("confirm-dialog", classes.publishButton)}
                classes={{ progress: classes.progress }}
                //onClick={handleOpenConfirmationModal}
                onClick={() => onClickSubmit(SubmitAction.PUBLISH)}
                isLoading={isSubmitting}
              >
                <Typography variant="h5">{`PUBLICAR`}</Typography>
              </LoadingButton>


          {/* <button onClick={() => onClickSubmit(SubmitAction.PREVIEW)} style={{ margin: '20px', padding: '10px' }}>
            Abrir PDF en Nueva Ventana
          </button> */}
          </React.Fragment>
          )}
        </HeaderComponent>

        <ContentComponent>
          <LoadingButton
                className={classnames("confirm-dialog", classes.previewButton)}
                classes={{ progress: classes.progress }}
                //onClick={handleOpenConfirmationModal}
                onClick={() => onClickSubmit(SubmitAction.PREVIEW)}
                isLoading={isSubmitting}
              >
            <AccountCircle />
          </LoadingButton>

          <InformeUpdateFormFields
            isSubmitting={isSubmitting}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            disabled={disabled}
            disabledFields={disabledFields}
            onDateChange={handleDateChange}
            onFormChange={onFormChange}
            onOpenMeasureDialog={(subsection:Subsection, type: MeasureType)=>onOpenMeasureDialog(subsection, type, values)}
          />
        </ContentComponent>

        <ActionsComponent>
          {!disabled && (
            <LoadingButton
              button={Fab}
              className={classnames("confirm-dialog", classes.saveFab)}
              classes={{ progress: classes.progress }}
              onClick={() => onClickSubmit(SubmitAction.SAVE)}
              isLoading={isSubmitting}
            >
              <Save />
            </LoadingButton>
          )}
        </ActionsComponent>
      </Form>
    </>
  );
};

export default InformeUpdateFormInner;
