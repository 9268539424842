import React from 'react';
import './activityProgressCreateFile.css';

/* eslint-disable react/prefer-stateless-function */
type ActivityProgressState = {
}
type ActivityProgressProps = {

}
// Només perque vegis com fer una animació simple de càrrega
class ActivityProgress extends React.Component<ActivityProgressProps, ActivityProgressState> {
    constructor(props:ActivityProgressProps) {
        super(props);
        this.state = { };
    }

    render() {
        return (
            <div className='preview_animation'>
                <div className='loadPDF'>
                    <p><u></u><u></u><u></u><u></u></p>
                    <p><u></u><u></u><u></u><u></u></p>
                </div>
            </div>
        );
    }
}

export default ActivityProgress;
