import { SelectorField } from "../../../../../Components/FormsWidgets";
import IconButton from "@material-ui/core/IconButton";
import ClearAllIcon from "@material-ui/icons/LayersClearOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import Toolbar from "@material-ui/core/Toolbar";
import { groupBy } from "lodash";
import { InformeT } from "../../../types";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { InformeRouteType } from "../../../hooks/useInformesRouteParams";
import FiltersResponsive from "../../../../../Components/Filters/Filters_responsive";

const fields_audit = [
  {
    id: "name",
    name: "Nombre",
    required: true,
    style: 1,
    type: 1,
  },
  {
    id: "dtStartVisit",
    name: "Fecha Visita",
    required: true,
    style: 1,
    type: 3,
  },
  {
    id: "statusDsc",
    name: "Estado",
    required: true,
    style: 1,
    type: 1,
  },
  {
    id: "dtReport",
    name: "Fecha Informe",
    required: true,
    style: 1,
    type: 3,
  },
  {
    id: "lowerMark",
    name: "Puntuación mínima",
    required: true,
    style: 1,
    type: 1,
  },
  {
    id: "upperMark",
    name: "Puntuación máxima",
    required: true,
    style: 1,
    type: 1,
  },
];

const fields_visits = [
  {
    id: "name",
    name: "Nombre",
    required: true,
    style: 1,
    type: 1,
  },
  {
    id: "dtStartVisit",
    name: "Fecha Visita",
    required: true,
    style: 1,
    type: 3,
  },
  {
    id: "statusDsc",
    name: "Estado",
    required: true,
    style: 1,
    type: 1,
  },
  {
    id: "dtReport",
    name: "Fecha Informe",
    required: true,
    style: 1,
    type: 3,
  }
];

type Config = {
  handleChangeFilter: Function;
  handleCleanFilters: Function;
  handleChangeFilterDate: Function;
  loadingOptions: boolean;
  options?: any;
  apply_filters: any;
  infomeType: InformeRouteType;
};

const InformesTableFilters = (props: Config) => {
  const {
    handleChangeFilter,
    loadingOptions,
    options,
    apply_filters,
    handleCleanFilters,
    handleChangeFilterDate,
    infomeType,
  } = props;

  const renderFilters = (informes: Array<InformeT>, arrayfilters: any) => {
    if (informes === undefined)
      return `Fallo en la carga de los filtros, vuelva a refrescar la página.`;

    const grouped_status = groupBy(informes, function (register) {
      return register.statusDsc;
    });
    const merged_status = Object.keys(grouped_status).map(
      (status: string, idx: number) => {
        return {
          id: status,
          name: status.toUpperCase(),
        };
      }
    );

    const grouped_name = groupBy(informes, function (register) {
      return register.name;
    });
    const merged_name = Object.keys(grouped_name).map(
      (name: string, idx: number) => {
        return {
          id: name,
          name: name.toUpperCase(),
        };
      }
    );

    const markValues = Array.from(Array(10).keys()).map((value) => {
      return {
        id: value + 1,
        name: value + 1,
      };
    });

    const data: any = {
      name: merged_name,
      statusDsc: merged_status,
      lowerMark: markValues,
      upperMark: markValues,
    };

    let filters = (props.infomeType == InformeRouteType.AUDITORIAS?fields_audit:fields_visits).map((filter: any, index: number) => {
      if (filter.type === 1)
        return (
          <SelectorField
            key={index}
            name={filter.name}
            id={filter.id}
            deviceData={arrayfilters}
            fieldsData={data[filter.id] ? data[filter.id] : []}
            handleFieldChange={handleChangeFilter}
            required={filter.required}
            style={filter.style}
            className="filter-item-200px"
          />
        );
      else if (filter.type === 2)
        return (
          <SelectorField
            key={index}
            name={filter.name}
            id={filter.id}
            deviceData={arrayfilters}
            fieldsData={data[filter.id] ? data[filter.id] : []}
            handleFieldChange={handleChangeFilter}
            required={filter.required}
            style={filter.style}
            className="filter-item-200px"
          />
        );
      else if (filter.type === 3)
        return (
          <MuiPickersUtilsProvider key={index} utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              key={index}
              label={filter.name}
              value={arrayfilters[filter.id] ? arrayfilters[filter.id] : null}
              onChange={(evt: any) => handleChangeFilterDate(filter.id, evt)}
              animateYearScrolling
              format="dd/MM/yyyy"
              minDate={new Date("1970-01-01")}
              shouldDisableDate={undefined}
              disabled={false}
              required={true}
              style={{
                marginRight: 0,
                backgroundColor: "white",
                borderRadius: 11,
                height: "100%",
                marginTop: 10,
                minWidth: 200,
                marginBottom: 10,
              }}
              className="filter-item-200px"
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
        );
      else return null;
    });

    return filters;
  };

  return (
    <FiltersResponsive
      loadingOptions={false}
      RenderFilters={renderFilters}
      apply_filters={apply_filters}
      handleCleanFilters={handleCleanFilters}
      options={options} 
    />
    // <div>
    //   {loadingOptions ? null : (
    //     <div className="filter-list">
    //       <Toolbar variant="dense" className="filter-list-toolbar">
    //         {renderFilters(options, apply_filters)}
    //       </Toolbar>
    //       <div className="clear-filter">
    //         {Object.keys(apply_filters).length === 0 ? (
    //           <IconButton aria-label="filter list">
    //             <FilterListIcon />
    //           </IconButton>
    //         ) : (
    //           <IconButton
    //             onClick={(evt: any) => handleCleanFilters(evt)}
    //             aria-label="filter list"
    //           >
    //             <ClearAllIcon />
    //           </IconButton>
    //         )}
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
};

export default InformesTableFilters;
