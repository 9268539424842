import * as React from "react";
import type { FormikProps, FormikHelpers } from "formik";

export default function useFormikActions<Values = {}>({
  setStatus,
  setErrors,
  setSubmitting,
  setTouched,
  setValues,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  validateForm,
  validateField,
  resetForm,
  submitForm,
  setFormikState,
}: FormikProps<Values>): FormikHelpers<Values> {
  return React.useMemo(
    () => ({
      setStatus,
      setErrors,
      setSubmitting,
      setTouched,
      setValues,
      setFieldValue,
      setFieldError,
      setFieldTouched,
      validateForm,
      validateField,
      resetForm,
      submitForm,
      setFormikState,
    }),
    [
      setStatus,
      setErrors,
      setSubmitting,
      setTouched,
      setValues,
      setFieldValue,
      setFieldError,
      setFieldTouched,
      validateForm,
      validateField,
      resetForm,
      submitForm,
      setFormikState,
    ]
  );
}
