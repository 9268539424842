import { flatMap, isNil } from "lodash";
import * as React from "react";
import { Action } from "../../../../Section/types";
import { InformeT } from "../../../types";

type Config = {
  informe?: InformeT;
};

const useInformeActions = ({ informe, ...props }: Config) => {
  return React.useMemo(() => {
    if (!informe) {
      return [];
    }

    const actions = (informe.sections ?? []).reduce((actionsAcc, section) => {
      const flattenActions = flatMap(section.subsections, "actions");
      return [...actionsAcc, ...flattenActions];
    }, [] as Action[]);
    return actions.map((action) => ({
      ...action,
      attachments: isNil(action.attachments) ? -1 : action.attachments,
    }));
  }, [informe]);
};

export default useInformeActions;
