import ReactPDF from '@react-pdf/renderer';
import { FC, PropsWithChildren } from 'react';

// Custom variants of `@react-pdf/renderer` components, which accept a `children` prop.
//
// Credits: Special thanks to GitHub user @antoineharel for sharing this solution at:
//          https://github.com/diegomura/react-pdf/pull/1798#issuecomment-1259552615

export const Document: FC<PropsWithChildren<any>> = ({ ...props }) => (
  <ReactPDF.Document {...props} />
);

export const Page: FC<PropsWithChildren<any>> = ({ ...props }) => (
  <ReactPDF.Page {...props} />
);