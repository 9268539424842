import * as React from "react";
// import { AuditData } from "../../../ApiClients/DummyData";
import Layout from "../../../Components/Layout";
import { IMe } from "../../../types";
import useHasPermissions from "../hooks/useHasPermissions";
import useInformesRouteParams, {
  InformeRouteType,
} from "../hooks/useInformesRouteParams";
import useLoader from "../hooks/useLoader";
import { InformeT } from "../types";
import { Action } from "../../Section/types";
import InformeOverview, {
  Props as InformeOverviewProps,
} from "./InformeOverview";
import OrderService from '../../../ApiClients/OrderService';

export interface Props {
  history: any;
  match: any;
  me?: IMe;
  version: string;
}

const InformeOverviewRoute: React.FC<Props> = ({
  history,
  match,
  version,
  me,
  ...props
}) => {
  const { centrotrabajoId, informeRouteType, informeId: informeId$strparam } =
    // This hook does not check the extra params, so it just spreads them as string
    useInformesRouteParams<{
      informeId: string;
    }>({});
  const informeId = Number(informeId$strparam);
  const [loading, setLoading] = React.useState(true);

  // This avaluates the access to the page
  const { pageAccessDenied } = useHasPermissions({
    me,
    centrotrabajoId,
    permissions: [5, 20, 50, 100],
  });

  const { hasPermissions: hasEditPermissions } = useHasPermissions({
    me,
    centrotrabajoId,
    // admin & superadmin
    permissions: [50, 100],
  });

  const handleNavigateToMeasure = React.useCallback(
    (action: Action) => {
      if(action.tipo == 2) // Send to correctivo
        history.push(
          `/${centrotrabajoId}/listado/${action.tipo}/${action.id}`
        );
      else if(action.tipo == 1) // 
        history.push(
          `/${centrotrabajoId}/panelcontrol/${action.tipo}`
        );
    },
    [centrotrabajoId, history]
  );

  
  const handleNavigateToReports = React.useCallback(
    (informeType:InformeRouteType) => {
      history.push(
        `/${centrotrabajoId}/reports/${informeType}`
      );
    },
    [centrotrabajoId, history]
  );
  return (
    <div className="App-basic">
      <Layout
        history={history}
        currentPage={1}
        allowDrawer={true}
        match={match}
        version={version}
        me={me}
        showEmpresa
        showLateralMenu
        loading={!me || loading?true:false}
      />

      <div className="App-body-webBar">
        {me
          ? pageAccessDenied || (
              <InformeOverviewQuery
                handleNavigateToReports={handleNavigateToReports}
                informeId={informeId}
                informeRouteType={informeRouteType}
                centroTrabajoId={centrotrabajoId}
                onNavigateToMeasure={handleNavigateToMeasure}
                readOnly={!hasEditPermissions}
                setLayoutLoading={setLoading}
                me={me}
                {...props}
              />
            )
          : /*`Fallo en la carga del perfil, volver a intentar`*/null}
      </div>
    </div>
  );
};

export default InformeOverviewRoute;

type InformeOverviewQueryProps = {
  informeId: number;
  informeRouteType: InformeRouteType;
  readOnly?: boolean;
  handleNavigateToReports:Function;
  onNavigateToMeasure?: InformeOverviewProps["onNavigateToMeasure"];
  centroTrabajoId: number;
  setLayoutLoading: Function;
  me?: IMe;
};
const InformeOverviewQuery = ({
  informeId,
  informeRouteType,
  centroTrabajoId,
  handleNavigateToReports,
  onNavigateToMeasure,
  setLayoutLoading,
  me,
  ...props
}: InformeOverviewQueryProps) => {
  const [loading, setLoading] = React.useState(true);
  const [changeForm, setChangeForm] = React.useState(false);
  const loader = useLoader({ loading });
  const [informe, setInforme] = React.useState<InformeT | undefined>(undefined);

  const loadData = React.useCallback(async () => {
    setLayoutLoading(true);
    // setLoading(true);
    // TODO:OS simulates async fetch
    // try {
    //   // Query from 'informeId' + 'informeType'
    //   const response: InformeT | undefined = await new Promise((resolve) => {
    //     const res: InformeT | undefined =
    //       informeId === AuditData.id &&
    //       informeRouteType === InformeRouteType.AUDITORIAS
    //         ? AuditData
    //         : undefined;
    //     setTimeout(() => {
    //       resolve(res);
    //     }, 1000);
    //   });
    //   setInforme(response);
    // } catch (err) {
    //   console.error(err);
    // } finally {
    //   setLoading(false);
    // }
    let response: InformeT = await OrderService.LoadReport(centroTrabajoId, (informeRouteType == InformeRouteType.VISITAS?1:2), informeId);
    setInforme(response);
    setLoading(false);
    setLayoutLoading(false);

  }, [informeId, informeRouteType, centroTrabajoId]);

  // const onFormChange = (state: boolean) => {
  //   console.log('handle Form Change:' + state);   
  //   setChangeForm(state);
  // }
  React.useEffect(() => {
    void loadData();
  }, [loadData]);

  return (
    loader || (
      <div>
        {informe ? (
          <InformeOverview
            {...props}
            key={informe.id}
            handleNavigateToReports={handleNavigateToReports}
            informeRouteType={informeRouteType}
            informe={informe}
            centroTrabajoId={centroTrabajoId}
            onNavigateToMeasure={onNavigateToMeasure}
            // onFormChange={onFormChange}
            me={me}
            loadData={loadData}
          />
        ) : (
          <div className="App-header">{"No se ha encontrado el informe"}</div>
        )}
      </div>
    )
  );
};
