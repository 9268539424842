import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe } from '../types';
import logoVC from '../Assets/nealia_prevent_icon.png';
import { authContext } from '../adalConfig';

const styles = (theme: Theme) => createStyles({
    containerStyle: {
        textAlign: 'center',
        verticalAlign: 'middle',
        color: '#888',
        lineHeight: '1.2',
        marginTop: '40vh',
    },
    titleStyle: {
        color: '#555',
        fontSize: '2em',
        fontWeight: 'bold',
    },
    bodyStyle: {
        color: '#555',
        marginTop:20,
        margin: '0 auto',
        width: '280px',
        fontWeight: 'bold',
    },
    imageStyle: {
        width: 200,
    }
});

interface IOutOfServiceProps {
    history: any;
    classes: any;
    refreshMyInfo: Function;
    myInfo?: IMe;
}

interface IOutOfServiceState {
}

let timer : any;

class OutOfService extends Component<IOutOfServiceProps, IOutOfServiceState> {
    
    componentDidMount() {
        //timer = setInterval(this.checkDisabled, 10000);
    }
    goBack = () => {
        this.props.refreshMyInfo();
        this.props.history.push('/');
    }

    render() {
        const { classes, myInfo } = this.props;
        return (
            <div className={classes.containerStyle}>
                <img className={classes.imageStyle} src={logoVC} alt='logo' />
                <div className={classes.bodyStyle}>{'Web en mantenimiento, disculpen las molestias.'}</div>
                <br />
                <div style={{ margin:'auto', textAlign:'left' }}>
                    <p className={classes.bodyStyle}>{`Nombre: ${myInfo?.userIdName}`}</p>
                    <p className={classes.bodyStyle}>{`Cuenta: ${myInfo?.userId}`}</p>
                </div>
                <br />
                <button className='App-header-options-item App-header-options-item-create' style={{ margin:'auto' }} onClick={()=>window.location.replace(`${window.location.origin}${window.location.pathname}`)}>{'Volver a intentar'}</button>
                <br />
                <button className='App-header-options-item App-header-options-item-create' style={{ margin:'auto' }} onClick={()=>authContext.logOut()}>{'Cerrar sesión'}</button>
            </div>
        );
    }
}

export default withStyles(styles)(OutOfService);