import * as React from "react";
import OrderService from "../../../../../ApiClients/OrderService";

type Config = {
  centroTrabajoId: number;
};

const useOptions = ({ centroTrabajoId }: Config) => {
  const [options, setOptions] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const loadOptions = async () => {
      await OrderService.LoadOptions(centroTrabajoId).then((res) => {
        setOptions(res);
      });
    };
    try {
      void loadOptions();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [centroTrabajoId]);

  // Use for filter options (eg. only visit)
  // const memoOptions = React.useMemo(() => options, [options]);
  return { options, loading };
};

export default useOptions;
