import React from "react";
import { InformeT } from "../../../types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/EditOutlined";
import DownloadIcon from "@mui/icons-material/SimCardDownload";
import IconButton from "@material-ui/core/IconButton";
import InformesTableStatusRowCell from "./InformesTableStatusRowCell";
import InformesTableMarkRowCell from "./InformesTableMarkRowCell";
import { InformeRouteType } from "../../../hooks/useInformesRouteParams";
import OverflowCell from "../../../../../Components/Material/OverflowCell";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      // marginTop: 20,
      "& thead.MuiTableHead-root": {
        borderBottom: "2px solid #00D3FF",
      },
      "& thead .MuiSvgIcon-root": {
        color: "#00D3FF !important",
        fontSize: "30px",
      },
      '& tbody> .MuiTableRow-root': {
        '&:nth-child(odd)': {
          backgroundColor: '#EEEEEE',
        },
        '&:nth-child(even)': {
          backgroundColor: 'white',
        },
        '&:hover': {
          backgroundColor: '#ddd',
        }
      },
      '& tbody .MuiTableCell-body': {
        maxWidth: '190px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'fit-content',
        whiteSpace: 'nowrap',
        // "&:first-child": {
        //   position: 'sticky',
        //   left: 0,
        //   zIndex: 1,
        //   backgroundColor:'inherit'
        // },
        "&:nth-child(2)": {
          position: 'sticky',
          left: 0,
          zIndex: 1,
          backgroundColor:'inherit'
        },
      },
      '& thead .MuiTableCell-head': {
        "&:nth-child(2)": {
          backgroundColor: 'white',
          position: 'sticky',
          left: 0,
          zIndex: 1,
        },
      }
    },
    paper: {
      width: "auto",
      marginBottom: theme.spacing(2),
      border: "3px solid rgb(0, 43, 97, 0.60)",
      borderRadius: "5px",
    },
    table: {
      minWidth: 900,
      '& .MuiTableCell-head':{ lineHeight: 1.25 } 
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export type InformeItem = Omit<InformeT, "sections"> & {
  
};

export type Props = {
  informes: InformeItem[];
  order: Order;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  rowOptionSelectedAnchor: any;
  informeType: InformeRouteType;

  handleClickOptionRow: (informe: InformeItem, option: number) => void;
  handleClickOpenRowOptions: (evt: any, id: number) => void;
  handleClickCloseRowOptions: () => void;
  OptionisOpened: (id: number) => boolean;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: Headers
  ) => void;
};

export type Order = "asc" | "desc";

type OptionRow = {
  disablePadding: boolean;
  id: number;
  label: string;
  numeric: boolean;
};

const ITEM_HEIGHT = 48;

export default function EnhancedTable(props: Props) {
  const {
    informes,
    informeType,
    handleClickOptionRow,
    handleClickOpenRowOptions,
    handleClickCloseRowOptions,
    OptionisOpened,
    rowOptionSelectedAnchor,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleRequestSort,
  } = props;

  const classes = useStyles();

  const renderOptionsRow = (row: InformeItem) => {
    const clicked = (action: number) => {
      handleClickOptionRow(row, action);
      handleClickCloseRowOptions();
    };
    return (
      <div>
        <IconButton
          size="small"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(evt: any) => handleClickOpenRowOptions(evt, row.id)}
        >
          <AddIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={rowOptionSelectedAnchor}
          keepMounted
          open={OptionisOpened(row.id)}
          onClose={handleClickCloseRowOptions}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 150,
            },
          }}
        >
          {optionsRow.map((option: OptionRow) => (
            <MenuItem key={option.id} onClick={() => clicked(option.id)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Paper square elevation={0}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={() => {}}
              onRequestSort={handleRequestSort}
              rowCount={informes.length}
              informeType={informeType}
            />
            <TableBody>
              {
                stableSort(informes, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: InformeItem, index: number) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => {}}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {/* <TableCell>{renderOptionsRow(row)}</TableCell> */}
                        <TableCell>
                          {row.storage && row.storage !== null?
                            <IconButton
                              size="small"
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(evt: any) => handleClickOptionRow(row, 2)}
                            > 
                              <DownloadIcon />
                            </IconButton>
                          :
                            null
                          }
                        </TableCell>
                        <TableCell onClick={()=>handleClickOptionRow(row, 1)} style={{ cursor:'pointer' }} align="center" component="th" scope="row" padding="none">
                          <a style={{ fontWeight:'bold', color:'#000'}}>{row.code}</a>
                        </TableCell>
                        {/* <TableCell align="center">{row.code}</TableCell> */}
                        <TableCell align="center">
                          <OverflowCell text={row.name.toUpperCase()}/>                          
                        </TableCell>
                        <TableCell align="center">                          
                          {new Date(row.dtStartVisit).toLocaleDateString()}
                          {row.status == 50?
                            ` - ${new Date(row.dtEndVisit).toLocaleDateString()}`
                          :
                            null
                          }
                        </TableCell>
                        <InformesTableStatusRowCell
                          statusDsc={row.statusDsc}
                          status={row.status}
                          statusColor={row.statusColor}
                        />
                        <TableCell align="center">
                          {!row.dtReport || row.dtReport == null?'':new Date(row.dtReport).toLocaleDateString()}
                        </TableCell>
                        {informeType == InformeRouteType.AUDITORIAS?
                          <InformesTableMarkRowCell mark={row.mark} />
                        :
                          null
                        }
                      </TableRow>
                    );
                  })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200, 300, 400, 500, 1000]}
          component="div"
          count={informes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página:"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from} - ${to} de ${count}`;
          }}
        />
      </Paper>
    </div>
  );
}

const optionsRow: OptionRow[] = [
  { id: 1, numeric: true, disablePadding: false, label: "Editar" },
];

export type Headers = keyof InformeT | "mark" | "type";
type HeadCell = {
  disablePadding: boolean;
  id: Headers;
  label: string;
  numeric: boolean;
};

const headCells_audits: HeadCell[] = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  { id: "name", numeric: false, disablePadding: false, label: "Nombre" },
  {
    id: "dtStartVisit",
    numeric: false,
    disablePadding: false,
    label: "Fecha visita",
  },
  { id: "statusDsc", numeric: false, disablePadding: false, label: "Estado" },
  {
    id: "dtReport",
    numeric: false,
    disablePadding: false,
    label: "Fecha informe",
  },
  { id: "mark", numeric: false, disablePadding: false, label: "Puntuación" },
];

const headCells_visits: HeadCell[] = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  { id: "name", numeric: false, disablePadding: false, label: "Nombre" },
  {
    id: "dtStartVisit",
    numeric: false,
    disablePadding: false,
    label: "Fecha visita",
  },
  { id: "statusDsc", numeric: false, disablePadding: false, label: "Estado" },
  {
    id: "dtReport",
    numeric: false,
    disablePadding: false,
    label: "Fecha informe",
  }
];


type EnhancedTableProps = {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: Headers) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  informeType: InformeRouteType;
};

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort, informeType } = props;

  const createSortHandler =
    (property: Headers) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {(informeType == InformeRouteType.AUDITORIAS? headCells_audits : headCells_visits).map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={/*orderBy === headCell.id*/ true}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={ArrowDropDownIcon}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
