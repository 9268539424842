import * as React from "react";
import { IMe } from "../../../types";
import { GetRole } from "../../../helpers";

type Config = {
  me: IMe | void;
  centrotrabajoId: number | void;
  permissions: number[];
};

type Return = {
  hasPermissions: boolean;
  pageAccessDenied: React.ReactNode;
};

const useHasPermissions = ({
  me,
  centrotrabajoId,
  permissions,
}: Config): Return => {
  const hasPermissions =
    me && centrotrabajoId && permissions.includes(GetRole(me, centrotrabajoId));

  return {
    hasPermissions: Boolean(hasPermissions),
    pageAccessDenied: !hasPermissions ? (
      <div>
        <label style={{ marginLeft: 10 }}>
          {`No tiene permisos para visualizar este espacio.`}
        </label>
      </div>
    ) : null,
  };
};

export default useHasPermissions;
