import { Formik } from "formik";
import { Props } from "./types";
import InformeUpdateFormInner from "./InformeUpdateFormInner";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const InformeUpdateForm = ({
  title,
  Header,
  Content,
  Actions,
  SubmitProps,
  disabled,
  disabledFields,
  onSubmit,
  onClickSubmit,
  onFormChange,
  onOpenMeasureDialog,
  ...props
}: Props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <Formik {...props} onSubmit={onSubmit}>
        {(formikProps) => (
          <InformeUpdateFormInner
            title={title}
            Header={Header}
            Content={Content}
            Actions={Actions}
            SubmitProps={SubmitProps}
            disabled={disabled}
            disabledFields={disabledFields}
            onClickSubmit={onClickSubmit}
            onFormChange={onFormChange}
            onOpenMeasureDialog={onOpenMeasureDialog}
            {...formikProps}
          />
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

export default InformeUpdateForm;
