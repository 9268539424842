import { StyleSheet } from '@react-pdf/renderer';

export const PdfStyles = StyleSheet.create({
  sections: {
    padding: 0,
  },
  listItemNumbered: {

  },
  logo1: {
    width: 45,
    height: 45,
  },
  scdlogo: {
    justifyContent:'center',
    alignItems: 'center',
  },
  logo2: {
    width: 150,
    height: 'auto',
    justifyContent:'center',
    alignItems:'center'
  },
  apartado: {

  },
  info: {

  },
  square: {
    width: 5,
    height: 5,
    backgroundColor: '#000000',
    marginRight: 5
    // marginBottom: 5
  },
  circle: {
    width: 5,
    height: 5,
    marginRight: 5,
    borderRadius: 50,  // Hace que el borde sea circular
    border: '1 solid black',  // Borde negro de 1px
    backgroundColor: 'white',  // Fondo blanco
    // marginBottom: 10,
  },
  bullet: {
    position:'absolute',
    left:-10,
    top:4,
    width: 3,
    height: 3,
    backgroundColor: '#000000',
    borderRadius: 50,
    marginRight: 5
    // marginBottom: 10,
  },
  number: {
    width: 20,  // Ancho fijo para los números
    textAlign: 'right',  // Alinea los números a la derecha
    marginRight: 5,
    fontWeight: 'bold',
  },
  apartadotitle: {
    
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 30,
    
    
  },
  paragraph: {
    // marginBottom: 10,
    // fontSize: 12,
  },
  bulletItem: {
    flexDirection: 'row', // Alinear elementos de lista horizontalmente
    alignItems: 'flex-start',
    marginBottom: 4,
    // flexWrap: 'wrap', // Permitir que los elementos de lista anidados se envuelvan
  },
  underline:{
    textDecoration:'underline'
  },
  list: {
    paddingLeft: 25,
  },
  strong: {
      fontWeight: 'bold',
  },
  em:{
    fontFamily: 'Open Sans',
    //fontFamily: 'Times-Italic'
    fontStyle: 'italic'
  },
  page: {
    padding: 40,
    fontFamily: 'Open Sans',
  },

  footer: {
    position: "absolute",
    fontSize: 8,
    bottom: 10,
    textAlign: "left",
    fontWeight: 'bold',
    color: '#999797',
    marginLeft: 40,
  },

  footerImage: {
    width: 100, 
    height: 'auto', 
    position: 'absolute',
    right: 50, 
    bottom: 6, 
  },

  footerImage2: {
    width: 20, 
    height: 20, 
    position: 'absolute',
    right: 155, 
    bottom: 4, 
  },

  logo3: {
    width: 50,
    height: 50,
  },
  markText: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  markText2: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#00D3FF',
  },
  desc: {
    backgroundColor: '#D1F8FF',
    padding: 8,
    fontSize: 8,
    marginBottom: 20,
    marginTop: 20,
  },
  
  comentarios: {
    
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    // height: 'auto', 

  },
  
  cspacer: {
    width: '10%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    padding: 4,
    paddingTop: 6,
    
  },
  sublist: {
    display: 'flex',
    flexDirection: 'row', // Alinear elementos de sublista en una fila
    flexWrap: 'wrap',     // Permitir que las sublistas se envuelvan a la siguiente línea
    // marginLeft: 10,       // Margen opcional para sublistas anidadas
    paddingRight: 15
  },
  
  ctext: {
    fontSize: 8,
    width: '75%',
    padding: 4,
    // borderBottom: 1,
    // borderBottomColor:'#00D3FF',
  },

  cseparator: {
    textAlign:'center',
    width: '95%',
    padding: 4,
    borderBottom: 0.5,
    borderBottomColor:'#00D3FF',
  },
  
  markempty: {
    width: '15%',
    display: 'flex',
    backgroundColor: '#D1F8FF',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomColor: '#00D3FF',
  },
  
  aspacer: {
    width: '10%',
  },

  numeration: {
    color: '#00D3FF',
  },
  
  atext: {
    fontSize: 10,
    width: '75%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderBottom: 2,
    borderBottomColor:'#00D3FF',
    fontWeight: 'bold',
    paddingTop: 8,
  },
  
  titlesec: {
    
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 40,
    
  },
  titletext: {
    fontSize: 12,
    width: '85%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    fontWeight: 'bold',
  },

  group: {
    fontSize: 14,
    width: '85%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    fontWeight: 'bold',
    
  },
  
  mark1: {
    width: '15%',
    display: 'flex',
    backgroundColor: '#D1F8FF',
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  mark: {
    width: '15%',
    display: 'flex',
    backgroundColor: '#D1F8FF',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: 2,
    borderBottomColor: '#00D3FF',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  rowlogo:{
    display: 'flex',
    flexDirection: 'row',
  },
  mainlogo:{
    flex:1
  },
  rheader:{
    width: '100%',
  },

  data: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: 8,
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  campo: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '15%',
    fontWeight: 'bold',
  },
  dato: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '85%',
  },

  global: {
    backgroundColor: '#D1F8FF',
  },
  
  title: {
    fontSize: 15,
    paddingTop: 10,
    marginTop: 10,
    marginBottom: 20,
    borderTop: 1,
    borderTopColor: '#00D3FF',
    fontWeight: 'bold', 
  },

  tableStyle :{
    width: '100%'
  },
  tableRowStyle : {
    flexDirection : 'row'
  },  
  tableColHeaderStyle : {
    width: "20%",
    borderStyle: "solid",
    border: 0,
    borderBottomColor: "#00d3ff",
    borderBottomWidth: 1,
    alignSelf:'baseline'
  },
  tableColStyle : {
    width: "20%",
    borderStyle: "solid",
    border: 0,
    borderBottomColor: "#e0e0e0",
    borderBottomWidth: 1,    
  },  
  tableCellHeaderStyle : {
    textAlign: "center",
    margin: 5,
    fontSize: 9,
    fontWeight: "bold"
  },
  tableCellStyle : {
    textAlign: "center",
    margin: 5,
    fontSize: 8
  },
  boldTableCellStyle : {
    textAlign: "center",
    margin: 5,
    fontSize: 8,
    fontWeight: 'bold'
  },
  waterMark : {
    position: "absolute",
    fontSize: 70,
    bottom: "50%",
    textAlign: "left",
    fontWeight: 'bold',
    color: '#red',
    left:"25%",
    textTransform: 'uppercase',
    transform: 'rotate(-45deg) translate(-50%,-50%)',
    opacity:0.12
  },

  listItem: {
    display: 'flex',
    flexDirection: 'row', // Asegura que los elementos de la lista se dispongan horizontalmente
    // alignItems: 'center', // Alinea verticalmente los elementos de la lista
    flexWrap: 'wrap', // Permite que el contenido se envuelva si es necesario
    width: '100%', // Asegura que ocupe el ancho total
  },
  listItemText: {
    flexShrink: 1, // Permite que el texto se reduzca si es necesario
    flexGrow: 1, // Permite que el texto crezca si hay espacio
    // fontSize: 10, // Ajusta el tamaño de fuente según tus necesidades
  },
});