import {
  Button,
  Menu,
  MenuItem,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { ClassNameMap } from "@material-ui/styles";
import classnames from "classnames";
import { compact, get } from "lodash";
import * as React from "react";
import { Subsection } from "../../../../types";
import type { ClassKey, FieldInnerProps } from "./types";
import SectionMark from "../../../../components/SectionMark";
import SubsectionAccordion from "../SubsectionAccordion";
import { InformeRouteType } from "../../../../../Informes/hooks/useInformesRouteParams";
import CreateModal from '../../../../../../Components/CreateDialog';
import { act } from "react-dom/test-utils";

type Props = FieldInnerProps;

const useStyles = makeStyles<Theme, {}, ClassKey>(() => ({
  root: {},
  footer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  addButton: {},
  subsectionMenu: {},
  subsectionMenuItem: {},
  subsectionAccordion: {
    marginBottom: 16,
  },
  markBox: {
    marginRight: 33,
  },
}));

const SubsectionsFieldInner = ({
  sectionId,
  field,
  reportFields,
  form,
  arrayHelpers,
  __component,
  classes: classes$props,
  sectionMark,
  disabled,
  onFormChange,
  onOpenMeasureDialog,
  ...props
}: Props) => {
  const Component = __component || React.Fragment;
  const classesByKey = mergeClasses(useStyles(), classes$props);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenList = React.useCallback(
    (ev: any) => setAnchorEl(ev?.currentTarget),
    []
  );
  const handleCloseList = React.useCallback(() => setAnchorEl(null), []);

  const [opennedModal, setOpennedModal] = React.useState<boolean>(false);
  const [textModal, setTextdModal] = React.useState<string>("");
  const [idx2Delete, setIdx2Delete] = React.useState<number>(-1);
  const [idx2Avg, setIdx2Avg] = React.useState<number>(-1);
  const [action, setAction] = React.useState<number>(0);

  const handleSelectSubection = (subsection: Omit<Subsection, "actions">) => {
    if(onFormChange)
      onFormChange(true);
    arrayHelpers.insert(subsection.order, { ...subsection, includeAvg:true, actions: [], comments: [] });
    handleCloseList();
  };

  const handleDeleteSubsection = React.useCallback(
    (index: number) => {
      const comments = subsections[index].comments?.length;

      if(!comments || comments == 0) {
        if(onFormChange)
          onFormChange(true);
        arrayHelpers.remove(index);
      } else {
        setAction(2)
        setIdx2Delete(index);
        setTextdModal(`¡Atención! ¿Quieres eliminar el apartado "${subsections[index].numeration}. ${subsections[index].name}" que contiene ${comments} comentario${comments == 1?"":"s"}?`)
        setOpennedModal(true);
      }      
    },
    [arrayHelpers, onFormChange]
  );    

  const handleSwitchSubsection = React.useCallback(
    (index: number, e:any) => {  
        const check = e.target.checked;
        if(!check){
          setAction(1);
          setTextdModal(`¡Atención! ¿Quieres excluir el apartado "${subsections[index].numeration}. ${subsections[index].name}" del cálculo del promedio?`)
          setOpennedModal(true);
          setIdx2Avg(index);
        }else{
          field.value[index].includeAvg = e.target.checked;
          arrayHelpers.replace(index, field.value[index]);
          // field.onChange(e)
          // arrayHelpers.pop()
          // subsections[index].includeAvg = action;
          // arrayHelpers.push(subsections[index]);
        }
    },
    [arrayHelpers]
  );  


  const handleCreateModal = (action:number) => {
    switch(action) {
      case 1: // Disable mark
        field.value[idx2Avg].includeAvg = false;
        field.value[idx2Avg].mark = (field.value[idx2Avg].mark?field.value[idx2Avg].mark:0);
        arrayHelpers.replace(idx2Avg, field.value[idx2Avg]);
        // if(idx2Delete > -1)
        //   arrayHelpers.remove(idx2Delete);
        // if(idx2Avg > -1){
        //   arrayHelpers.pop()
        //   subsections[idx2Avg].includeAvg = false;
        //   arrayHelpers.push(subsections[idx2Avg]);          
        // }          
        // setIdx2Avg(-1);
        setOpennedModal(false);
        break;  
      case 2: // Delete subsection
        arrayHelpers.remove(idx2Delete);   
        setIdx2Delete(-1);
        setOpennedModal(false); 
        break;
    }
  }

  const subsections = field.value.sort((a, b) => a.order - b.order);
  const hasSubsections = Array.isArray(subsections) && subsections.length > 0;
  const subsectionsError = get(form.errors, field.name);
  const hasError = typeof subsectionsError === "string";

  //mostrar el total del grupo
  const showMark:boolean = form.initialValues["type"] == InformeRouteType.AUDITORIAS? true : false;

  const subsectionIds = subsections.map((s) => s.id);
  // TODO:OS get list of missing sections to add
  // On Open "Añadir", the listed sections should be difference(All, field.value). If empty array, disable button
  // Also, decide how to obtain the "All" sections (query and prop drilling...?)
    
  const _allSubsections = reportFields.length > 0 ? reportFields.find(
    (s) => s.id === sectionId
  )!.subsections.filter((i) => !subsectionIds.includes(i.id)): [];
  // console.log(subsections)
  return (
    <Component>
      {hasError && (
        <Typography variant="caption" color="error">
          {subsectionsError}
        </Typography>
      )}
      {hasSubsections ? (
        subsections.map((subsection, index) => {
          const fieldName = `${field.name}.${index}`;
          // either a single string, or array of strings (multiple errors)
          // cannot be an object since it is primitive type
          const error = get(form.errors, `${fieldName}.mark`);
          const errorComment = get(form.errors, `${fieldName}.comments`);

          return (
            <React.Fragment key={index}>
              <SubsectionAccordion
                key={index}
                showMark={showMark}
                fieldName={fieldName}
                subsection={subsection}
                className={classnames(classesByKey("subsectionAccordion"))}
                onDeleteClick={() => handleDeleteSubsection(index)}      
                onSwitchClick={(e:any) => handleSwitchSubsection(index, e)}          
                onChange={field.onChange}
                disabled={disabled}
                onOpenMeasureDialog={onOpenMeasureDialog}
                onFormChange={onFormChange}
              />
              {error && (
                <Typography key={index} variant="caption" color="error">
                  {Array.isArray(error) ? error[0].toString() : error.toString()}
                </Typography>
              )}
              <br/>
              {errorComment && (
                <Typography key={index} variant="caption" color="error">
                  {Array.isArray(errorComment) ? errorComment[0].toString() : errorComment.toString()}
                </Typography>
              )}
            </React.Fragment>
          );
        })
      ) : (
        <Typography>No hay apartados</Typography>
      )}

      <div className={classnames(classesByKey("footer"))}>
        {showMark?
          hasSubsections && (
            <SectionMark className={classnames(classesByKey("markBox"))}>
              {sectionMark}
            </SectionMark>
            )
        :
            null
        }

        {opennedModal?
            <CreateModal
                onCloseCreationModal={()=>setOpennedModal(false)}
                isOpen={opennedModal}
                inputText={textModal}
                typeModal={2}
                action={handleCreateModal}
                typeAction={action}
                loadingModal={false}
                titleModal={`MENSAJE`}
            />
        :
            null
        }

        {!disabled && (
          <React.Fragment>
            <Button
              variant="contained"
              className={classnames(classesByKey("addButton"))}
              onClick={(e) => handleOpenList(e)}
              disabled={
                !Array.isArray(_allSubsections) || !_allSubsections.length
              }
            >
              <Add />
              <Typography variant="subtitle2" color="inherit">
                Nuevo apartado
              </Typography>
            </Button>
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleCloseList}
              keepMounted
              className={classnames(classesByKey("subsectionMenu"))}
            >
              {_allSubsections.map((s, idx:number) => (
                <MenuItem
                  key={idx}
                  className={classnames(classesByKey("subsectionMenuItem"))}
                  onClick={() => handleSelectSubection(s)}
                >
                  {s.numeration}. {s.name}
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        )}
      </div>
    </Component>
  );
};

export default SubsectionsFieldInner;

const mergeClasses = (
  cl1: ClassNameMap<ClassKey>,
  cl2?: Partial<ClassNameMap<ClassKey>>
) => {
  return (field: ClassKey) => compact([cl2?.[field], cl1[field]]);
};
