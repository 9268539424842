import React from 'react';
import ReactDOM from 'react-dom';
import './indexApp.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, HashRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { createRoot } from 'react-dom/client';

const theme = createTheme ({
  typography: {
    allVariants: {
      //fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      fontFamily: 'Montserrat, sans-serif'
    },
  },
});

const Container = () => {
    return (
      <ThemeProvider theme={theme}>
        <HashRouter>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </HashRouter>
      </ThemeProvider>
    );
}

const container:any = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Container />);
// ReactDOM.render(<Container />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();