import { Button, CircularProgress } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

function LoadingButton(props: {
  button?: React.ElementType;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  classes?: ClassNameMap<"progress">;
}) {
  const { button, disabled, isLoading, onClick, children, className, classes } =
    props;
  const ButtonComponent = button || Button;

  return (
    <ButtonComponent
      type="submit"
      disabled={disabled || isLoading}
      onClick={onClick}
      className={className}
    >
      {isLoading && (
        <CircularProgress
          size={24}
          className={classes?.progress ?? undefined}
          color="inherit"
        />
      )}
      {!isLoading && children}
    </ButtonComponent>
  );
}

export default LoadingButton;
