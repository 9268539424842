import * as React from "react";
import { Column, SortDirection } from "../../components/EnhancedTableHead";
import InformeActionsTable from "../../components/InformeActionsTable";
import { Props } from "./types";
import { Action } from "../../../../../Section/types";

const InformeActionsTableContainer = ({ actions, onClickAction, ...props }: Props) => {
  const [order, setOrder] = React.useState(SortDirection.ASC);
  const [orderBy, setOrderBy] = React.useState<keyof Props["actions"][0]>("typeDsc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const handleClickColumn = React.useCallback(
    (columnKey: keyof Props["actions"][0]) => () => {
      setOrderBy(columnKey);
      setOrder((prev) =>
        prev === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
      );
    },
    []
  );

  const columns = useColumns({ onClick: handleClickColumn });
  const rowsPerPageOptions = [10, 25, 50, 100];
  const handleChangePage = React.useCallback((event: unknown, p: number) => {
    setPage(p);
  }, []);

  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  // const handleClickAction = React.useCallback((action:Action) => {
  //   console.log(action)
  //   // if(action.tipo == 2) // Send to correctivo page

  //   // <FormListado
  //   //   options={options}
  //   //   handleCloseItemForm={handleCloseDialog}
  //   //   handleCreateItem={(item: any) => onSubmitFormPreventivo(item)}
  //   //   loading={optionsLoading || saveItem}
  //   //   register={bRegister}
  //   //   readonly={false}
  //   // />
  // }, []);

  return (
    <InformeActionsTable
      {...props}
      actions={useSortedActions({ actions, order, orderBy })}
      order={order}
      orderBy={orderBy}
      columns={columns}
      rowsPerPageOptions={rowsPerPageOptions}
      page={page}
      onClickAction={onClickAction}
      rowsPerPage={rowsPerPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default InformeActionsTableContainer;

function useColumns({
  onClick,
}: {
  onClick: (columnName: keyof Props["actions"][0]) => Column["onClick"];
}) {
  return React.useMemo(
    () => [
      {
        key: "typeDsc",
        label: "Tipo",
        onClick: onClick("typeDsc"),
      },
      {
        key: "id",
        label: "ID",
        onClick: onClick("id"),
      },
      {
        key: "sectionDsc",
        label: "Bloque",
        onClick: onClick("attachments"),
      },
      {
        key: "subsectionDsc",
        label: "Apartado",
        onClick: onClick("attachments"),
      },
      {
        key: "dtCreated",
        label: "Fecha creación",
        onClick: onClick("dtCreated"),
      },
      // {
      //   key: "attachments",
      //   label: "Adjuntos",
      //   onClick: onClick("attachments"),
      // },
      {
        key: "statusDsc",
        label: "Estado",
        onClick: onClick("statusDsc"),
      },
    ],
    [onClick]
  );
}

type SortConfig<T extends Props["actions"][0]> = {
  actions: T[];
  order: SortDirection;
  orderBy: keyof T;
};
function useSortedActions<T extends Props["actions"][0]>({
  actions,
  order,
  orderBy,
}: SortConfig<T>) {
  const descendingComparator = React.useCallback(
    (a: T, b: T, orderBy: SortConfig<T>["orderBy"]) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    },
    []
  );

  const comparator = React.useMemo(
    () => (a: T, b: T) =>
      order === SortDirection.DESC
        ? descendingComparator(a, b, orderBy)
        : -descendingComparator(a, b, orderBy),
    [descendingComparator, order, orderBy]
  );

  const stabilizedThis = actions.map(
    (action, index) => [action, index] as [T, number]
  );

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}
