import * as React from "react";
import { useParams, useHistory } from "react-router-dom";

export type Config = {
  redirectTo?: string | null;
};

export const DEFAULT_REDIRECT_TO = "/";

export enum InformeRouteType {
  VISITAS = "visits",
  AUDITORIAS = "audits",
}

export type MatchParams<T extends { [key: string]: string }> = T & {
  informeRouteType: InformeRouteType;
  centrotrabajoId: string;
};

export type Return<T extends { [key: string]: string }> = T & {
  informeRouteType: InformeRouteType;
  centrotrabajoId: number;
};

const useInformesRouteParams = <T extends { [key: string]: string }>({
  redirectTo = DEFAULT_REDIRECT_TO,
}): Return<T> => {
  const { informeRouteType, ...rest } = useParams<MatchParams<T>>();
  const history = useHistory();

  React.useEffect(() => {
    if (!Object.values(InformeRouteType).includes(informeRouteType)) {
      history.push(redirectTo);
    }
  }, [informeRouteType, history, redirectTo]);

  // @ts-expect-error T could be initialized as different constraint than { [key: string]: any }
  return React.useMemo(
    () => ({
      ...rest,
      centrotrabajoId: Number(rest.centrotrabajoId),
      informeRouteType,
    }),
    [informeRouteType, rest]
  );
};

export default useInformesRouteParams;
