import classnames from "classnames";
import { Props } from "./types";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {},
  labelText: {
    textTransform: "uppercase",
    textAlign: "center",
  },
  valueWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #707070",
    borderRadius: 10,
    height: 45,
  },
}));

const SectionMarkSummary = ({ group, mark, className }: Props) => {
  const classes = useStyles();
  return (
    <div className={classnames(className, classes.root)}>
      <Typography
        className={classnames(classes.labelText)}
        variant="h6"
        gutterBottom
      >
        {getGroupLabel(group)}
      </Typography>
      <div className={classes.valueWrapper}>
        <Typography variant="h6">{mark?.toFixed(2)}</Typography>
      </div>
    </div>
  );
};

export default SectionMarkSummary;

function getGroupLabel(group: string): string {
  switch (group) {
    case "1":
      return "Control operacional";
    case "2":
      return "Gestión documental";
    case "total":
      return "Total informe";
    default:
      return "";
  }
}
