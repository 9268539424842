import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import "./FormCorrectivo.css";
import Loader from "./Loader";

interface FormObservacionProps {
  handleCloseItemForm: () => void;
  handleCreateItem: (observacion: string) => void;
  loading: boolean;
}

const FormObservacion: React.FC<FormObservacionProps> = ({
  handleCloseItemForm,
  handleCreateItem,
  loading,
}) => {
  const [observacion, setObservacion] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleCreateItem(observacion);
  };

  return (
    <Dialog
      open={true}
      onClose={handleCloseItemForm}
      aria-labelledby="form-dialog-title"
      className="dialog-data"
    >
      <DialogTitle id="form-dialog-title">
        {"FORMULARIO CREACIÓN OBSERVACIÓN"}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="observacion"
            label="Observación"
            type="text"
            fullWidth
            variant="outlined"
            value={observacion}
            onChange={(e) => setObservacion(e.target.value)}
            multiline={true}
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          {!loading ? (
            <>
              <div style={{ display: "flex", gap: 25 }}>
                <Button onClick={handleCloseItemForm} className="cancel-dialog">
                  {"Cancelar"}
                </Button>
                <Button type="submit" className="confirm-dialog">
                  {"Crear"}
                </Button>
              </div>
            </>
          ) : (
            <Loader loading={true} />
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormObservacion;
