import { IRegister } from "../../../../../types";

type Config = {
  onSuccess?: (item:any, action:number) => void;
};

const useOnSubmitFormPreventivo = ({ onSuccess }: Config) => {
  // This is the reused submit
  //   saveData=async(item:any)=>{
  //     let response:any = await OrderService.saveData(item, 1);
  //     if(response == 2){
  //         this.setState({
  //             modalText: '¡ATENCIÓN! Ya existe una medida preventiva con la misma configuración.',
  //             modalType: 1,
  //             creationModalOpen: true,
  //             creatingItem:false,
  //         });
  //     } else {
  //         this.loadControlPanel();
  //         this.setState({creatingItem:false, creationItemOpen:false});
  //     }
  // }

  return (item: IRegister) => {
    console.log("Submitting Form Preventivo 'unknown typed' item");
    // console.log(item);
    if (onSuccess) {
      onSuccess(item, 1);
    }
  };
};

export default useOnSubmitFormPreventivo;
