import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Theme,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import classnames from "classnames";
import { get } from "lodash";
import * as React from "react";
import { Section } from "../../../../types";
import useSectionMarks from "../../hooks/useSectionMarks";
import useSummaryMarks from "../../hooks/useSummaryMarks";
import SectionCard from "../SectionCard/SectionCard";
import SectionMarkSummary from "../SectionMarkSummary/SectionMarkSummary";
import type { ClassKey, FieldInnerProps } from "./types";
import OrderService from '../../../../../../ApiClients/OrderService';
import { InformeRouteType } from "../../../../../Informes/hooks/useInformesRouteParams";
import CreateModal from '../../../../../../Components/CreateDialog';

type Props = FieldInnerProps;

const useStyles = makeStyles<Theme, {}, ClassKey>((theme) => ({
  root: {},
  header: {
    display: "flex",
    gap: theme.spacing(5, 3),
    flexWrap: "wrap",
    alignItems: "flex-end",
  },
  summaryWrapper: {
    // flexDirection: "row",
    flex: "1 1 auto",

    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 40,
  },
  summaryItem: {
    width: 250,
  },
  summaryItemSm: {
    flex: "1 0 auto",
  },
  addSectionWrapper: {
    display:'flex',
    justifyContent: "flex-end",
    width: "100%",
  },
  addSectionButton: {
    backgroundColor: "#00DEFF",
    borderRadius: "10px",
    height: 56,
    color: "white",
    alignItems: "center",
    width: 200,
  },
  addSectionWrapper_bloque: {
    display: "block",
    position: 'sticky',    
    zIndex : 4,    
    left: '100%',
    top: 'calc(100% - 160px)',
    transform: 'translate(-65%, -0%)',
    width:56
  },
  addSectionButton_bloque: {
    backgroundColor: "#00DEFF",
    borderRadius: "50%",
    height: 56,
    color: "white",
    alignItems: "center",
    width: 56,
    minWidth:55,    
  },
  sectionMenu: {},
  sectionMenuItem: {},
  
}));

const SectionsFieldInner = ({
  field,
  form,
  arrayHelpers,
  __component,
  disabled,
  onFormChange,
  onOpenMeasureDialog,
  ...props
}: Props) => {

  const classes = useStyles();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const summaryItemClasses = classnames(classes.summaryItem, {
    [classes.summaryItemSm]: isSmDown,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenList = (ev: any) => setAnchorEl(ev?.currentTarget);
  const handleCloseList = () => {    
    setAnchorEl(null)
  };
  const [opennedModal, setOpennedModal] = React.useState<boolean>(false);
  const [textModal, setTextdModal] = React.useState<string>("");
  const [idx2Delete, setIdx2Delete] = React.useState<number>(-1);  
  const [idxNew, setIdxNew] = React.useState<number>(-1);

  const handleSelectSection = (section: Omit<Section, "subsections">) => {
    if(onFormChange)
      onFormChange(true);

    arrayHelpers.insert(section.order, { ...section, subsections: [] });        
    setIdxNew(section.id);    
    handleCloseList();
  };

  const sections = field.value.sort((a, b) => a.order - b.order);
  
  const sectionsError = get(form.errors, field.name);
  const hasError = typeof sectionsError === "string";

  const sectionIds = sections.map((s) => s.id);

  const handleCreateModal = (action:number) => {
    switch(action) {
      case 1: // Delete section
        if(onFormChange)
          onFormChange(true);
        arrayHelpers.remove(idx2Delete);
        setOpennedModal(false);                
        break;
    }
  }

  const handleOnDelete = (idx:number) => {
    const subsections = sections[idx].subsections.length;
    if(subsections == 0) {
      if(onFormChange)
        onFormChange(true);
      arrayHelpers.remove(idx);
    } else {
      setIdx2Delete(idx);
      setTextdModal(`¡Atención! ¿Quieres eliminar la sección "${sections[idx].numeration}. ${sections[idx].name}" que contiene ${subsections} apartado${subsections == 1?"":"s"}?`)
      setOpennedModal(true);
    }
  }


  // TODO:OS get list of missing sections to add
  // On Open "Añadir", the listed sections should be difference(All, field.value). If empty array, disable button
  // Also, decide how to obtain the "All" sections (query and prop drilling...?)
  const [reportFields, setReportFields] = React.useState<Array<Section>>([]);

  const loadReportFields = React.useCallback(async () => {
    if([50, 99, 98, 97].indexOf(arrayHelpers.form.status) == -1) {
      let response: Array<Section> = await OrderService.LoadReportFields();
      setReportFields(response);            
    }
  }, []);

  React.useEffect(() => {
    void loadReportFields();        
  }, [loadReportFields]);

  const _allSections = reportFields.map(({ subsections, ...i }) => i).filter(
    (i) => !sectionIds.includes(i.id)
  );

  const sectionMarks = useSectionMarks({
    sections,
    path: field.name,
    formErrors: form.errors,
  });

  const { total, ...summaryMarks } = useSummaryMarks({
    sectionMarks,
    sections,
  });

  const showMark:boolean = form.initialValues["type"] == InformeRouteType.AUDITORIAS? true : false;
  // console.log(Object.entries(summaryMarks))
  // console.log(total)
  arrayHelpers.form.values["mark"] = total;
  arrayHelpers.form.values["markOperationalControl"] = summaryMarks["1"] ?? null;
  arrayHelpers.form.values["markDocumentManagement"] = summaryMarks["2"] ?? null;
  //verificamos si el nuevo elemento existe, movemos la vista al elemento y limpiamos el registro de idxNew
  if(document.getElementById(`sec-${idxNew}`)){
    let y =  document.getElementById(`sec-${idxNew}`)!.getBoundingClientRect().top  + window.pageYOffset - 250;
    window.scrollTo({top: y, behavior: 'smooth'});
    setIdxNew(-1);
  }
  // const summaryDataOrder = Object.entries(summaryMarks);
  return (
    <Grid container spacing={4} style={{ display:'block' }}>
      {!disabled && (
          <div className={`${classes.addSectionWrapper} ${classes.addSectionWrapper_bloque}`}>
            <Button
              variant="contained"
              className={`${classes.addSectionButton} ${classes.addSectionButton_bloque}`}
              style={{ display: _allSections.length === 0 ? 'none': 'inline-flex' }}
              onClick={(e) => handleOpenList(e)}
              disabled={_allSections.length === 0}       
              title="Nuevo bloque"       
            >
              <Add/>
              {/* <Typography variant="subtitle1" color="inherit">
                Nuevo bloque
              </Typography> */}
            </Button>
          </div>
        )}
      <Grid item xs={12} className={classes.header}>
        {showMark?
          <div className={classes.summaryWrapper}>
{/* 
            <SectionMarkSummary
              className={summaryItemClasses}
              group={summaryDataOrder[1][0]}
              mark={summaryDataOrder[1][1]}
            />

            <SectionMarkSummary
              className={summaryItemClasses}
              group={summaryDataOrder[0][0]}
              mark={summaryDataOrder[0][1]}
            /> */}
            {Object.entries(summaryMarks).reverse().map(([group, mark], index) => {
              return (
                <SectionMarkSummary
                  key={index}
                  className={summaryItemClasses}
                  group={group}
                  mark={mark}
                />
              );
            })}

            <SectionMarkSummary
              className={summaryItemClasses}
              group="total"
              mark={total}
            />
          </div>
        :
          null
        }

        {opennedModal?
            <CreateModal
                onCloseCreationModal={()=>setOpennedModal(false)}
                isOpen={opennedModal}
                inputText={textModal}
                typeModal={2}
                action={handleCreateModal}
                typeAction={1}
                loadingModal={false}
                titleModal={`MENSAJE`}
            />
        :
            null
        }

        
      </Grid>

      {Array.isArray(sections) && sections.length > 0 ? (                        
        <Grid item xs={12} container>
          {sections.map((section, index) => (
            <SectionCard
              fieldName={`${field.name}.${index}`}
              key={`${section.numeration}::${section.id}`}              
              section={section}
              lastItem={index === sections.length - 1}
              onDeleteClick={() => handleOnDelete(index)}
              disabled={disabled}
              reportFields={reportFields}
              onFormChange={onFormChange}
              SubsectionFieldProps={{
                onOpenMeasureDialog,
                sectionMark: sectionMarks[section.id][index],
                classes: {
                  addButton: classes.addSectionButton,
                  subsectionMenu: classes.sectionMenu,
                  subsectionMenuItem: classes.sectionMenuItem,
                },
              }}
            />  
            
          ))}{" "}          
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography>No hay secciones</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        {hasError && (
          <Typography variant="caption" color="error">
            {sectionsError}
          </Typography>
        )}
      </Grid>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseList}
        keepMounted
        className={classes.sectionMenu}
      >
        {_allSections.map((s, idx:number) => (
          <MenuItem
            key={idx}
            className={classes.sectionMenuItem}
            onClick={() => handleSelectSection(s)}
          >
            {s.numeration}. {s.name}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default SectionsFieldInner;
