import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import Empresa from './Empresa';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import PaginatedTable from './Material/PaginatedSettingSections';
import {filter, includes, cloneDeep, findIndex} from 'lodash';
import OrderService from '../ApiClients/OrderService';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import Toolbar from '@material-ui/core/Toolbar';
import {GetRole, GetEmpresa} from '../helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';
import DatePicker from './DatePicker';
import { IMe, IRegister, IIRegister, ICentroTrabajo, ISection, IFilter } from '../types';
import LoaderBeauty from './Material/activityProgress';
import CreateModal from './CreateDialog';
import List from './Material/MultiSelect';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import ReactExport from 'react-export-excel';
import SearchField from './SearchField';
import LateralMenu from './LateralMenu';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';

function tmRegister(centrotrabajoId: any){
    return ({
        centrotrabajoId:centrotrabajoId,
        id: -1,
        description: '',
        activated: true,
        used: false
    });
};

const options_filters:any = {
    used: [
        {
            id: "1",
            name: "SÍ",
        },
        {
            id: "0",
            name: "NO",
        }
    ]
}

const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const exportDataExcel=(empresa:ICentroTrabajo, data:Array<ISection>)=>{
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const d = new Date();
    const date = `${d.getFullYear()}${String(d.getMonth()+1).padStart(2,'0')}${String(d.getDate()).padStart(2,'0')}`;
    
    data = data.map((item:ISection, idx:number)=>{
        return({
            ...item,
            option: `${item.id}_${item.description}`
        })
    })
    
    let sheets = 
            <ExcelSheet data={data} name={`Secciones`} key={0}>
                <ExcelColumn label="id" value="id"/>
                <ExcelColumn label="dsc" value="description"/>
                <ExcelColumn label="option" value="option"/>
            </ExcelSheet>

    return (
        <ExcelFile filename={`Secciones_${empresa.description.replaceAll(' ','_')}_${date}`} hideElement={true} element={<button>{`Descargar`}</button>}>
            {sheets}
        </ExcelFile>
    );
}

const fieldsUsers = [
    [
        {
            id: 'description',
            name: 'Descripción',
            required: true,
            style:3,
            helper:'',
            maxText:300,
            typeField:2,
            multiline:false
        },
        {
            id: 'activated',
            name: 'Activo',
            required: true,
            style:1,
            typeField:5,
        }
    ],
];

const fields_filters = [
    {
        id: 'used',
        name: 'Configurado',
        required: false,
        style:1,
        type: 1
    }                  

];

interface IFormListadoItemProps {
    classes: any;
    options:any;
    handleCreateItem:Function;
    handleCloseItemForm: Function;
    loading:boolean;
    //centrotrabajoId:string;
    register: ISection;
}

interface IFormListadoItemState {
    itemListado:any;
}

class FormListadoItem extends Component<IFormListadoItemProps, IFormListadoItemState> {
    constructor(props: IFormListadoItemProps) {
        super(props);
        this.state = {
            itemListado: props.register,
        };
    }

    handleChangeUser=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.value;
        this.setState({ itemListado: newItemListado });
    }    
    
    handleChangeCheckBox=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.checked;
        this.setState({ itemListado: newItemListado });
    }    
    

    shouldDisableToday = (date:any) => {
        return false;
    }

    handleChangeDate=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event;
        this.setState({ itemListado: newItemListado });
    }

    handleListChange=(id:any, e:any, list:any)=> {
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = list;
        this.setState({ itemListado: newItemListado });
    }

    verifyForm=(type:number, id?:string)=>{

        if(type == 1){ // Verifica todo el formulario
            const item = this.state.itemListado;
            if(item.role == -1){
                return false;
            } else if(item.description.length == 0){
                return false;
            }

            return true;
        } else if(type == 2){ // Verifica el ítem

        }

        return false;
        /*let idProduct : number = Number(this.state.productInfo.id)
        let ivaProduct : number = Number(this.state.productInfo.iva)

        if(verificationType == 0) {
            switch(id){
                case "id":
                    if(idProduct <= 0){
                        return false;
                    }
                    break;
                case "name":
                    if(this.state.productInfo.name.length == 0){
                        return false;
                    }
                    break;
                case "iva":
                    if(ivaProduct < 0){
                        return false;
                    }
                    break;
            }
            return true;
        } else {

            if(idProduct <= 0){
                return false;
            }

            if(this.state.productInfo.name.length == 0){
                return false;
            }

            if(ivaProduct < 0){
                return false;
            }
            return true;
        }*/

    }
    handleResetItemForm = () => {
        this.setState({itemListado:undefined});
    }

    render() {
        const {itemListado} = this.state;

        let fieldsTMP = fieldsUsers.map((n0:any, index:number)=>{
            let fieldsN0 = n0.map((n:any, index:number)=>{
                switch(n.typeField){
                    case 1:
                        return(
                            <SelectorField
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={this.props.options[n.id]?this.props.options[n.id]:[]}
                                keyDescription={n.id}
                                handleFieldChange={this.handleChangeUser}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                required={n.required}
                                style={n.style}
                                deviceData={itemListado}
                            />
                        );
                    case 2:
                        return(
                            <TextFieldInput
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={itemListado}
                                handleFieldChange={this.handleChangeUser}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                required={n.required}
                                style={n.style}
                                helper={n.helper}
                                maxText={n.maxText}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                                noError={true}
                                multiline={n.multiline}
                            />
                        );
                    case 3:
                        return(
                            DatePicker(n.id, n.name, "dd/MM/yyyy", this.handleChangeDate, itemListado.fechaReferencia, false, this.shouldDisableToday, new Date('1970-01-01'))
                        );
                    case 4:
                        return(
                            <div style={{marginBottom:20}}>
                                <List id={n.id} data={itemListado[n.id]} multiple={true} name={n.name} placeholder={n.name} options={this.props.options[n.id]?filter(this.props.options[n.id], function(o){return o.activated}):[]} handleChangeSelect={(e:any, list:any)=>this.handleListChange(n.id,e,list)}/>
                            </div>
                        );
                    case 5:
                        return(
                            <CheckBoxField
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={itemListado}
                                handleFieldChange={this.handleChangeCheckBox}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                            />
                        )
                    default:
                        return('');
                }
            })
            return (
                <form key={index} noValidate autoComplete="off">
                    {fieldsN0}
                </form>
            )
        })

        return (
            <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-little'>
                <DialogTitle id="form-dialog-title">{'FORMULARIO SECCIÓN'}</DialogTitle>
                <DialogContent>
                    {fieldsTMP}
                </DialogContent>
                <DialogActions>
                    {!this.props.loading?
                        <div style={{ display:'flex', gap:20 }}>
                            <Button onClick={()=>this.props.handleCloseItemForm()} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(1)} onClick={()=>this.props.handleCreateItem(this.state.itemListado)} className="confirm-dialog">
                                {`${itemListado.id == -1?`Crear`:`Actualizar`}`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}
const FormListado = withStyles(listadoStyles)(FormListadoItem);

interface IListadoProps {
    history:any;
    match:any;
    me?: IMe;
    version: string;
    filters: IFilter;
    handleChangeFilter: Function;
    handleCheckFilter: Function;
}

interface IListadoState {
    rowSelected: number;
    creationModalOpen:boolean;
    creationItemOpen: boolean;
    creationModalOpenEmail:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    htmlBody:boolean;
    sendingEmail:boolean;
    emailResult:number;
    currentYearSelected: number|string;
    loading:boolean;
    loadingDocuments:boolean;
    documents:any;
    options?:any;
    searchValue:string;
    creatingItem:boolean;
    bRegister: ISection;
    loadingModal: boolean;
    titleModal: string;
    editing: boolean;
    saving: boolean;
    downloaddata:boolean;

    filter_id: string;

}

class Income extends Component<IListadoProps, IListadoState> {
    constructor(props: IListadoProps) {
        super(props);
        this.state = {
            rowSelected: -1,
            //dataIncome: dataIncome,
            documents:[],
            creationModalOpen:false,
            creationItemOpen:false,
            creationModalOpenEmail:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            sendingEmail:false,
            emailResult:-1,
            htmlBody:false,
            currentYearSelected: new Date().getFullYear(),
            loading: true,
            loadingDocuments: true,
            searchValue: '',
            creatingItem: false,
            bRegister: tmRegister(this.props.match.params.centrotrabajoId),
            loadingModal: false,
            titleModal: `MENSAJE`,
            editing: false,
            saving: false,
            downloaddata:false,
            filter_id: "f_ss"
        };
    }

    componentDidMount(){
        this.loadData();
    }

    componentDidUpdate(prevProps:IListadoProps){
        if(prevProps.me !== this.props.me)
            this.loadData();
    }

    loadData = () => {
        const {me} = this.props;

        if(!me)
            return;
        if ([50, 100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) > -1) {
            this.props.handleCheckFilter(this.props.match.params.centrotrabajoId);
            this.loadSections();
        } else {
            this.setState({
                loading:false,
                loadingDocuments: false
            })
        }
    }

    loadSections = async () => {
        const centrotrabajoId = Number(this.props.match.params.centrotrabajoId);
        let response = await OrderService.LoadSections(centrotrabajoId);
        this.setState({loadingDocuments: false, documents: response.sections, options: {}});
    }

    handleFilterDocuments=(documents:Array<ISection>, f:any)=>{
        let newDocuments = cloneDeep(documents);
        
        //** FILTRA POR LOS FILTROS APLICADOS */
        const keys = Object.keys(f);
        const values:any = Object.values(f);

        if(keys.length > 0){
            for(let a=0; a<keys.length;a++){
                newDocuments = filter(newDocuments, function(o){return o[keys[a]] == values[a]})
            }
        }

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o){return includes(o.description.toLowerCase(), searchValue) });
        }

        return newDocuments;
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }

    handleCleanFilters = (evt:any)=>{
        evt.stopPropagation();
        this.props.handleChangeFilter(this.state.filter_id, {}, false);
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false, });
    }

    onCloseFormItemModal = () => {
        this.setState({ creationItemOpen: false });
    }

    /* CALL BACKEND */


    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1:
                break;
        }
        this.onCloseCreationModal();
    }

    onCloseCreationModalEmail = () => {
        this.setState({ creationModalOpenEmail: false });
    }

    handleClickListado=(income:number)=>{
        this.setState({creationItemOpen:true, bRegister: tmRegister(this.props.match.params.centrotrabajoId)});
        /*switch(income){
            case 1:
                this.props.history.push('/generar/preventivo')
                break;
            case 2:
                this.props.history.push('/ingressos/correctivo')
                break;
        }*/
    }

    handleSelectYearChange = (event:any) => {
    }

    handleItemClick=(item:any)=>{
        this.props.history.push(`${window.location.hash.replace('#','')}/${item.id}`)
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters[this.state.filter_id]);
        newFilters[id] = evt.target.value;

        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);
    }

    handleCreateItem=(item:any)=>{
        this.setState({creatingItem:true})
        this.saveData(item);
    }

    saveData=async(item:any)=>{
        let response:any = await OrderService.saveData(item, 11);
        if(response == 2){
            this.setState({
                modalText: '¡ATENCIÓN! Ya existe una sección igual.',
                modalType: 1,
                creationModalOpen: true,
                creatingItem:false,
            });
        } else if(response == 3){


            this.setState({
                modalText: `¡ATENCIÓN! No se puede desactivar la sección "${item.description}" ya que se encuentra configurada en el panel de control de este u otros centros de trabajo.`,
                modalType: 1,
                creationModalOpen: true,
                creatingItem:false,
                creationItemOpen: false,
            });
        } else {
            this.loadSections();
            this.setState({creatingItem:false, creationItemOpen:false});
        }
    }

    
    saveData03 = async (section:ISection) => {
        let response:any = await OrderService.saveData(section, 14);

        if(response !== 1){
            let newItemListado = cloneDeep(this.state.documents);
            const idx = findIndex(newItemListado, function(o:ISection){return o.id == section.id});
    
            if(idx > -1)
                newItemListado[idx].used = !section.used;

            if(response == 3){
                let response:any = await OrderService.LoadPanelControl(this.props.match.params.centrotrabajoId);
                let count = undefined;
                let modal_text:any = `¡ATENCIÓN! No se puede desactivar la sección "${section.description}" para este centro de trabajo porqué existen otras OTs con dicha sección configurada.`
    
                /*if(response){
                    count = filter(response, function(o){return o.seccion == section.id});
                    modal_text = <div><p>{`¡ATENCIÓN! No se puede desactivar la sección "${section.description}" para este centro de trabajo porqué existen las siguientes OTs con dicha sección configurada:`}</p><p style={{fontWeight:'bold'}}>{count.map(function(o){return `OT: ${o.id}`}).join("\r\n")}</p></div>
                }*/
    
                this.setState({
                    modalText: modal_text,
                    modalType: 1,
                    creationModalOpen: true,
                    creatingItem:false,
                    documents: newItemListado,
                    loadingDocuments: false
                });
            } else { 
                this.setState({
                    modalText: '¡ATENCIÓN! No se ha podido guardar el cambio.',
                    modalType: 1,
                    creationModalOpen: true,
                    creatingItem:false,
                    documents: newItemListado,
                    loadingDocuments: false
                });
            }
        }
        else {
            this.setState({
                creationModalOpen: false,
                creatingItem:false,
                loadingDocuments: false
            });
        }
    }

    handleClickOptionRow=(register:ISection, option:number)=>{
        switch(option){
            case 1: // Implica editar el registro
                this.setState({creationItemOpen:true, bRegister: register})
                break;
            default:
                break;
        }
    }
            
    handleChangeCheckBox=(id:any, event:any, register:ISection)=>{
        let newItemListado = cloneDeep(this.state.documents);
        const idx = findIndex(newItemListado, function(o:ISection){return o.id == register.id});

        if(idx > -1){
            newItemListado[idx][id] = event.target.checked;
            newItemListado[idx].changed = true;
            this.setState({ documents: newItemListado, loadingDocuments:true, /*editing: true*/ }, ()=>this.saveData03(newItemListado[idx]));
        }
    }    
    
    handleDownloadList = (data:Array<any>) => {
        const f_data = filter(data, function(o){return o.used});

        if(f_data.length == 0)
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No hay ningún registro para exportar para este centro de trabajo.`,
                modalType: 1,
                creationModalOpen: true
            })
        else
            this.setState({
                downloaddata: true
            }, ()=>setTimeout(() => {
                this.setState({downloaddata: false})
            }, 2500))

    }
 
    RenderFilters = (optionsfilters:any, arrayfilters:any) => {
        if(optionsfilters == undefined)
            return `Fallo en la carga de los filtros, vuelva a refrescar la página.`;

        let filters = fields_filters.map((item:any, index:number)=>{

            if(item.type == 1)
                return (
                    <SelectorField
                        key={index}
                        name={item.name}
                        id={item.id}
                        deviceData={arrayfilters}
                        fieldsData={optionsfilters[item.id]?optionsfilters[item.id]:[]}
                        handleFieldChange={this.handleChangeFilter}
                        required={item.required}
                        style={item.style}
                        className='filter-item'
                    />
                );
        })

        return filters;
    }

    render() {

        const {loadingDocuments, options, documents, searchValue, creatingItem, bRegister, filter_id} = this.state;
        const {me, filters} = this.props;
        const empresa:ICentroTrabajo|undefined = GetEmpresa(this.props.match.params.centrotrabajoId, me);

        let title = (
            <div className='App-title-webPage'>
                {`Panel secciones`}
                {'  '}
                {/*<Loader loading={this.state.loading} />*/}
            </div>
        );


        const apply_filters: any = filters[filter_id];
        const f_documents = this.handleFilterDocuments(documents, apply_filters);

        return (
            <div className='App-basic'>
                <Layout 
                    history={this.props.history} 
                    currentPage={1}
                    allowDrawer={true}
                    match={this.props.match}
                    version={this.props.version}
                    me={me}
                    showEmpresa
                    showLateralMenu
                />
                <div className='App-body-webBar'>
                    {me?
                        [50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1?
                            <div>
                                <div className="App-header">
                                    {title}
                                </div>
                                <label style={{ marginLeft:10 }}>
                                    {`No tiene permisos para visualizar este espacio.`}
                                </label>
                            </div>
                        :
                            <div>
                                <div className="App-header">
                                    {title}
                                    <div className='filter-list' style={{ marginTop:20 }}>                                                        
                                        <Toolbar variant="dense" className='filter-list-toolbar'>
                                            {this.RenderFilters(options_filters, apply_filters)}
                                        </Toolbar>
                                        <div className='clear-filter'>
                                            {Object.keys(apply_filters).length == 0?
                                                <IconButton aria-label="filter list">
                                                    <FilterListIcon />
                                                </IconButton>
                                            :
                                                <IconButton onClick={(evt:any)=>this.handleCleanFilters(evt)} aria-label="filter list">
                                                    <ClearAllIcon />
                                                </IconButton>
                                            }
                                        </div>                                        
                                    </div> 
                                    <div className="App-header-options">
                                        <div style={{ display:'flex', gap:'16px' }}>
                                            <div className={`App-header-options-item App-header-options-item-create ${!loadingDocuments?null:`App-header-options-item-disable`}`} onClick={()=>(!loadingDocuments?this.handleClickListado(1):null)}>
                                                {`+ Crear sección`}
                                            </div>
                                            <div className="App-header-options-item">
                                                <IconButton onClick={()=>this.handleDownloadList(f_documents)} aria-label="download list" style={{marginLeft:10}}>
                                                    <DownloadIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                            <SearchField placeholder="Buscar..." value={searchValue} allowSearch={true} onValueChange={this.handleSearchChange} handleClearSearchValue={this.handleClearSearchValue}/>
                                    </div>
                                </div>
                                <div>
                                    {this.state.editing?
                                            <Button disabled={this.state.saving?true:false} /*onClick={()=>this.handleSaveData(item)}*/ variant="contained" color="primary" style={{backgroundColor:'#8cd98c', width:'100%', marginTop:20}}>
                                                {!this.state.saving?
                                                    <TakeIcon style={listadoStyles.leftIcon}/>
                                                :
                                                    <Loader loading={true}/>
                                                }
                                                {`${this.state.saving?`Guardando...`:`Guardar`}`}
                                            </Button>
                                    :
                                        null
                                    }
                                </div>
                                <div>
                                    <PaginatedTable
                                        data={f_documents}
                                        handleSearch={this.handleSearchChange}
                                        searchValue={searchValue}
                                        onClearSearchValue={this.handleClearSearchValue}
                                        handleItemClick={this.handleItemClick}
                                        handleClickOptionRow={this.handleClickOptionRow}
                                        handleChangeCheckBox={this.handleChangeCheckBox}
                                        handleDownloadList={this.handleDownloadList}
                                    />
                                </div>
                                {this.state.creationItemOpen?
                                    <FormListado
                                        options={options}
                                        handleCloseItemForm={this.onCloseFormItemModal}
                                        handleCreateItem={this.handleCreateItem}
                                        loading={creatingItem}
                                        register={bRegister}
                                    />
                                :
                                    null
                                }
                                {this.state.creationModalOpen?
                                    <CreateModal
                                        onCloseCreationModal={this.onCloseCreationModal}
                                        isOpen={this.state.creationModalOpen}
                                        inputText={this.state.modalText}
                                        typeModal={this.state.modalType}
                                        action={this.handleCreateModal}
                                        typeAction={this.state.modalAction}
                                        loadingModal={this.state.loadingModal}
                                        titleModal={this.state.titleModal}
                                    />
                                :
                                    null
                                }
                            </div>
                    :
                        this.state.loading?
                            <div style={{marginLeft:20}}>
                                <Loader loading={true}/>
                            </div>
                        :
                            `Fallo en la carga del perfil, volver a intentar`
                    }
                    
                    {this.state.downloaddata && empresa?
                        exportDataExcel(empresa, filter(f_documents, function(o){return o.used}))
                    :
                        null
                    }

                    <LoaderBeauty visible={loadingDocuments?true:false}/>
                </div> 
            </div>
        );
    }
}

export default Income;