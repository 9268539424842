import { Formik } from "formik";
import { Props } from "./types";
import InformeCreateFormInner from "./InformeCreateFormInner";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const InformeCreateForm = ({
  Header,
  Content,
  Actions,
  SubmitProps,
  disabled,
  disabledFields,
  onSubmit,
  onBack,
  ...props
}: Props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <Formik {...props} onSubmit={onSubmit}>
        {(formikProps) => (
          <InformeCreateFormInner
            Header={Header}
            Content={Content}
            Actions={Actions}
            SubmitProps={SubmitProps}
            disabled={disabled}
            disabledFields={disabledFields}
            onBack={onBack}
            {...formikProps}
          />
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

export default InformeCreateForm;
