import Loader from "../../../Components/Loader";

type Config = {
  loading: boolean | void;
};

const useLoader = ({ loading }: Config) => {
  return (
    loading && (
      <div style={{ marginLeft: 20 }}>
        <Loader loading={true} />
      </div>
    )
  );
};

export default useLoader;
