import { object as objectSchema, string as stringSchema } from "yup";

export const getValidationSchema = () => {
  return objectSchema().shape({
    name: stringSchema().required('Campo obligatorio'),
    description: stringSchema(),
    team: stringSchema(),
    participant: stringSchema(),
    dtStartVisit: stringSchema().required('Campo obligatorio')
  });
};