import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Input,
  Switch,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Add, Delete, ExpandMore, NoteAdd } from "@material-ui/icons";
import * as React from "react";
import { Props } from "./types";
import classnames from "classnames";
import SectionMark from "../../../../components/SectionMark";
import SubsectionCommentsField from "../SubsectionCommentsField";
import MeasureTypeMenu from "../../../../../Informes/modules/informe-create-measure/components/MeasureTypeMenu";
import { MeasureType } from "../../../../../Informes/modules/informe-create-measure/hooks/useInformeCreateMeasureForms";
import { Section, Subsection } from "../../../../types";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 10,
  },
  headerRoot: {
    backgroundColor: "#707070",
    color: "white",
    borderRadius: "10px 10px 0 0",
  },
  headerRootClosed: {
    borderRadius: 10,
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerWrapper: {},
  title: {
    textTransform: "uppercase",
  },
  subtitle: {},
  actionsWrapper: {
    display: "flex",
    alignItems: "center",
  },
  actionWrapper: {
    backgroundColor: "white",
    marginLeft: 15,
  },
  action: {
    height: 25,
    width: 25,
  },
  actionsMenu: {},
  actionsMenuItem: {},
  markBox: {
    backgroundColor: "white",
  },
  content: {},
  divider: {},
}));

const SubsectionAccordion = ({
  subsection,
  fieldName,
  showDescription,
  className,
  onDeleteClick,
  onSwitchClick,
  onChange,  
  disabled,
  onOpenMeasureDialog,
  onFormChange,
  showMark,
  ...props
}: Props) => {
  const classes = useStyles();
  const [swithState, setSwitchState] = React.useState(true);
  const [accordionOpen, setAccordionOpen] = React.useState(true);
  const toggleOpen = () => setAccordionOpen((prev) => !prev);
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const onAddActionClick = React.useCallback((ev: any) => {
    ev.stopPropagation();
    setAnchorEl(ev?.currentTarget);
  }, []);
  const onCloseActionMenu = useStopPropagationWrapper(() => setAnchorEl(null));

  const onSelectMeasure = React.useCallback(
    (subsection: Subsection, type: MeasureType) => {
      onOpenMeasureDialog(subsection, type);
      onCloseActionMenu();
    },
    [onCloseActionMenu, onOpenMeasureDialog]
  );

  const onDeleteSubsection = useStopPropagationWrapper(onDeleteClick);
  const commentInputRef = React.useRef<HTMLInputElement>();
  const onAddComment = useStopPropagationWrapper(() => {
    setTimeout(() => commentInputRef.current?.focus(), 0);
    setAccordionOpen(true);
  });
  
  const onSwitchSubsection = React.useCallback((ev:any) =>{
    ev.stopPropagation();        
    onSwitchClick(ev);
  }, [onSwitchClick]);

  return (
    <>
      <Accordion
        expanded={accordionOpen}
        onChange={toggleOpen}
        className={classnames(classes.root, className)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore style={{ color: "white" }} />}
          aria-controls={`${fieldName}-accordionContent`}
          id={`${fieldName}-accordionHeader`}
          classes={{
            root: classnames(classes.headerRoot, {
              [classes.headerRootClosed]: !accordionOpen,
            }),
            content: classes.headerContent,
          }}
        >
          <div>
            <Typography variant="subtitle1" className={classes.title}>
              {subsection.numeration}. {subsection.name}
            </Typography>
            {showDescription && (
              <Typography className={classes.subtitle}>
                {subsection.description}
              </Typography>
            )}
          </div>
          <div className={classes.actionsWrapper}>
            {/* {!disabled && (
              <IconButton
                className={classnames(classes.actionWrapper, classes.action)}
                onClick={onAddComment}
              >
                <Add />
              </IconButton>
            )} */}
            <SectionMark
              className={classnames(classes.actionWrapper, classes.markBox)}
            >
              <div>
                <Typography color="textPrimary" variant="h6">
                  {subsection.numeration}
                </Typography>
              </div>
            </SectionMark>

            {!disabled && (
              <IconButton
                style={{ marginLeft: "15px" }}
                className={classnames(classes.action)}
                onClick={onAddActionClick}
              >
                <NoteAdd style={{ color: "white" }} />
              </IconButton>
            )}

            {!disabled && (
              <IconButton
                style={{ marginLeft: "15px" }}
                className={classnames(classes.action)}
                onClick={onDeleteSubsection}
              >
                <Delete style={{ color: "white" }} />
              </IconButton>
            )}

            {showMark ? (
              <Switch disabled={disabled} title="Incluir este valor en el cálculo del promedio" checked={subsection.includeAvg} color="default" onClick={(e:any)=>onSwitchSubsection(e)}/>
            ):null}
            {showMark?
            <SectionMark
              className={classnames(classes.actionWrapper, classes.markBox)}
            >
              <Input
                name={`${fieldName}.mark`}
                type="number"
                onChange={onChange}
                onClick={(ev) => {
                  ev.stopPropagation();
                }}
                value={subsection.mark}
                inputProps={{
                  step: 0.01,
                  style: { textAlign: "center" },
                }}
                disabled={disabled}
              />
            </SectionMark>
          :
            null
          }
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <SubsectionCommentsField
            name={`${fieldName}.comments`}
            disabled={disabled}
            inputRef={commentInputRef}
            onFormChange={onFormChange}
          />
        </AccordionDetails>
      </Accordion>
      <MeasureTypeMenu
        anchorEl={anchorEl}
        subsection={subsection}
        onClose={onCloseActionMenu}
        onSelectMeasure={onSelectMeasure}
      />
    </>
  );
};

export default SubsectionAccordion;

const useStopPropagationWrapper = (fn?: () => void) => {
  return React.useCallback(
    (ev?: any) => {
      // difficult to get the specific html event
      ev?.stopPropagation();
      if (fn) {
        fn();
      }
    },
    [fn]
  );
};
