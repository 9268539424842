import * as React from "react";
import { ClassKey, Props } from "./types";
import {
  Paper,
  Table,
  TableContainer,
  TablePagination,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import EnhancedTableHead from "../EnhancedTableHead";
import EnhancedTableRow from "../EnhancedTableRow";
import { TableBody } from "@mui/material";

const useStyles = makeStyles<Theme, Props, ClassKey>((theme) => ({
  root: {
    width: "100%",
    marginTop: 40,
    "& thead.MuiTableHead-root": {
      borderBottom: "2px solid #00D3FF",
    },
    "& thead .MuiSvgIcon-root": {
      color: "#00D3FF !important",
      fontSize: "30px",
    },
    "& tbody> .MuiTableRow-root": {
      "&:nth-child(odd)": {
        backgroundColor: "#EEEEEE",
      },
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
      },
    },
  },

  paper: {
    width: "auto",
    marginBottom: theme.spacing(2),
    border: "3px solid rgb(0, 43, 97, 0.60)",
    borderRadius: "5px",
  },

  title: {},
  table: {},
}));

const InformeActionsTable = (props: Props) => {
  const {
    actions,
    order,
    orderBy,
    columns,
    rowsPerPageOptions,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    onClickAction,
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Paper square elevation={0}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {"Medidas realizadas"}
        </Typography>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              columns={columns}
            />
            <TableBody>
              {actions.map((action) => (
                <EnhancedTableRow
                  key={action.referenceDsc}
                  action={action}
                  onClickAction={onClickAction}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={actions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          labelRowsPerPage="Nº Registros por página:"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from} - ${to} de ${count}`;
          }}
        />
      </Paper>
    </div>
  );
};

export default InformeActionsTable;
