import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe, INew } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import {cloneDeep, filter, includes} from 'lodash';
import SearchField from './SearchField';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    new:{
        marginBottom:20,
        borderRadius:'10px',
        padding:10,
    },
});

interface INotificacionesProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    updateMe:Function;
    version: string;
}

interface INotificacionesState {
    me?:IMe;
    searchValue: string;
}

class Notificaciones extends Component<INotificacionesProps, INotificacionesState> {
    constructor(props: INotificacionesProps) {
        super(props);
        this.state = {
            me: props.myInfo,
            searchValue: '',
        };
    }

    componentDidUpdate(prevprops:INotificacionesProps){
        if(prevprops.myInfo !== this.props.myInfo){
            this.setState({me:this.props.myInfo})
        }
    }

    componentWillUnmount(){
        const {me} = this.state;
        if(!me)
            return;
        let nNews = filter(me.news, function(o){return !o.viewed});
        if(nNews.length > 0){
            let uNews = me.news.map((item)=>{
                return {
                    ...item,
                    viewed: true
                }
            })
            me.news = uNews;
            this.props.updateMe(me, 15);
        }
    }

    handleFieldChange=(id:string, evt:any)=>{
        let newMe = cloneDeep(this.state.me);
        if(newMe){
            newMe[id] = evt.target.checked;
            this.setState({me:newMe});
        }
    }

    handleFieldTextChange=(evt:any)=>{
        let newMe = cloneDeep(this.state.me);
        if(newMe){
            newMe[evt.target.id] = evt.target.value;
            this.setState({me:newMe});
        }
    }

    renderNews=(me:IMe, classes:any)=>{
        const f_news = this.handleFilterNews(me.news);

        if(f_news.length == 0)
            return <p>{`No hay noticias que mostrar con los valores de búsqueda.`}</p>

        let items = f_news.map((item:INew, index:number)=>{
            return(
                <div className={`new-card ${!item.viewed?'new-card-not-viewed':'new-card-viewed'}`} key={index} dangerouslySetInnerHTML={{ __html: item.new }} />
            );
        });
        return items;
    }

    handleFilterNews=(news:Array<INew>)=>{

        let n_news = cloneDeep(news);

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(n_news.length > 0 && searchValue && searchValue.length > 0){
            n_news = filter(n_news, function(o){return includes(o.new.toLowerCase(), searchValue) });
        }
        return n_news;
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }

    render() {
        const {myInfo, classes, version, updateMe} = this.props;
        const {me, searchValue} = this.state;

        return (
            <div className='Home-basic'>
            
                <Layout 
                    history={this.props.history} 
                    currentPage={1}
                    allowDrawer={true}
                    match={this.props.match}
                    version={this.props.version}
                    me={me}
                    showEmpresa={false}
                    showLateralMenu={false}
                />

                <div className='Home-header-webBar'>
                    <div className="App-header-options" style={{ marginRight:15 }}>
                        <SearchField placeholder="Buscar..." value={searchValue} allowSearch={true} onValueChange={this.handleSearchChange} handleClearSearchValue={this.handleClearSearchValue}/>
                    </div>
                </div>
                <div className='bg-companies' >
                    <div className='App-header' style={{ paddingLeft:20 }}>
                        {`Novedades`}
                    </div> 
                    {me?
                        <div className='news-container'>
                            {me.news.length == 0?
                                <p>{`No hay noticias que mostrar.`}</p>
                            :
                                this.renderNews(me, classes)
                            }
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </div>

                {/* <div className='Home-header-webBar'>

                    {me?
                        <div>
                            <div className="App-header" style={{marginTop:75}}>
                                {`Novedades`}
                            </div>
                            <div style={{ marginLeft:10 }}>
                                {me.news.length == 0?
                                    <strong>{`No hay noticias que mostrar.`}</strong>
                                :
                                    this.renderNews(me, classes)
                                }
                            </div>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </div> */}

            </div>
        );
    }
}

export default withStyles(styles)(Notificaciones);