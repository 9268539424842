import { IRegister } from "../../../../../types";

type Config = {
  onSuccess?: (item:any, action:number) => void;
};

const useOnSubmitFormCorrectivo = ({ onSuccess }: Config) => {
  // This is the reused submit
  //   saveData=async(item:any)=>{
  //     let response:any = await OrderService.saveData(item, 2);
  //     this.loadListado();
  //     this.setState({creatingItem:false, creationItemOpen:false});
  // }
  return (item: IRegister) => {
    console.log("Submitting Form Correctivo 'unknown typed' item");
    // console.log(item);
    if (onSuccess) {
      onSuccess(item, 2);
    }
  };
};

export default useOnSubmitFormCorrectivo;
