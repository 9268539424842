import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Props as InformeCreateFormProps } from "../containers/InformeCreateForm";

export type FormPropsT = {
  Header?: React.ComponentType<any>;
  Content?: React.ComponentType<any>;
  Actions?: React.ComponentType<any>;
};

export type Props<FP extends InformeCreateFormProps> = Omit<
  DialogProps,
  "children" | "onClose"
> & {
  id?: string;
  closeButton?: boolean;
  onClose: NonNullable<DialogProps["onClose"]>;
  title: NonNullable<DialogProps["title"]>;
  Form: React.ComponentType<FP & FormPropsT>;
  FormProps: Omit<FP, "Header" | "Content" | "Actions">;
};

const InformeCreateFormDialog = ({
  id,
  closeButton,
  title,
  Form,
  FormProps,
  ...dialogProps
}: Props<InformeCreateFormProps>) => {
  const Header = ({ children }: { children?: React.ReactNode }) => {
    return (
      <React.Fragment>
        <DialogTitle style={{ marginRight: 30 }} id="alert-dialog-slide-title">
          {children || title}
        </DialogTitle>

        {closeButton ? (
          <IconButton
            aria-label="close"
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={(ev) => dialogProps.onClose(ev, "backdropClick")}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <Dialog
      {...dialogProps}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className="dialog-data"
    >
      <Form
        Header={Header}
        Content={DialogContent}
        Actions={DialogActions}
        {...FormProps}
      />
    </Dialog>
  );
};

export default InformeCreateFormDialog;
