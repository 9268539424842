import {
  ButtonBase,
  TableCell,
  TableRow,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { isNil } from "lodash";
import { Action } from "../../../../Section/types";
import { format } from "date-fns";

type ClassKey = "root" | "primaryText" | "secondaryText" | "statusCell";

export type Props = {
  action: Action;
  onClickAction?: (action: Action) => void;
};

const useStyles = makeStyles<Theme, {}, ClassKey>(() => ({
  root: {},
  primaryText: {
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign:'center'
  },
  secondaryText: {textAlign:'center'},
  statusCell: { display: "flex", alignItems: "center" },
  statusIcon: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    marginRight: 10,
  },
}));

const EnhancedTableRow = ({ action, onClickAction }: Props) => {
  const { referenceDsc, typeDsc, sectionDsc, subsectionDsc, dtCreated, attachments, estadoId, statusDsc, statusColor } =
    action;

  const classes = useStyles();

  return (
    <TableRow className={classes.root}>
      <TableCell>
          <Typography variant="body2" className={classes.secondaryText}>
            {typeDsc}
          </Typography>
      </TableCell>

      <TableCell align="center">
        <ButtonBase
          onClick={() =>
            onClickAction &&
            (action.tipo === 1 || action.tipo === 2) &&
            onClickAction(action)
          }
        >
          <Typography variant="body2" className={classes.primaryText}>{referenceDsc} </Typography>
        </ButtonBase>
      </TableCell>

      <TableCell>
        <Typography variant="body2" className={classes.secondaryText}>
          {sectionDsc}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" className={classes.secondaryText}>
          {subsectionDsc}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" className={classes.secondaryText}>
          {format(new Date(dtCreated), "dd/MM/yyyy")}
        </Typography>
      </TableCell>

      {/* <TableCell>
        <Typography variant="body2">
          {isNil(attachments) || attachments < 0 ? "-" : attachments}
        </Typography>{" "}
      </TableCell> */}

      <TableCell>
        <span className={classes.statusCell} style={{ justifyContent:'center' }}>
          <div
            style={{
              height: "10px",
              display: "inline-block",
              width: "10px",
              //backgroundColor: getColorByStatus(estadoId),
              backgroundColor: statusColor,
              borderRadius: "50%",
              marginRight: 10,
            }}
          ></div>
          <Typography
            variant="body2"
            className={classes.primaryText}
            display="inline"
          >
            {statusDsc}
          </Typography>
        </span>
      </TableCell>
    </TableRow>
  );
};

export default EnhancedTableRow;

// function getColorByStatus(status: number) {
//   switch (status) {
//     case 50:
//       return "rgb(34, 108, 32)";
//     case 20:
//       return "rgb(51, 175, 247)";
//     case 10:
//       return "rgb(255, 0, 0)";
//   }
// }
