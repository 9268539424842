import { Theme, Typography, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import * as React from "react";
type ClassKey = "root";

type Props = {
  className?: string;
  size?: "small" | "large";
  border?: boolean;
  children: React.ReactNode;
};

// Styles based on design AdobeXD
const useStyles = makeStyles<Theme, Props, ClassKey>(() => ({
  root: ({ size = "small", border = true }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: border ? "1px solid #707070" : undefined,
    borderRadius: 10,
    height: size === "small" ? 33 : 45,
    width: size === "small" ? 69 : 230,
  }),
}));

const SectionMark = (props: Props) => {
  const classes = useStyles(props);
  return (
    <div className={classnames(classes.root, props.className)}>
      <Typography variant="h6">{props.children}</Typography>
    </div>
  );
};

export default SectionMark;
