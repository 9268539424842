import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import DoneIcon from "@material-ui/icons/Done";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import SyncIcon from "@material-ui/icons/Sync";

export type Props = {
  statusDsc: string;
  status: number;
  statusColor: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
    },
    icon: {
      fontSize: "1.2em",
      display: "inline-block",
      verticalAlign: "middle",
    },
    statusText: {
      marginLeft: theme.spacing(1),
      textTransform: "uppercase",
      fontWeight: "bold",
    },
  })
);

export default function InformesTableStatusRowCell({
  statusDsc,
  status,
  statusColor,
}: Props) {
  const classes = useStyles();

  return (
    <TableCell align="center" className={classes.root}>
      {(() => {
        switch (status) {
          case 10:
            return (
              <PriorityHighIcon
                style={{ color: statusColor }}
                className={classes.icon}
              />
            );
          case 20:
            return (
              <SyncIcon style={{ color: statusColor }} className={classes.icon} />
            );
          case 50:
            return (
              <DoneIcon style={{ color: statusColor }} className={classes.icon} />
            );
          default:
            return null;
        }
      })()}
      <span className={classes.statusText}>{statusDsc}</span>
    </TableCell>
  );
}
