import * as React from "react";
import { Field, FieldArray } from "formik";

import SubsectionsFieldInner from "./SubsectionsFieldInner";
import type { Props } from "./types";

const SubsectionsField = ({ name, component, onFormChange, ...props }: Props) => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => (
      <Field
        {...props}
        __component={component}
        component={SubsectionsFieldInner}
        name={name}
        onFormChange={onFormChange}
        arrayHelpers={arrayHelpers}
      />
    )}
  />
);

export default SubsectionsField;
