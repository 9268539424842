import * as React from "react";
import { InformeRouteType } from "../../../hooks/useInformesRouteParams";
import { filter, includes, cloneDeep } from "lodash";
import InformeCreateForm from "../../Informes-create-form/containers/InformeCreateForm";
import useInformeCreateFormDialog from "../../Informes-create-form/hooks/useInformeCreateFormDialog";
import { InformeT } from "../../../types";

import InformesTable, { Headers, Order } from "../components/InformesTable";
import SearchAndCreateHeader from "../components/SearchAndCreateHeader";
import InformesTableFilters from "../components/InformesTableFilters";
import useInformesItems from "../hooks/useInformesItems";
import { OpenExternalFile } from "../../../../../helpers";

export type Props = {
  informeType: InformeRouteType;
  informes: InformeT[];
  userIdName: string;
  centrotrabajoId: number;
  onNavigateToInforme: (informeId: number) => void;
  canCreateInforme?: boolean;
};

const InformesTableContainer = ({
  informeType,
  informes: informes$props,
  userIdName,
  centrotrabajoId,
  onNavigateToInforme,
  canCreateInforme,
}: Props) => {
  const [informeCreateFormDialog, { handleOpen }] = useInformeCreateFormDialog({
    form: InformeCreateForm,
    formProps: {
      informeType,
      userIdName,
      centrotrabajoId,
      submitMeta: { onSuccess: onNavigateToInforme },
    },
    title: "Nuevo informe",
  });

  // const informes = useInformesItems(informes$props);

  const [searchValue, setSearchValue] = React.useState<string>("");
  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };
  const handleClearSearchValue = () => {
    setSearchValue("");
  };

  const [apply_filters, setApplyFilters] = React.useState<any>({});

  const handleFilterDocuments = (documents: any, filters: any) => {
    let newDocuments = cloneDeep(documents);

    //** FILTRA POR LOS FILTROS APLICADOS */
    const keys = Object.keys(filters);
    const values: any = Object.values(filters);

    if (keys.length > 0) {
      for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
        if (
          ["dtStartVisit"].indexOf(keys[keyIndex]) > -1 ||
          ["dtReport"].indexOf(keys[keyIndex]) > -1
        )
          newDocuments = filter(newDocuments, function (document) {
            return (
              new Date(document[keys[keyIndex]]).getDate() ===
                new Date(values[keyIndex]).getDate() &&
              new Date(document[keys[keyIndex]]).getMonth() ===
                new Date(values[keyIndex]).getMonth() &&
              new Date(document[keys[keyIndex]]).getFullYear() ===
                new Date(values[keyIndex]).getFullYear()
            );
          });
        else if (["lowerMark"].indexOf(keys[keyIndex]) > -1)
          newDocuments = filter(newDocuments, function (document) {
            return document["mark"] >= values[keyIndex];
          });
        else if (["upperMark"].indexOf(keys[keyIndex]) > -1)
          newDocuments = filter(newDocuments, function (document) {
            return document["mark"] <= values[keyIndex];
          });
        else
          newDocuments = filter(newDocuments, function (o) {
            return o[keys[keyIndex]] === values[keyIndex];
          });
      }
    }

    //** FILTRA POR EL VALOR DE BÚSQUEDA */
    if (
      newDocuments.length > 0 &&
      searchValue.toLowerCase() &&
      searchValue.toLowerCase().length > 0
    ) {
      newDocuments = filter(newDocuments, function (document) {

        return (
          includes(document.id, searchValue.toLowerCase()) ||
          includes(document.name.toLowerCase(), searchValue.toLowerCase()) ||
          // includes(document.dtStartVisit.toLowerCase(), searchValue.toLowerCase()) ||
          // includes(
          //   document.dtReport,
          //   searchValue.toLowerCase()
          // ) ||
          includes(document.statusDsc.toLowerCase(), searchValue.toLowerCase())
        );
      });
    }

    return newDocuments;
  };

  const [rowOptionSelected, setRowOptionSelected] = React.useState(-1);
  const [rowOptionSelectedAnchor, setRowOptionSelectedAnchor] =
    React.useState(null);
  const OptionisOpened = (id: number) => {
    if (rowOptionSelected === id) return true;

    return false;
  };
  const handleClickCloseRowOptions = () => {
    setRowOptionSelected(-1);
    setRowOptionSelectedAnchor(null);
  };
  const handleClickOpenRowOptions = (evt: any, id: number) => {
    setRowOptionSelectedAnchor(evt.currentTarget);
    setRowOptionSelected(id);
  };

  const handleClickOptionRow = (informe: InformeT, option: number) => {
    switch (option) {
      case 1:
        onNavigateToInforme(informe.id);
        break;
      case 2:
        OpenExternalFile(informe.storage)
        // const byteCharacters = atob(informe.storage.substr(`data:application/pdf;base64,`.length));
        // const byteArrays = [];

        // for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        //     const slice = byteCharacters.slice(offset, offset + 1024);

        //     const byteNumbers = new Array(slice.length);
        //     for (let i = 0; i < slice.length; i++) {
        //         byteNumbers[i] = slice.charCodeAt(i);
        //     }

        //     const byteArray = new Uint8Array(byteNumbers);

        //     byteArrays.push(byteArray);
        // }
        // const blob = new Blob(byteArrays, {type: `application/pdf`});
        // const blobUrl = URL.createObjectURL(blob);

        // window.open(blobUrl, '_blank');
        break;
      default:
        break;
    }
  };

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<Headers>("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: Headers
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeFilter = (id: string, evt: any) => {
    let newFilter = cloneDeep(apply_filters);
    newFilter[id] = evt.target.value;

    setApplyFilters(newFilter);
  };
  const handleChangeFilterDate = (id: string, date: any) => {
    let newFilter = cloneDeep(apply_filters);
    newFilter[id] = date;

    setApplyFilters(newFilter);
  };
  const handleCleanFilters = () => {
    setApplyFilters({});
  };

  return (
    <>
      <SearchAndCreateHeader
        canCreate={canCreateInforme}
        handleOpenDialog={handleOpen}
        searchValue={searchValue}
        handleChangeSearchValue={handleChangeSearchValue}
        handleClearSearchValue={handleClearSearchValue}
        options={informes$props}

        handleChangeFilter={handleChangeFilter}
        handleChangeFilterDate={handleChangeFilterDate}
        handleCleanFilters={handleCleanFilters}
        apply_filters={apply_filters}
        informeType={informeType}
        informes$props={informes$props}
      />

      <InformesTable
        informes={handleFilterDocuments(informes$props, apply_filters)}
        informeType={informeType}
        handleClickOptionRow={handleClickOptionRow}
        handleClickOpenRowOptions={handleClickOpenRowOptions}
        handleClickCloseRowOptions={handleClickCloseRowOptions}
        OptionisOpened={OptionisOpened}
        rowOptionSelectedAnchor={rowOptionSelectedAnchor}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
      />

      {canCreateInforme && informeCreateFormDialog}
    </>
  );
};

export default InformesTableContainer;
