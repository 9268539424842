import { InformeRouteType } from "../../../hooks/useInformesRouteParams";
import { InformeT } from "../../../types";
import { Props as InformeUpdateFormProps } from "../components/InformeUpdateForm";
export type { Values } from "../components/InformeUpdateForm";

export enum SubmitAction {
  SAVE = "save",
  PUBLISH = "publish",
  PREVIEW = "preview"
}

export type Props = Omit<
  InformeUpdateFormProps,
  "onSubmit" | "initialValues" | "onClickSubmit"
> & {
  // this is the 'informe' entity
  informe: InformeT;
  handleNavigateToReports: Function;
  informeRouteType: InformeRouteType;
  initialValues?: InformeUpdateFormProps["initialValues"];
};
