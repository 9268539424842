import * as React from "react";
import useSectionMarks from "./useSectionMarks";
import { Section } from "../../../types";
import { filter, groupBy, isNumber, pickBy, size, sum, sumBy } from "lodash";

type Config = {
  sectionMarks: ReturnType<typeof useSectionMarks>;
  sections: Pick<Section, "id" | "groupId">[];
};

type Return = {
  [groupId: number]: number | undefined;
  total: number;
};

const useSummaryMarks = (config: Config): Return => {
  return React.useMemo(() => getSummaryMarks(config), [config]);
};

export default useSummaryMarks;

export function getSummaryMarks({ sectionMarks, sections }: Config): Return {
  const sectionsByGroup = groupBy(sections, (section) => section.groupId);  
  const markByGroup = Object.entries(sectionsByGroup).reduce(
    (acc, [group, _sections]) => {
      const markedSections = filter(_sections, (s) =>
        sectionMarks[s.id]
      );      
      // Not undefined due to previous filter
      // console.log(markedSections);
      
      const sumOfMarks = sumBy(markedSections, (ms:any) => sectionMarks[ms.id][1]!);
      const sumOfSubs = sumBy(markedSections, (ms:any) => sectionMarks[ms.id][2]!);
      const avgGroup =
        sumOfSubs > 0
          ? sumOfMarks / sumOfSubs
          : 0;

      return { ...acc, [group]: avgGroup };
    },
    {}
  );

  const definedValues = pickBy(markByGroup, isNumber);

  const sumOfValues = sum(Object.values(definedValues));
  const totalAverage =
    size(definedValues) > 0 ? sumOfValues / size(definedValues) : 0;

  return { ...markByGroup, total: totalAverage };
}
