import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export type Column = {
  key: string;
  disablePadding?: boolean;
  label: string;
  onClick?: () => void;
};

export type Props = {
  columns: Column[];
  orderBy?: string;
  order?: SortDirection;
};

const EnhancedTableHead = ({ columns, order, orderBy, ...props }: Props) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.key}
            padding={column.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === column.key ? order : false}
            align="center"
          >
            <TableSortLabel
              active={/*orderBy === column.key*/ true}
              direction={order}
              onClick={column.onClick}
              IconComponent={ArrowDropDownIcon}
              style={{flexDirection: 'row'}}              
            >
              <Typography variant="subtitle1">
                {column.label.toUpperCase()}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
