import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe } from '../types';
import logoVC from '../Assets/nealia_prevent_icon.png';
import { authContext } from '../adalConfig';

const styles = (theme: Theme) => createStyles({
    containerStyle: {
        textAlign: 'center',
        verticalAlign: 'middle',
        color: '#888',
        lineHeight: '1.2',
        marginTop: '40vh',
    },
    titleStyle: {
        color: '#555',
        fontSize: '2em',
        fontWeight: 'bold',
    },
    bodyStyle: {
        color: '#555',
        marginTop:20,
        margin: '0 auto',
        width: '280px',
        fontWeight: 'bold',
    },
    imageStyle: {
        marginBottom:20,
        width: 150,
    }
});

interface IUnauthorizedProps {
    history: any;
    classes: any;
    refreshMyInfo: Function;
    myInfo?: IMe;
}

interface IUnauthorizedState {
}

let timer : any;

class Unauthorized extends Component<IUnauthorizedProps, IUnauthorizedState> {
    render() {
        const { classes, myInfo } = this.props;
        return (
            <div className={classes.containerStyle}>
                <img className={classes.imageStyle} src={logoVC} alt='logo' />
                <div className={classes.titleStyle}>{'NO AUTORIZADO'}</div>
                <p className={classes.bodyStyle}>{'Lo sentimos, pero no tiene permisos para interactuar con la web.'}</p>
                <br />
                <div style={{ margin:'auto', textAlign:'left' }}>
                    <p className={classes.bodyStyle}>{`Nombre: ${myInfo?.userIdName}`}</p>
                    <p className={classes.bodyStyle}>{`Cuenta: ${myInfo?.userId}`}</p>
                </div>
                <br />
                <button className='App-header-options-item App-header-options-item-create' style={{ margin:'auto' }} onClick={()=>window.location.replace(`${window.location.origin}${window.location.pathname}`)}>{'Volver a intentar'}</button>
                <br />
                <button className='App-header-options-item App-header-options-item-create' style={{ margin:'auto' }} onClick={()=>authContext.logOut()}>{'Cerrar sesión'}</button>
            </div>
        );
    }
}

export default withStyles(styles)(Unauthorized);