import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Document, Page } from '../Material/pdfRenderComponents';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons//InfoOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DatePicker from '../DatePicker';
import { IMe, IIIRegister, IVRegister, IForm, IQuestion, ICentroTrabajo } from '../../types';
import { SelectorField, CheckBoxField, TextFieldInput } from '../FormsWidgets';
import {GetRole, GetEmpresa} from '../../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import {cloneDeep, filter, find, findIndex} from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageViewIcon from '@material-ui/icons/Pageview';
import { GetConcatedDate, GetDatetimeFormat } from '../../vc_general';
import OrderService from '../../ApiClients/OrderService';
import LoaderBeauty from '../Material/activityProgress';
import CreateModal from '../CreateDialog';
import ListSecciones from '../Material/MultiSelect';
import Loader from '../Loader';
import { ListChildComponentProps } from 'react-window';
import {epis} from '../../ApiClients/DummyData';

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY:'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
    width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        //overflowX: 'auto',
        borderWidth: 2, 
        borderColor: '#00809b',
        borderStyle: 'solid',
    },
    table:{
        //tableLayout:'fixed'
        width: '100%',
    },
    row:{
        //wordWrap:'break-word'
        width:'100%',
        //backgroundColor:'#ddd'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    //fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth:'200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});

const useStyles = ({
    page: {
        //display: 'row',
        //backgroundColor: '#E4E4E4',
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    body: {
        paddingTop: 15,
        paddingBottom: 40,
        paddingLeft: 15,
    },
    logo: {
        width: 60,
        height: 60,
    },
    logoFito: {
        width: 'auto',
        height: 'auto',
    },
    bussinessData: {
        width: 'auto',
        height: 'auto',
    },
    header:{
        marginTop:7,
        fontSize:10
    },
    secondHeader:{
        marginTop:7,
        fontSize:11,
        marginBottom:10
    },
    headerTable:{
        fontSize:10,
        marginVertical:'auto',
        padding:3,
        borderTop:'1pt solid rgba(0,0,0,0.31)',
        borderBottom:'1pt solid rgba(0,0,0,0.31)',
        borderLeft:'1pt solid rgba(0,0,0,0.31)',
    },
    lineTable:{
        fontSize:9,
        marginVertical:'auto',
        padding:3,
    },
    content: {
        marginRight:15,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    },
    content02: {
        marginRight:0,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    }
});
/*
const secciones_inspected = (list:Array<any>)=>{

    let secciones = list.map((seccion:any, index:number)=>{

        return (
            <View key={index} style={[useStyles.content, {paddingBottom:5}]}>
                <View style={{width:'13%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {index + 1}
                    </Text>
                </View>
                <View style={{width:'87%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {seccion.name}
                    </Text>
                </View>
            </View>
        );
    })
    return secciones;
}

const questions = (list:Array<IQuestion>)=>{

    let questions = list.map((question:IQuestion, index:number)=>{

        return (
            <View key={index} style={[useStyles.content, {paddingBottom:5}]}>
                <View style={{width:'55%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.question}
                    </Text>
                </View>
                <View style={{width:'10%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.answer}
                    </Text>
                </View>
                <View style={{width:'35%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.comment}
                    </Text>
                </View>
            </View>
        );
    })
    return questions;
}
*/

const pdf_questions = (list:Array<IQuestion>)=>{

    let questions = list.map((question:IQuestion, index:number)=>{

        return (
            <View key={index} style={[useStyles.content02, {paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                <View style={{width:'38%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'justify', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.question}
                    </Text>
                </View>
                <View style={{width:'15%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.answer}
                    </Text>
                </View>
                <View style={{width:'45%'}}>
                    <Text style={[useStyles.lineTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>
                        {question.comment}
                    </Text>
                </View>
            </View>
        );
    })
    return questions;
}

const PdfForm  = (props: ListChildComponentProps) =>{
    const register:IForm = props.data[0];
    const me:IMe = props.data[1];
    const item:IVRegister = props.data[2];

    const empresa:ICentroTrabajo|undefined = GetEmpresa(item.centrotrabajoId, me);

    return(
        <Document>
            <Page size="A4" style={useStyles.body} >
                <View fixed>
                    <View style={[useStyles.content, {paddingBottom:20, justifyContent:'space-between'}]}>
                        <Text style={{fontSize:8, color:'#8c8c8c', fontWeight:'bold'}} fixed>
                            {`Fecha revisión: ${register.dt_reviewed}`}
                        </Text>
                        <Text style={{fontSize:8}} render={({ pageNumber, totalPages }) => (
                            `Pág. ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                    <View style={[useStyles.content, {paddingBottom:20}]}>
                        <View style={{width:'25%', marginRight:30, paddingRight:15, borderRight:'1pt solid rgba(0,0,0,0.31)'}}>
                            {empresa?
                                <Image src={empresa.logo} style={useStyles.bussinessData}/>
                            :
                                null
                            }
                            <Text style={{fontWeight:25, fontSize:10, textAlign:'center', marginTop:10}}>{`${empresa?.description}`}</Text>
                        </View>
                        <View style={{width:'70%', height:120}}>
                            <Text style={{fontWeight:50, fontSize:20}}>{`${register.title}`}</Text>
                            <Text style={useStyles.header}>{`Fecha realización: ${GetDatetimeFormat(register.dt_created)}`}</Text>
                            <Text style={useStyles.header}>{`Realizado por: ${register.usuario}`}</Text>
                        </View>
                    </View>
                </View>
                <View style={{width:'100%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', backgroundColor:'#ddd', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Secciones`}</Text>
                </View>
                <View style={{width:'95%', marginTop:10, marginLeft:'auto', paddingRight:'5%'}}>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${register.secciones.map(function(o){return o.name}).join("\r\n")}`}</Text>
                    <Text style={[useStyles.headerTable, {textAlign:'left', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`${register.seccionDsc}`}</Text>
                </View>
                <View style={{paddingBottom:10}}>
                    <View style={[useStyles.content02, {paddingTop:10, marginLeft:'auto', paddingRight:'5%'}]}>
                        <View style={{width:'40%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center'}]}>{`Punto inspección`}</Text>
                        </View>
                        <View style={{width:'15%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', backgroundColor:'#ddd'}]}>{`Respuesta`}</Text>
                        </View>
                        <View style={{width:'45%'}}>
                            <Text style={[useStyles.headerTable, {textAlign:'center', justifyContent:'center', borderRight:'1pt solid rgba(0,0,0,0.31)'}]}>{`Comentario`}</Text>
                        </View>
                    </View>
                    {pdf_questions(register.questions)}
                </View>
            </Page>    
        </Document>
    ); 
}

const questions = [
    {id:"q_1", question: "La estructura de la estantería no presenta daños:\r\n\r\n• No hay falta de verticalidad en los puntales.\r\n• Las placas base y conexiones de los largueros con los puntales no presentan deformación visible.\r\n• No se aprecian grietas en las soldaduras o material base.\r\n• No hay ausencia de clavijas de seguridad de los conectores de los largueros.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},
    {id:"q_2", question: "Existen protecciones en buen estado en las partes más expuestas a golpes de las estanterías (extremos de los puntales de pasillos de trabajo / maniobra).", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},
    {id:"q_3", question: "Los elementos de almacenaje (paleta, cajón, bidón, contenedor, jaula, etc.) son adecuados y se encuentran en buen estado.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_4", question: "Los elementos de almacenaje son estables y están correctamente orientados y posicionados sobre los elementos de apoyo del equipo de almacenaje.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_5", question: "La posición de las cargas sobre los elementos de almacenaje está centrada para evitar cualquier sobrecarga. No se superan las cargas máximas indicadas en la placa de características.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_6", question: "El suelo del almacén se encuentra en buenas condiciones.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_7", question: "Las placas de características están visibles, en buen estado y se corresponden con la realidad del almacén.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_8", question: "Los equipos de manutención mecánicos utilizados (carretillas, transpaletas…) están en buen estado y son los apropiados para utilizar según tipo de estantería y pasillo de trabajo disponible (la anchura del pasillo de trabajo debe permitir suficiente holgura para la maniobrabilidad segura del equipo).", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_9", question: "La señalización vertical y horizontal de la zona de almacén afectada es correcta y de acuerdo al RD 485/97.\r\n\r\n• Se delimitarán claramente las zonas de circulación y los límites de ubicación de las zonas de apilado situadas al pie y sobre las estanterías. Es importante señalizar el lugar donde aparcar los equipos de trabajo (carretillas…).\r\n• Cuando sea necesario para la protección de los trabajadores, las vías de circulación de vehículos deberán estar delimitadas con claridad mediante franjas continuas de un color bien visible, preferentemente blanco o amarillo, teniendo en cuenta el color del suelo. La delimitación deberá respetar las necesarias distancias de seguridad entre vehículos y objetos próximos, y entre personas y vehículos.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_10", question: "Se observa orden y limpieza (mercancías bien colocadas sin obstaculizar zonas de tránsito, sin derrames ni suciedad, limpieza regular del polvo acumulado en las luminarias, etc.).", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]},    
    {id:"q_11", question: "La iluminación es suficiente de acuerdo al RD 486/97.\r\n\r\n• Se deben situar los sistemas de iluminación por encima de los pasillos de forma que se tenga una iluminación suficiente sobre las zonas de trabajo y evitar el deslumbramiento de los operarios y la creación de zonas de sombra.\r\n• El nivel mínimo de iluminación del almacén estará en función de la atención visual requerida. De acuerdo al RD 486/97 los pasillos de circulación de uso exclusivo en los que no se requiera lectura alguna, tendrán una iluminación mínima de 50 lux. Cuando se requiera la lectura de texto que precisa una atención visual baja el nivel mínimo será de 100 lux.\r\n• Toda la instalación estará dotada de suficiente número de aparatos de alumbrado de emergencia que permitan la fácil salida al exterior de los trabajadores en caso de emergencia.\r\n• Toda instalación de alumbrado rota, sucia, deteriorada o que presente luminarias fundidas será inmediatamente sustituida o reparada.\r\n• Como norma general se debe situar las luminarias fuera del alcance de los aparatos o carretillas de manutención y de sus cargas. Se protegerán especialmente contra los golpes los aparatos de alumbrado de emergencia.", answer: "", comment: "", a_template: [{id:"CORRECTO",name:"CORRECTO"},{id:"INCORRECTO",name:"INCORRECTO"},{id:"NO APLICA",name:"NO APLICA"}]}
];

const help = [
    "Se seguirán las indicaciones establecidas en la instrucción de Inspecciones Visuales de Estanterías del Grupo Vall Companys."
]

interface IFormProps {
    me:IMe;
    classes: any;
    secciones:any;
    handleCloseItemForm: Function;
    item:IVRegister;
}

interface IFormState {
    creationModalOpen:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    titleModal: string;
    htmlBody:boolean;
    loading:boolean;
    loadingModal:boolean;
    register:IForm;
    modalClassName:string;
}

class Form extends Component<IFormProps, IFormState> {
    _going2upload:any = {attachments:[]};
    constructor(props: IFormProps) {
        super(props);
        this.state = {
            creationModalOpen:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            htmlBody:false,
            loading: false,
            loadingModal: false,
            titleModal: `MENSAJE`,
            modalClassName:'dialog-little',
            register: {
                dt_reviewed: `01/01/21`,
                dt_created: new Date(),
                dt_edited: new Date(),
                title: `Estanterías - Sección`,
                usuario: props.me.userIdName,
                secciones: [],
                seccionDsc: "",
                questions: questions
            }
        };
    }

    componentDidMount(){
        this.handleShowHelpers();
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false });
    }

    /* CALL BACKEND */

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 6);
        this._going2upload = {attachments:[]};
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        let modaltype = 1;
        let modalaction = -1;

        if(response == 1) {
            p = `Formulario adjuntado correctamente`;
            modaltype = 4;
            modalaction= 1;
        }
        
        this.setState({
            creationModalOpen:true,
            modalText:p,
            modalType: modaltype,
            modalAction: modalaction,
            loadingModal: false,
            modalClassName:'dialog-little'
        });
    }

    /* */

    handleShowHelpers=()=>{
        this.setState({
            modalText: `¡NOTA! ${help[0]}`,
            modalType: 1,
            creationModalOpen: true,
            modalClassName:'dialog-little'
        });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Implica que se ha adjuntado corractamente el formulario
                this.props.handleCloseItemForm(true);
                this.onCloseCreationModal();
                break;
            case 2: // Implica iniciar el proceso de adjuntar el formulario
                this.setState({loadingModal: true},()=>{
                    this.handleCreateForm();
                });
                break;
            default:
                this.onCloseCreationModal();
        }
    }

    handleChangeUserQuestions=(id:any, event:any, i01:number, type?:string)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.questions[i01][id] = event.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeSelectDevices=(e:any, list:any)=>{
        let nRegister = Object.assign({}, this.state.register);
        nRegister.secciones = list;
        this.setState({ register: nRegister });
    }

    handleChangeUser = (id:string, e:any) => {
        let nRegister = Object.assign({}, this.state.register);
        nRegister[id] = e.target.value;
        this.setState({ register: nRegister });
    }

    handleCreateForm=()=>{

        const {item, me} = this.props;
        const {register} = this.state;

        let dale = <PdfForm index={1} data={[register, me, item]} style={{}} />;

        const blob = pdf(dale).toBlob();

        blob.then(ret=>{

            let reader = new FileReader();

            reader.onloadend = async ()=>{
                this._going2upload.attachments.push({name: `Informe_EST_01_seccion_${GetConcatedDate(register.dt_created)}.pdf`, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: item.tipo, listadoId: item.listadoTableId, id: item.id});
                this.sendAttachFiles();
            }
            reader.readAsDataURL(ret);

        }).catch(err=>{
            console.log(err)
        })
    }

    handleAskCreateForm=()=>{
        const {item} = this.props;

        this.setState({
            modalAction: 2,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo a la acción ${item.id} de la orden de trabajo ${item.listadoTableId}?`,
            modalType: 2,
            creationModalOpen: true,
            modalClassName:'dialog-little'
        });
    }

    verifyForm=(register:IForm)=>{

        // Verifica hay almenos una sección
        if(register.secciones.length == 0)
            return false;

        // Verifica que todas las preguntas menos los comentarios han sido contestadas
        let c = true;
        register.questions.map(i=>{
            if(i.answer.length == 0)
                c = false;
        })

        if(!c)
            return false;

        return true;
    }

    renderForm = (register:IForm, secciones:any) => {

        return(
            <div style={{display:'inline', width:'100%'}}>
                <div style={{width:'100%', marginBottom:20}}>
                    <ListSecciones id={"secciones"} data={register.secciones} multiple={true} name={'Sección'} placeholder={'Sección'} options={secciones} handleChangeSelect={(e:any, list:any)=>this.handleChangeSelectDevices(e,list)}/>
                </div>
                <div style={{width:'100%', marginBottom:20}}>
                    <TextFieldInput 
                        id={'seccionDsc'} 
                        name={`Descripción sección`} 
                        multiline={false} 
                        fieldsData={register} 
                        handleFieldChange={(id:string, e:any, type:string)=>this.handleChangeUser('seccionDsc', e)}
                        required={false}
                        disabled={false}
                        type={`text`}
                        helper={``}
                        maxText={100}
                        style={2}
                        noError={true}
                        shrink={true}
                        size={"small"}
                    />
                </div>
                <Paper className={this.props.classes.paper}>
                    <Table className={this.props.classes.table} aria-label="simple table">
                    {register.questions.map((item:IQuestion, i01:number)=>{
                        return(
                            <TableBody key={i01} style={{width:'100%'}}>
                                <TableRow className={this.props.classes.row} style={{backgroundColor:'#00809b'}} key={i01}>
                                    <TableCell style={{width:'100%', color:'#fff'}} align="center"><strong>{i01 + 1}</strong></TableCell>
                                </TableRow>
                                <TableRow className={this.props.classes.row}> 
                                    <TableCell style={{whiteSpace:'pre-line'}} align="left">{`${item.question}`}</TableCell>
                                </TableRow> 
                                <TableRow className={this.props.classes.row}> 
                                    <TableCell align="center">
                                        <div style={{maxWidth:150}}>
                                            <SelectorField
                                                name={`Q_${i01 + 1}`}
                                                fieldsData={item.a_template}
                                                id={'answer'}
                                                deviceData={item}
                                                handleFieldChange={(id:string, evt:any)=>this.handleChangeUserQuestions(id,evt,i01)}
                                                required={true}
                                                style={3}
                                            />
                                        </div>

                                    </TableCell>
                                </TableRow> 
                                <TableRow className={this.props.classes.row}> 
                                    <TableCell align="center">
                                        <TextFieldInput
                                            name={`C_${i01 + 1}`}
                                            id={'comment'}
                                            disabled={false}
                                            type={"text"}
                                            required={false}
                                            handleFieldChange={(id:string, evt:any, type:string)=>this.handleChangeUserQuestions(id,evt,i01,type)}
                                            style={3}
                                            helper={""}
                                            fieldsData={item}
                                            maxText={2000}
                                            noError={true}
                                            size={"medium"}
                                            shrink={true}
                                        />
                                    </TableCell>
                                </TableRow> 
                            </TableBody>
                        );
                    })}  
                    </Table>
                </Paper> 
            </div>
        );
    }

    render() {
        const {register, loading} = this.state;
        const {me, secciones} = this.props;

        return (
            <Dialog style={{padding:'25px'}} fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data'>
                <DialogTitle id="form-dialog-title">
                    <div className="App-header">
                        {`${register.title}`}
                    </div>
                    <div>
                        <IconButton size="small" onClick={()=>this.handleShowHelpers()} aria-label="help user">
                            <HelpIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {this.renderForm(register, secciones)}
                    <div>
                        {this.state.creationModalOpen?
                            <CreateModal
                                onCloseCreationModal={this.onCloseCreationModal}
                                isOpen={this.state.creationModalOpen}
                                inputText={this.state.modalText}
                                typeModal={this.state.modalType}
                                action={this.handleCreateModal}
                                typeAction={this.state.modalAction}
                                loadingModal={this.state.loadingModal}
                                titleModal={this.state.titleModal}
                                className={this.state.modalClassName}
                            />
                        :
                            null
                        }
                    </div>
                </DialogContent>
                <DialogActions style={{ borderTop: '#ff972b solid 3px'}}>
                    {!loading?
                        <div style={{marginRight:'auto', display:'flex', marginLeft: 'auto', width: '90%', justifyContent:'space-between'}}>
                            <Button onClick={()=>this.props.handleCloseItemForm(false)} className='cancel-dialog'>
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(register)} onClick={()=>this.handleAskCreateForm()} className={`confirm-dialog ${!this.verifyForm(register)?`confirm-dialog-disable`:``}`}>
                                {`Generar informe`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Form);