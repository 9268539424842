import * as React from "react";
import { ClassKey, Props } from "./types";
import {
  Box,
  Divider,
  IconButton,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import SubsectionsField from "../SubsectionsField";

const useStyles = makeStyles<Theme, {}, ClassKey>(() => ({
  root: {
    width: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  content: {},
  divider: {
    margin: "50px 0",
  },
}));

const SectionCard = ({
  fieldName,
  section,
  reportFields,
  lastItem,
  disabled,
  onFormChange,
  onDeleteClick,
  classes: classes$props,
  SubsectionFieldProps,
}: Props) => {
  const classes = { ...useStyles(), ...(classes$props || {}) };

  return (
    <Box className={classes.root}>
      <div className={classes.header}>
        <Typography variant="subtitle1" display="inline">
          {section.numeration}. {section.name}
        </Typography>
        {!disabled && (
          <IconButton onClick={onDeleteClick}>
            <Delete />
          </IconButton>
        )}
      </div>

      <div className={classes.content}>
        <SubsectionsField
          {...SubsectionFieldProps}
          sectionId={section.id}
          reportFields={reportFields}
          name={`${fieldName}.subsections`}
          disabled={disabled}
          onFormChange={onFormChange}
        />
      </div>
      {!lastItem && <Divider className={classes.divider} />}
    </Box>
  );
};

export default SectionCard;
