import React, { Component, useState } from 'react';
import { IMe, ICentroTrabajo, IDashboard } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import { GetLogoEmpresa } from '../helpers';
import './Dashboard.css';
import ReactEcharts from "echarts-for-react";
import LinearProgress from '@mui/material/LinearProgress';
import { GetRole, GetEmpresa } from '../helpers';
import DatePicker from './DatePicker';
import OrderService from '../ApiClients/OrderService';
interface IDashboardSection{
    name: string,
    kpi:[IDashboardBoxProps],
    graphics: [IDashboardGraphicsBoxProps]
}
interface IDashboardSectionState{

}
class DashboardSection extends Component<IDashboardSection, IDashboardSectionState>{
    renderGraphics = (graphics:[IDashboardGraphicsBoxProps]) =>{
        let graphic = graphics.map((graphicData: IDashboardGraphicsBoxProps, index: number) => {
            if(graphicData)
                return (
                    <div key={`${graphicData.id}${graphicData.name}`} className='dashboard-graphic'>
                        <h3 className='boxTitle'>{graphicData.name}</h3>                
                        <ReactEcharts className='dashboard-graphic-wrapper' option={graphicData.options} />
                    </div>
                );
            else
                return null
        });
        return <div className='dashboard-kpi-container'>{graphic}</div>
    }
    renderKPI = (kpi:[IDashboardBoxProps]) => {
        let kpi_data = kpi.map((kpi_item:IDashboardBoxProps, index:number)=>{
            return (
                <div key={index} className='dashboard-kpi'>
                    <h3 className='boxTitle'>{kpi_item.name}</h3>
                    <div className='dashboard-kpi-wrapper'>
                        <p className='boxValue'>{kpi_item.number} <span style={{ fontSize: '1.2rem',lineHeight: '2.5rem',verticalAlign: 'middle' }}>/</span> {kpi_item.total}</p>
                        {kpi_item.showLoad ?
                            <div key={`${kpi_item.name}_info`} style={{ width: '85%', margin: '0 auto' }}>
                                <LinearProgress variant="determinate" value={Number(kpi_item.percentage)} />
                                <span style={{ display: 'block', textAlign: 'center', width: '100%', marginTop: '.5rem' }}>{kpi_item.percentage}%</span>
                            </div>
                        : 
                        null
                        }                    
                    </div>
                </div>          
            )
        })

        return kpi_data;
        
    }

    render(){
        return(
            <div key={this.props.name} className='dashboard-section'>
                <h2>{this.props.name}</h2>
                <div className='dashboard-section-data-wrapper'>
                    {this.renderKPI(this.props.kpi)}
                    {this.renderGraphics(this.props.graphics)}  
                </div>
                
            </div>
        );
        
    }
}

interface IDashboardBoxProps {
    id: number;
    name: string;
    number: string;
    showLoad: boolean;
    percentage: number;
    total: number;
    type: number;
}

interface IDashboardGraphicsBoxProps {
    id: number;
    name: string;
    options: any;
}

interface IDashboardProps {
    history: any;
    match: any;
    me?: IMe;
    version: string;
    // dataI: IDashboard;
}

interface IDashboardState {
    loading: boolean;
    searchValue: string;
    creationModalOpen: boolean;
    modalType: number;
    typeAction: number;
    inputText: any;
    titleModal: string;
    closeButtonModal: boolean;
    info: any;
    startDate: Date;
    endDate: Date;
}

class Dashboard extends Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            loading: true,
            searchValue: '',
            modalType: -1,
            typeAction: -1,
            inputText: '',
            creationModalOpen: false,
            titleModal: `MENSAJE`,
            closeButtonModal: false,
            info: [],
            startDate: new Date(new Date().getTime() 
            - 7 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
        };
    }
    componentDidMount() {
        this.data();
    }

    data = async () => {                
        let response = await OrderService.dashboardData(this.props.match.params.centrotrabajoId, this.state.startDate, this.state.endDate);
        this.setState({ info: response, loading: false });        
    }
    
    RenderSections(){
        if(this.state.info !== undefined && this.state.info.length > 0){
            let section = this.state.info.map((sectionData: IDashboardSection, index: number) => {
                return (
                    <DashboardSection
                        key={index}
                        name = {sectionData.name}
                        kpi={sectionData.kpi}
                        graphics={sectionData.graphics}
                    />
                );
            });

            return <div className='dashboard-section-container'>{section}</div>
        }
        else return <div style={{textAlign:'center', width:'100%', marginTop:100}}>{`No hay datos para visualizar en las fechas seleccionadas.`}</div>;        
    }

    handleChangeStartDate = (id: any, event: any) => {
        this.setState({ startDate: event, loading: true }, ()=> this.data());
    }

    handleChangeEndDate = (id: any, event: any) => {        
        this.setState({ endDate: event, loading: true }, ()=> this.data());
    }

    shouldDisableToday = (date: any) => {
        return false;
    }

    render() {
        const { me } = this.props;
        return (
            <div className='App-basic'>
                <Layout
                    me={this.props.me}
                    history={this.props.history}
                    currentPage={0}
                    allowDrawer={false}
                    match={this.props.match}
                    version={this.props.version}
                    showEmpresa
                    showLateralMenu
                    loading={this.state.loading}
                />
                <div className='App-body-webBar'>
                    <div className='App-header'>
                        <div className="App-title-webPage">Tablero</div>
                        
                    </div>
                    {me ?
                        [20, 50, 100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) === -1 ?
                            <label style={{ marginLeft: 10 }}>
                                {`No tiene permisos para visualizar este espacio.`}
                            </label>
                            :
                            <div id="sections" className='container'>
                                <div className='dates-container'>
                                    {/* <h2>Intevalo de fechas</h2> */}
                                    <div>{DatePicker('_graphicDateStart', 'Fecha Inicio', "dd/MM/yyyy", this.handleChangeStartDate, this.state.startDate.toString(), false, this.shouldDisableToday, new Date('1970-01-01'))}</div>
                                    <div>{DatePicker('_graphicDateEnd', 'Fecha Fin', "dd/MM/yyyy", this.handleChangeEndDate, this.state.endDate.toString(), false, this.shouldDisableToday, new Date('1970-01-01'))}</div>
                                </div>
                                {this.RenderSections()}
                            </div>
                        :
                        this.state.loading ?
                            <div style={{ marginLeft: 20 }}>
                                <Loader loading={true} />
                            </div>
                            :
                            <label style={{ marginLeft: 10 }}>
                                {`Fallo en la carga del perfil, volver a intentar.`}
                            </label>
                    }
                </div>
            </div>
        );
    }
}

export default Dashboard;