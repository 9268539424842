import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import Empresa from './Empresa';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import PaginatedTable from './Material/PaginatedTableListado';
import {filter, includes, cloneDeep, groupBy} from 'lodash';
import OrderService from '../ApiClients/OrderService';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {GetRole, GetEmpresa} from '../helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';
import DatePicker from './DatePicker';
import { IMe, IRegister, IIRegister, ICentroTrabajo, IIIRegister, IFilter } from '../types';
import LoaderBeauty from './Material/activityProgress';
import FormCorrectivo from './FormCorrectivo';
import ReactExport from 'react-export-excel';
import CreateModal from './CreateDialog';
import {GetDate} from '../vc_general';
import LateralMenu from './LateralMenu';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import SearchField from './SearchField';
import FiltersResponsive from './Filters/Filters_responsive';

const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    root: {
    },
        nested: {
        paddingLeft: theme.spacing(4),
    },    
});

const fields = [
    {
        id: 'origen',
        name: 'Orígen',
        required: false,
        style:1,
        type: 1
    },
    {
        id: 'tipoMedida',
        name: 'Tipo medida',
        required: false,
        style:1,
        type: 1
    },
    {
        id: 'seccion',
        name: 'Sección',
        required: false,
        style:1,
        type: 1
    },
    {
        id: 'puestoTrabajo',
        name: 'Puesto trabajo',
        required: false,
        style:1,
        type: 1
    },
    {
        id: 'referencia',
        name: 'Riesgo',
        required: false,
        style:1,
        type: 1
    },
    {
        id: 'responsable',
        name: 'Responsable',
        required: false,
        style:1,
        type: 1
    },
    {
        id: 'estado',
        name: 'Estado',
        required: false,
        style:1,
        type: 1
    },
    {
        id: 'nonCompliant',
        name: 'No Conformidad',
        required: false,
        style: 1, 
        type:2
    },
    // {
    //     id: 'fechaPrevista',
    //     name: 'Fecha prevista',
    //     require: false,
    //     style: 1,
    //     type: 3
    // },
    // {
    //     id: 'fechaCierre',
    //     name: 'Fecha cierre',
    //     require: false,
    //     style: 1,
    //     type: 3
    // }                    

];


const exportDataExcel=(empresa:ICentroTrabajo, data:Array<IIIRegister>)=>{
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    data = data.map((item:IIIRegister, idx:number)=>{
        return({
            ...item,
            fechaPrevista: GetDate(item.fechaPrevista),
            fechaPrevistaEnd: GetDate(item.fechaPrevistaEnd),
            fechaCierre: (item.fechaCierre==null?item.fechaCierre:GetDate(item.fechaCierre))
        })
    })
    const grouped:any = groupBy(data, 'tipo');
    const d = new Date();
    const date = `${d.getFullYear()}${String(d.getMonth()+1).padStart(2,'0')}${String(d.getDate()).padStart(2,'0')}`;
    const tipo:number = data[0].tipo;
    
    let sheets = Object.keys(grouped).map((key:string,index:number)=>{
        return(
            <ExcelSheet data={grouped[key]} name={`${tipo == 1?`Preventivos`:`Correctivos`}`} key={index}>
                <ExcelColumn label="ID" value="code"/>
                <ExcelColumn label="Orígen" value="origenDsc"/>
                <ExcelColumn label="Tipo medida" value="tipoMedidaDsc"/>
                <ExcelColumn label="Sección" value="seccionDsc"/>
                <ExcelColumn label="Puesto de trabajo" value="puestoTrabajoDsc"/>
                <ExcelColumn label="Código riesgo" value="referenciaCode"/>
                <ExcelColumn label="Riesgo" value="referenciaDsc"/>
                <ExcelColumn label="Detalle riesgo" value="detalleRiesgo"/>
                <ExcelColumn label="Medidas" value="medidas"/>
                <ExcelColumn label="Prioridad" value="prioridadDsc"/>
                <ExcelColumn label="Responsable" value="responsableDsc"/>
                <ExcelColumn label="Estado" value="estadoDsc"/>
                <ExcelColumn label="Fecha prevista inicio" value="fechaPrevista"/>
                <ExcelColumn label="Fecha prevista fin" value="fechaPrevistaEnd"/>
                <ExcelColumn label="Fecha cierre" value="fechaCierre"/>
            </ExcelSheet>
        );
    });

    return (
        <ExcelFile filename={`${tipo == 1?`Preventivos`:`Correctivos`}_${empresa.description.replaceAll(' ','_')}_${date}`} hideElement={true} element={<button>{`Descargar`}</button>}>
            {sheets}
        </ExcelFile>
    );
}

/*interface IFormDownloadDataProps {
    //classes: any;
    data:Array<IIIRegister>;
}

interface IFormDownloadDataState {
    downloaddata:boolean;
}

class IFormDownloadData extends Component<IFormDownloadDataProps, IFormDownloadDataState> {
    constructor(props: IFormDownloadDataProps) {
        super(props);
        this.state = {
            downloaddata:false
        };
    }
    
    render() {

        return (
            <div style={{padding:10, border:'2px solid #002b61d9', borderRadius:'10px'}}>
                <div style={{marginTop:20, textAlign:'center'}}>
                    <div>
                        {!this.state.downloaddata?
                            this.props.data.length > 0?
                                <div>
                                    <p>
                                        {`Se exportarán un total de ${this.props.data.length} registros.`}
                                    </p>
                                    <button className="button-save" onClick={()=>this.setState({downloaddata:true})}>{`Descargar`}</button>
                                </div>
                            :
                                `No hay datos disponibles a exportar.`
                        :
                            `Descargando datos...`
                        }
                    </div>
                    <div style={{marginTop:20}}>
                        {this.state.downloaddata?
                            exportDataExcel(this.props.data)
                        :
                            null
                        }
                    </div>
                </div>
            </div>
        );*/
       /* return (
            exportDataExcel(this.props.tipo, this.props.data)
        );*/
   /* }
}
const FormDownloadData = withStyles(styles)(IFormDownloadData);*/

interface IListadoProps {
    history:any;
    match:any;
    me?: IMe;
    version: string;
    filters: IFilter;
    handleChangeFilter: Function;
    handleCheckFilter: Function;
}

interface IListadoState {
    rowSelected: number;
    creationModalOpen:boolean;
    creationItemOpen: boolean;
    creationModalOpenEmail:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    modalClassName:string;
    htmlBody:boolean;
    sendingEmail:boolean;
    emailResult:number;
    currentYearSelected: number|string;
    loading:boolean;
    loadingOptions:boolean;
    loadingDocuments:boolean;
    documents:any;
    //filters:any;
    options?:any;
    searchValue:string;
    creatingItem:boolean;
    loadingModal:boolean;
    titleModal:string;
    downloaddata:boolean;

    tipo: Number;
    filter_id: string;
}

class Income extends Component<IListadoProps, IListadoState> {
    constructor(props: IListadoProps) {
        super(props);
        this.state = {
            rowSelected: -1,
            //dataIncome: dataIncome,
            documents:[],
            creationModalOpen:false,
            creationItemOpen:false,
            creationModalOpenEmail:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            sendingEmail:false,
            emailResult:-1,
            htmlBody:false,
            currentYearSelected: new Date().getFullYear(),
            loading: true,
            loadingOptions: true,
            loadingDocuments: true,
            //filters:{},
            searchValue: '',
            creatingItem:false,
            loadingModal: false,
            titleModal: `MENSAJE`,
            downloaddata:false,
            modalClassName:'dialog-little',

            tipo: Number(this.props.match.params.tipo),
            filter_id: (Number(this.props.match.params.tipo)==1?"f_mp":"f_mc")
        };
    }

    componentDidMount(){
        this.reviewPage();
        this.loadData();
    }

    componentDidUpdate(prevProps:any, props:any){
        if(prevProps.match.params.tipo !== this.props.match.params.tipo) {
            const {me} = this.props;
            if(!me)
                return;
            if ([5,10,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) > -1)
                this.setState({loadingDocuments: true, tipo: Number(this.props.match.params.tipo)}, ()=>this.loadListado());
            
            this.setState({tipo: Number(this.props.match.params.tipo), filter_id: (Number(this.props.match.params.tipo)==1?"f_mp":"f_mc")});
        }
        if(prevProps.me !== this.props.me)
            this.loadData();
        this.reviewPage();
    }

    loadData = () => {
        const {me} = this.props;

        if(!me)
            return;
        if ([5,10,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) > -1) {
            this.loadListado();
            this.loadOptions();
        } else {
            this.setState({
                loading:false,
                loadingOptions: false,
                loadingDocuments: false
            })
        }
    }

    loadOptions = async () => {
        let response = await OrderService.LoadOptions(this.props.match.params.centrotrabajoId);
        this.setState({loadingOptions: false, options: response});
    }

    loadListado = async () => {
        const centrotrabajoId = Number(this.props.match.params.centrotrabajoId);
        const tipo = Number(this.props.match.params.tipo);

        let response = await OrderService.LoadListado(centrotrabajoId, tipo);
        this.setState({loadingDocuments: false, documents: response});
    }

    handleFilterDocuments=(documents:any, f: any)=>{
        let newDocuments = cloneDeep(documents);

        //** FILTRA POR LISTADO ACTUAL */
        const tipo = this.state.tipo;

        newDocuments = filter(newDocuments, function(o){return o.tipo == tipo});

        //** FILTRA POR LOS FILTROS APLICADOS */
        const keys = Object.keys(f);
        const values:any = Object.values(f);
        if(keys.length > 0){
            for(let a=0; a<keys.length;a++){
                if(["fechaPrevista"].indexOf(keys[a])  > -1)
                    newDocuments = filter(newDocuments, function(o){return new Date(o[keys[a]]) >= new Date(values[a])})
                else if(["fechaCierre"].indexOf(keys[a])  > -1)
                    newDocuments = filter(newDocuments, function(o){return new Date(o[keys[a]]) <= new Date(values[a])})
                else
                    newDocuments = filter(newDocuments, function(o){return o[keys[a]] == values[a]})
            }
        }

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o){return includes(o.referenciaCode.toLowerCase(), searchValue) || includes(o.origenDsc.toLowerCase(), searchValue) || includes(o.seccionDsc.toLowerCase(), searchValue)  || includes(o.puestoTrabajoDsc.toLowerCase(), searchValue) || includes(o.tipoMedidaDsc.toLowerCase(), searchValue) || includes(o.estadoDsc.toLowerCase(), searchValue) || includes(o.responsableDsc.toLowerCase(), searchValue) || includes(o.medidas.toLowerCase(), searchValue)  || includes(o.detalleRiesgo.toLowerCase(), searchValue) });
        }
        return newDocuments;
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }

    handleCleanFilters = (evt:any)=>{
        evt.stopPropagation();
        this.props.handleChangeFilter(this.state.filter_id, {}, false);
    }

    reviewPage=()=>{
        this.props.handleCheckFilter(this.props.match.params.centrotrabajoId);
        
        if([1,2].indexOf(Number(this.props.match.params.tipo)) == -1)
            this.props.history.push(`/`);
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false, });
    }

    onCloseFormItemModal = () => {
        this.setState({ creationItemOpen: false });
    }

    /* CALL BACKEND */


    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Export data
                break;
        }
        this.onCloseCreationModal();
    }

    onCloseCreationModalEmail = () => {
        this.setState({ creationModalOpenEmail: false });
    }

    handleClickListado=(income:number)=>{
        this.setState({creationItemOpen:true});
        /*switch(income){
            case 1:
                this.props.history.push('/generar/preventivo')
                break;
            case 2:
                this.props.history.push('/ingressos/correctivo')
                break;
        }*/
    }

    handleSelectYearChange = (event:any) => {
    }

    handleItemClick=(item:any)=>{
        this.props.history.push(`${window.location.hash.replace('#','')}/${item.id}`)
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters[this.state.filter_id]);
        newFilters[id] = evt.target.value;

        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);
    }

    handleChangeFilterDate=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters[this.state.filter_id]);
        newFilters[id] = evt;
        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);
    }

    RenderFilters = (optionsfilters:any, arrayfilters:any) => {
        if(optionsfilters == undefined)
            return `Fallo en la carga de los filtros, vuelva a refrescar la página.`;

        let filters = fields.map((item:any, index:number)=>{
            if(item.type == 1)
                return (
                    <SelectorField
                        key={index}
                        name={item.name}
                        id={item.id}
                        deviceData={arrayfilters}
                        fieldsData={optionsfilters[item.id]?optionsfilters[item.id]:[]}
                        handleFieldChange={this.handleChangeFilter}
                        required={item.required}
                        style={item.style}
                        className='filter-item'
                    />
                );
            else if(item.type == 2){
                if(this.props.match.params.tipo == 2)
                    return (
                        <SelectorField
                            key={index}
                            name={item.name}
                            id={item.id}
                            deviceData={arrayfilters}
                            fieldsData={[{active:true, id:0, name:'Conformidad'},{active:true, id:1, name:'No Conformidad'}]}
                            handleFieldChange={this.handleChangeFilter}
                            required={item.required}
                            style={item.style}
                            className='filter-item'
                        />
                    );
                    
            }else if(item.type == 3)
                return (
                    <div key={index} className='filter-item'>{DatePicker(item.id, item.name, "dd/MM/yyyy", this.handleChangeFilterDate, arrayfilters[item.id]?arrayfilters[item.id]:null, false, undefined, new Date("1970-01-01"), item.style)}</div>
                    
                )
        })

        return filters;
    }

    handleCreateItem=(item:any)=>{
        this.setState({creatingItem:true})
        this.saveData(item);
    }

    saveData=async(item:any)=>{
        let response:any = await OrderService.saveData(item, 2);
        if(response == 0){
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No se ha podido completar la acciónm vuelva a intentarlo.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little',
                creatingItem: false
            })
        } else {
            this.loadListado();
            this.setState({creatingItem:false, creationItemOpen:false});
        }
    }

    handleDownloadList = (data:Array<any>) => {
        if(data.length == 0)
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No hay ningún registro para exportar.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little'
            })
        else
            this.setState({
                downloaddata: true
            }, ()=>setTimeout(() => {
                this.setState({downloaddata: false})
            }, 2500))

    }

    render() {
        const {loadingOptions, loadingDocuments, options, documents, searchValue, creatingItem, filter_id} = this.state;
        const {me, filters} = this.props;
        const empresa:ICentroTrabajo|undefined = GetEmpresa(this.props.match.params.centrotrabajoId, me);

        const tipo = this.props.match.params.tipo;
        let title = (
            <div className='App-title-webPage'>
                {`Listado ${ tipo == 1?`preventivos`:`correctivos`}`}
                {'  '}
                {/*<Loader loading={this.state.loading} />*/}
            </div>
        );

        const apply_filters: any = filters[filter_id];
        const f_documents = this.handleFilterDocuments(documents, apply_filters);

        return (
            <div className='App-basic'>
                <Layout 
                    history={this.props.history} 
                    currentPage={1}
                    allowDrawer={true}
                    match={this.props.match}
                    version={this.props.version}
                    me={me}
                    showEmpresa
                    showLateralMenu
                />
                <div className='App-body-webBar'>
                    {me?
                        [5,10,20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1?
                            <div>
                                <div className="App-header">
                                    {title}
                                </div>
                                <label style={{ marginLeft:10 }}>
                                    {`No tiene permisos para visualizar este espacio.`}
                                </label>
                            </div>
                        :
                            <div>
                                <div className="App-header">
                                    {title}
                                    <div className="App-header-options App-header-options-responsive">
                                    <SearchField placeholder="Buscar..." value={searchValue} allowSearch={true} onValueChange={this.handleSearchChange} handleClearSearchValue={this.handleClearSearchValue}/>
                                        <div style={{ display:'flex', flexWrap: 'wrap', gap:'10px' }}>
                                            {me ?
                                                [20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) !== -1 && tipo == 2?
                                                        <div className={`App-header-options-item App-header-options-item-create ${(options?null:`App-header-options-item-disable`)}`} onClick={()=>(options?this.handleClickListado(1):null)}>
                                                            {`Crear correctivo`}
                                                        </div>
                                                    :
                                                        null
                                                :
                                                null
                                            }
                                            <div className="App-header-options-item download-list-button">
                                                <IconButton onClick={()=>this.handleDownloadList(f_documents)} aria-label="download list">
                                                    <DownloadIcon />
                                                </IconButton>
                                            </div>                                            
                                        </div>    
                                        <FiltersResponsive
                                                    loadingOptions={loadingOptions}
                                                    RenderFilters={this.RenderFilters}
                                                    apply_filters={apply_filters}
                                                    handleCleanFilters={this.handleCleanFilters}
                                                    options={options} />                                    
                                    </div>
                                </div>                                
                                
                                {documents.length > 0?
                                    <div style={{width:'auto', textAlign:'right'}}>
                                        <p style={{fontWeight:'bold'}}>
                                            {`Visualizando: ${f_documents.length}/${documents.length} ${documents.length == 1?`registro`:`registros`}`}
                                        </p>
                                    </div>
                                :
                                    null
                                }

                                <div>
                                    <PaginatedTable
                                        allowExport={[50, 100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) !== -1?true:false}
                                        allowSearch={false}
                                        data={f_documents}
                                        role={GetRole(me, this.props.match.params.centrotrabajoId)}
                                        handleSearch={this.handleSearchChange}
                                        searchValue={searchValue}
                                        onClearSearchValue={this.handleClearSearchValue}
                                        handleItemClick={this.handleItemClick}
                                        handleDownloadList={this.handleDownloadList}
                                    />
                                </div>
                                {this.state.creationItemOpen?
                                    <FormCorrectivo
                                        centrotrabajoId={this.props.match.params.centrotrabajoId}
                                        options={options}
                                        handleCloseItemForm={this.onCloseFormItemModal}
                                        handleCreateItem={this.handleCreateItem}
                                        loading={creatingItem}
                                    />
                                :
                                    null
                                }
                            </div>
                    :
                        this.state.loading?
                            <div style={{marginLeft:20}}>
                                <Loader loading={true}/>
                            </div>
                        :
                            `Fallo en la carga del perfil, volver a intentar`
                    }

                    {this.state.creationModalOpen?
                        <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.modalText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.modalAction}
                            loadingModal={this.state.loadingModal}
                            titleModal={this.state.titleModal}
                            closeButton={true}
                            className={this.state.modalClassName}
                        />
                    :
                        null
                    }

                    {this.state.downloaddata && empresa?
                        exportDataExcel(empresa, f_documents)
                    :
                        null
                    }
                    <LoaderBeauty visible={loadingOptions||loadingDocuments?true:false}/>
                </div> 
            </div>
        );
    }
}

export default Income;